import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
  to: string;
  exact?: boolean;
  target?: string;
  strict?: boolean;
  className?: string;
  children: React.ReactNode;
}

/**
 * A special navlink that transforms
 * into an anchor tag for external links
 */
export const Link = ({
  to,
  exact,
  target,
  strict,
  children,
  className,
}: Props): JSX.Element => {
  return to.startsWith("http") ||
    to.startsWith("mailto:") ||
    to.startsWith("tel:") ? (
    <a className={className} href={to} target={target}>
      {children}
    </a>
  ) : (
    <NavLink className={className} to={to} exact={exact} strict={strict}>
      {children}
    </NavLink>
  );
};
