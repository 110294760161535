export default class Youtube {
  /**
   * Get YouTube ID from URL
   *
   * @param  {string} url
   * @return {string|null}
   */
  static getIDFromLink = (url) => {
    const regex = /(?:youtube(?:-nocookie)?\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const sections = url.match(regex);

    return sections && sections[1];
  };
}
