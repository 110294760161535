import React from "react";
import { isEmpty } from "lodash";
import ReactPlayer from "react-player";
import styled from "styled-components";

interface Props {
  link: string;
}

const VideoPreview = ({ link }: Props) => {
  const vimeo = /^https?:\/\/(vimeo\.com)\/(.*)$/m;
  const youtube = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/watch\?v=([^&]+)/m;
  const soudcloud = /^https?:\/\/(soundcloud\.com|snd\.sc)\/(.*)$/m;

  /** Loops through valid sources */
  const isValid = [vimeo, youtube, soudcloud].filter(
    (regex: RegExp) => !isEmpty(link?.match(regex))
  );

  return (
    !isEmpty(isValid) && (
      <StyledContainer>
        <ReactPlayer width="100%" height={198} url={link} controls />
      </StyledContainer>
    )
  );
};

const StyledContainer = styled.div`
  margin-top: 7px;
  overflow: hidden;
  border-radius: 3px;
`;

export default VideoPreview;
