export enum ChannelList {
  work = "Work",
  food = "Food",
  sport = "Sport",
  sleep = "Sleep",
  money = "Money",
  lgbtq = "LGBTQ+",
  travel = "Travel",
  opinion = "Opinion",
  education = "Education",
  diversity = "diversity",
  mental_health = "Mental Health",
  entertainment = "Entertainment",
  neurodiversity = "Neurodiversity",
  physical_health = "Physical Health",
  science_and_technology = "Science & Technology",
  family_and_relationships = "Family and Relationships",
}
