export const EVENT_LOGIN = "login";
export const EVENT_LOGOUT = "logout";
export const EVENT_PAGE_VIEW = "page_view";
export const EVENT_REGISTER = "register";
export const EVENT_STUDENT_REGISTER = "student_registered";
export const EVENT_PARTNER_USER_REGISTER = "partner_user_registered";
export const EVENT_WELLBEING_COMPLETED = "wellbeing_completed";
export const EVENT_ADVERT_VIEW = "advert_view";
export const EVENT_ADVERT_VIEW_DEMO = "advert_view_demo";
export const EVENT_ADVERT_CLICK = "advert_click";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyANNNiAWRG9nXx2XhJSIBoek45H92lmyR0",
  authDomain: "miindset.firebaseapp.com",
  projectId: "miindset",
  storageBucket: "miindset.appspot.com",
  messagingSenderId: "155980204936",
  appId: "1:155980204936:web:d099d889ee2d71f1f1483d",
  measurementId: "G-4QTC8WFVCB",
};
