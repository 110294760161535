import axios from "axios";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Dialog } from "@blueprintjs/core";
import useReactRouter from "use-react-router";
import React, { useEffect, useState } from "react";

import Icon from "@components/Icon";
import { useUser } from "@hooks/useUser";
import { Title } from "@components/Title";
import Sidebar from "@components/Sidebar";
import {
  EVENT_ADVERT_CLICK,
  EVENT_ADVERT_VIEW,
  EVENT_ADVERT_VIEW_DEMO,
} from "@constants/analytics";
import { CHANNEL_SLUGS } from "@constants/channelSlugs";
import ContainerContent from "@components/ContainerContent";
import ContentBlockPaginated from "@components/ContentBlockPaginated";
import AdvertDailyChallengeExpandedInterface from "@interfaces/AdvertDailyChallengeExpandedInterface";
import { ColorInterface } from "@interfaces/ColorInterface";
import { getChannelAdSingle } from "@store/actions/ads";
import analytics from "@utils/analytics";

const AdvertDailyChallengeContainer = () => {
  const [modalTitle, setModalTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<JSX.Element>(<></>);
  const [ad, setAd] = useState<AdvertDailyChallengeExpandedInterface | null>();

  const { user } = useUser();

  const {
    match: {
      params: { slug },
    },
  } = useReactRouter<{ slug: string }>();

  useEffect(() => {
    /** @todo for demonstration purposes */
    const adId = CHANNEL_SLUGS[slug];
    getChannelAdSingle(adId).then(async (advert) => {
      if (advert) {
        setAd(advert);
        logAnalytics(EVENT_ADVERT_VIEW, advert);
      }
    });
  }, [slug]);

  /**
   * Log an event to analytics with the advert id and heading
   * @param event The name of the event
   */
  function logAnalytics(
    event: string,
    advert?: AdvertDailyChallengeExpandedInterface
  ) {
    const logAdvert = advert ?? ad;
    analytics.logEvent(event, {
      headingPrimary: logAdvert?.headings?.primary,
      headingSecondary: logAdvert?.headings?.secondary,
      adId: logAdvert?.ad_id,
    });
  }

  /**
   *
   * @param {string} title
   * @param {JSX.Element} content
   * @return void
   */
  function showModal(title: string, content: JSX.Element) {
    setIsModalOpen(true);
    setModalTitle(title);
    setModalContent(content);
  }

  /**
   * Sent a conversion request partners api.
   *
   * @returns {void}
   */
  function handleSendConversion(url): void {
    axios.post(url, {
      userId: user.user_id,
    });
  }

  return !ad ? (
    <></>
  ) : (
    <StyledChannelContainer>
      <Helmet>
        <title>
          {ad.headings.primary} - {process.env.REACT_APP_COMPANY_NAME}
        </title>
      </Helmet>

      <StyledContainerContent>
        <Title>{ad.headings.primary}</Title>
        {ad.logo_url && (
          <StyledBanner color={ad.colours.primary}>
            <StyledImage src={ad.logo_url} alt={ad.headings.primary} />
          </StyledBanner>
        )}

        {ad.workout && (
          <StyledBlock>
            {ad.workout.map((workout, key) => (
              <StyledText key={key}>{workout}</StyledText>
            ))}
          </StyledBlock>
        )}

        {ad.challenges && (
          <StyledBlock>
            {ad.challenges.map((challenge, key) => (
              <StyledText key={key}>
                <span className="pr-2">{`${challenge.name} `}</span>
                <StyledAnchor
                  onClick={() => {
                    if (ad.callback_url) handleSendConversion(ad.callback_url);
                    logAnalytics(EVENT_ADVERT_VIEW_DEMO);

                    showModal(
                      challenge.name,
                      <>
                        {challenge.media_url && (
                          <StyledImg
                            src={challenge.media_url}
                            alt={challenge.name}
                          />
                        )}
                        {challenge.audio_url && (
                          <audio
                            controls={true}
                            src={challenge.audio_url}
                            className="mb-4 ml-a mr-a d-block"
                          />
                        )}
                        <StyledText>
                          <strong>Instructions</strong>
                        </StyledText>
                        {challenge.content.map((content, key) => (
                          <StyledText key={key}>{content}</StyledText>
                        ))}
                        <StyledText className="pt-4 pb-2">
                          <strong>Alternate Movements</strong>
                        </StyledText>
                        <StyledAnchorButton
                          target="_blank"
                          className="full"
                          href={ad.button_url}
                          color={ad.colours.secondary}
                          onClick={() => logAnalytics(EVENT_ADVERT_CLICK)}
                        >
                          {ad.buttons.demo ?? ad.buttons.page}
                        </StyledAnchorButton>
                      </>
                    );
                  }}
                >
                  (Demo)
                </StyledAnchor>
              </StyledText>
            ))}
          </StyledBlock>
        )}

        {ad.instructions && (
          <StyledBlock>
            <StyledAnchor
              onClick={() =>
                showModal(
                  "Workout Instructions",
                  <>
                    {ad.instructions.length > 1 ? (
                      <ul>
                        {ad.instructions.map((instruction, key) => (
                          <li key={key}>
                            <StyledText>{instruction}</StyledText>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <StyledText>{ad.instructions?.[0]}</StyledText>
                    )}
                    <div className="pt-3">
                      <StyledAnchorButton
                        target="_blank"
                        className="full"
                        href={ad.button_url}
                        color={ad.colours.secondary}
                        onClick={() => logAnalytics(EVENT_ADVERT_CLICK)}
                      >
                        {ad.buttons.page}
                      </StyledAnchorButton>
                    </div>
                  </>
                )
              }
            >
              <StyledIcon icon="info-sign" />
              Workout Instructions
            </StyledAnchor>
          </StyledBlock>
        )}

        <StyledAnchorButton
          target="_blank"
          href={ad.button_url}
          color={ad.colours.secondary}
          onClick={() => logAnalytics(EVENT_ADVERT_CLICK)}
        >
          {ad.buttons.page}
        </StyledAnchorButton>

        <Dialog
          title={modalTitle}
          canEscapeKeyClose
          isOpen={isModalOpen}
          canOutsideClickClose
          onClose={() => setIsModalOpen(false)}
        >
          <div className="py-6 px-6">{modalContent}</div>
        </Dialog>
      </StyledContainerContent>

      <Sidebar>
        <ContentBlockPaginated type="articles" channel={slug} />
      </Sidebar>
    </StyledChannelContainer>
  );
};

const StyledChannelContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    display: flex;
  }
`;

const StyledBanner = styled.div<ColorInterface>`
  padding: 0.8rem;
  margin-bottom: 2rem;
  background-color: ${({ color }) => color};
`;

const StyledAnchor = styled.a`
  display: flex;
  flex-direction: row;
  color: var(--brandColorPrimary);
`;

const StyledImg = styled.img`
  width: auto;
  max-width: 80%;
  display: block;
  margin: 0 auto 2rem;
`;

const StyledImage = styled.img`
  max-width: 240px;
`;

const StyledBlock = styled.div`
  margin-bottom: 1.5rem;
`;

const StyledText = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;

const StyledIcon = styled(Icon)`
  color: #26253c;
  margin-right: 0.3rem;
`;

const StyledContainerContent = styled(ContainerContent)`
  flex-grow: 1;
`;

const StyledAnchorButton = styled.a<ColorInterface>`
  color: white;
  display: block;
  max-width: 410px;
  font-size: 1.1rem;
  margin: 0.5rem 0 0;
  text-align: center;
  padding: 1rem 1rem 0.8rem;
  background-color: ${({ color }) => color};

  &.full {
    width: 100%;
    max-width: 100%;
  }

  &:hover {
    opacity: 0.9;
    color: white;
    font-size: 1.1rem;
    text-decoration: none;
  }
`;

export default AdvertDailyChallengeContainer;
