/* eslint no-underscore-dangle: 0 */
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/es/storage";
import reducer from "./reducers";

let [composeEnhancers, configureStore] = "";

if (process.env.NODE_ENV === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  configureStore = composeEnhancers(applyMiddleware(thunk))(createStore);
} else {
  composeEnhancers = compose;
  configureStore = composeEnhancers(applyMiddleware(thunk))(createStore);
}

const config = {
  key: "root",
  storage,
};

const combinedReducer = persistCombineReducers(config, reducer);

const createAppStore = () => {
  const store = configureStore(combinedReducer);
  const persistor = persistStore(store);

  return { persistor, store };
};

export default createAppStore;
