import work from "../assets/images/channels/work.jpg";
import tech from "../assets/images/channels/tech.jpg";
import pets from "../assets/images/channels/pets.jpg";
import diet from "../assets/images/channels/diet.jpg";
import food from "../assets/images/channels/food.jpg";
import lgbtq from "../assets/images/channels/lgbtq.jpg";
import sport from "../assets/images/channels/sport.jpg";
import sleep from "../assets/images/channels/sleep.jpg";
import money from "../assets/images/channels/money.jpg";
import travel from "../assets/images/channels/travel.jpg";
import opinion from "../assets/images/channels/opinion.jpg";
import anxiety from "../assets/images/channels/anxiety.jpg";
import finances from "../assets/images/channels/finances.jpg";
import exercise from "../assets/images/channels/exercise.jpg";
import diversity from "../assets/images/channels/diversity.jpg";
import education from "../assets/images/channels/education.jpg";
import celebrities from "../assets/images/channels/celebrities.jpg";
import mindfulness from "../assets/images/channels/mindfulness.jpg";
import mentalHealth from "../assets/images/channels/mental-health.jpg";
import entertainment from "../assets/images/channels/entertainment.jpg";
import neurodiversity from "../assets/images/channels/neurodiversity.jpg";
import physicalHealth from "../assets/images/channels/physical-health.jpg";
import workplaceStress from "../assets/images/channels/workplace-stress.jpg";
import scienceAndTechnology from "../assets/images/channels/science-and-technology.jpg";
import familyAndRelationships from "../assets/images/channels/family-and-relationships.jpg";

const images = {
  work,
  pets,
  tech,
  diet,
  food,
  lgbtq,
  sport,
  sleep,
  money,
  travel,
  anxiety,
  opinion,
  finances,
  exercise,
  education,
  diversity,
  celebrities,
  mindfulness,
  entertainment,
  neurodiversity,
  mental_health: mentalHealth,
  physical_health: physicalHealth,
  workplace_stress: workplaceStress,
  science_and_technology: scienceAndTechnology,
  family_and_relationships: familyAndRelationships,
};

const colors = {
  pets: "#A06A26",
  diet: "#A2BB46",
  anxiety: "#B1426F",
  finances: "#55646C",
  exercise: "#669347",
  celebrities: "#BF3A46",
  mindfulness: "#F0B795",
  workplace_stress: "#659DD3",

  // Channel specific
  work: "#a68472",
  food: "#0192cb",
  sport: "#a2aa6f",
  lgbtq: "#dd8e87",
  sleep: "#93768b",
  money: "#efaa9c",
  travel: "#63c2fa",
  opinion: "#D76F27",
  education: "#cba487",
  diversity: "#f4af54",
  mental_health: "#cf9f4a",
  entertainment: "#ffe57c",
  neurodiversity: "#fcca86",
  physical_health: "#939590",
  science_and_technology: "#bcc8c8",
  family_and_relationships: "#0199cb",
};

export default class Channels {
  /**
   * Normalise the data into a
   * more useable format.
   *
   * @param  {Object} channels
   * @return {Array}
   */
  static normalize = (channels) =>
    channels.map((channel) => ({
      name: Object.values(channel)[0],
      slug: Object.keys(channel)[0].replace(/_/g, "-"),
    }));

  /**
   * Retrieve the channel color by key
   *
   * @param  {string} key
   * @return {string} the hex color of the channel
   */
  static getColorByKey = (key) => (colors[key] ? colors[key] : "");

  /**
   * Retrieve the channel image by key
   *
   * @param  {string} key
   * @return {Object|null} the image from source
   */
  static getImageByKey = (key) => {
    const name = key.replace(/-/g, "_");

    return images[name] ? images[name] : null;
  };
}
