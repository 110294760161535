import { last } from "lodash";
import styled from "styled-components";
import React, { Component, Fragment } from "react";
import { Radio, RadioGroup } from "@blueprintjs/core";

import Toast from "@utils/Toast";
import WellbeingSelect from "@components/WellbeingSelect";
import { QuestionInterface } from "@interfaces/QuestionInterface";

interface Props {
  questions: any;
  submitAssessmentAnswers: any;
}

export default class Assessment extends Component<Props> {
  state = {
    answers: [],
    options: [] as QuestionInterface[],
  };

  /**
   * Set the initial answer state.
   */
  componentDidMount() {
    const { questions } = this.props;

    const answers: any = [];

    questions.forEach((question: any) => {
      answers[question.number] = {
        question: question.number,
        answer: question.type === "slider" ? null : [],
      };
    });

    this.setState({
      answers,
    });
  }

  /**
   * Set the current answer.
   *
   * @param {number} questionNumber
   * @param {number} answer
   */
  setAnswer = (questionNumber: number, answer: number) => {
    const { answers } = this.state;

    answers[questionNumber] = { question: questionNumber, answer };

    this.setState({
      answers,
    });
  };

  /**
   * Get slugified answer title
   * @param  {{ text: string }} answer
   * @return {string}
   */
  getSluggedAnswer = (answer: { text: string }): string => {
    const spaces = answer.text.replace(/ /g, "_");

    return spaces.replace(/-/g, "").toLowerCase();
  };

  /**
   * Set the current answer.
   *
   * @param {number} questionNumber
   * @param {any} answer
   */
  setMultiAnswer = (questionNumber: number, option: number, answer: any) => {
    const { answers } = this.state;

    const questionKey = Number(questionNumber + option);
    // @ts-ignore
    const beChecked = !this.state[questionKey];

    const sluggedAnswer = this.getSluggedAnswer(answer);
    let currentAnswer = answers[questionNumber]
      ? //
        // @ts-ignore
        answers[questionNumber].answer
      : [];

    // Update the answer state
    if (beChecked) {
      // @ts-ignore
      if (last(answers).answer.length === 3) {
        Toast.danger({
          message: "Sorry, you may only select a maximum of 3 preferences.",
        });
      } else {
        currentAnswer.push(sluggedAnswer);
        answers[questionNumber] = {
          // @ts-ignore
          question: questionNumber,
          // @ts-ignore
          answer: [...new Set(currentAnswer)],
        };

        this.setState({ answers, [questionKey]: beChecked });
      }
    } else {
      currentAnswer = currentAnswer.filter(
        // @ts-ignore
        (loopAnswer) => loopAnswer !== sluggedAnswer
      );
      answers[questionNumber] = {
        // @ts-ignore
        question: questionNumber,
        // @ts-ignore
        answer: [...new Set(currentAnswer)],
      };

      this.setState({ answers, [questionKey]: beChecked });
    }
  };

  /**
   * Get a single answer.
   *
   * @param {number} questionNumber
   */
  getAnswer = (questionNumber: number) => {
    const { answers } = this.state;
    // @ts-ignore
    return answers[questionNumber] ? answers[questionNumber].answer : false;
  };

  /**
   * Get a multi answer.
   *
   * @param {number} questionNumber
   */
  getMultiAnswer = (questionNumber: number) => {
    const { options } = this.state;
    return options[questionNumber];
  };

  render() {
    const { answers } = this.state;
    const { questions, submitAssessmentAnswers } = this.props;

    return (
      <Fragment>
        <StyledAssessment>
          {questions.map((question: any) => (
            <StyledAssessmentContainer key={question.number}>
              <StyledAssessmentQuestion>
                <StyledAssessmentText>
                  {`Q${question.number}. ${question.text
                    .replace("7.", "")
                    .trim()}`}
                </StyledAssessmentText>
              </StyledAssessmentQuestion>
              {question.type === "slider" ? (
                <Fragment>
                  <RadioGroup
                    selectedValue={Number(this.getAnswer(question.number))}
                    onChange={(e) => {
                      this.setAnswer(
                        question.number,
                        Number(e.currentTarget.value)
                      );
                    }}
                  >
                    <Radio label="Never" value={1} />
                    <Radio label="Occasionally" value={2} />
                    <Radio
                      value={3}
                      label="Sometimes"
                      className={`test__assessment-radio-${question.number}`}
                    />
                    <Radio label="Often" value={4} />
                    <Radio label="Always" value={5} />
                  </RadioGroup>
                </Fragment>
              ) : (
                <StyledAssessmentMulti>
                  {question.options.map((option: any, key: number) => {
                    const slug = question?.slugs[key];

                    return (
                      <WellbeingSelect
                        key={key}
                        alt={slug}
                        image={`/assets/images/wellbeing/${slug}.jpg`}
                        className={`test__assessment-what-mind-${slug}`}
                        selected={this.state[question.number + key] || false}
                        onClick={() =>
                          this.setMultiAnswer(question.number, key, option)
                        }
                      >
                        <StyledOptionText>{option.text}</StyledOptionText>
                      </WellbeingSelect>
                    );
                  })}
                </StyledAssessmentMulti>
              )}
            </StyledAssessmentContainer>
          ))}
        </StyledAssessment>
        <StyledAssessmentButton
          id="test__assessment-submit"
          onClick={() => submitAssessmentAnswers(answers)}
        >
          COMPLETE
        </StyledAssessmentButton>
      </Fragment>
    );
  }
}

const StyledAssessment = styled.div`
  display: grid;
  grid-gap: 2rem 8rem;
  grid-template-columns: 1fr 1fr;

  > div:last-child {
    grid-column: 1/3;
  }

  &&& {
    .bp3-control .bp3-control-indicator {
      --size: 1.5rem;

      background: #fff;
      font-weight: bold;
      font-size: 1.1rem;
      width: var(--size);
      height: var(--size);
      padding-left: 0.5rem;
      border: 2px solid #fc2d76;
    }

    .bp3-control .bp3-control-indicator::before {
      --size: 0.75rem;

      display: none;
      width: var(--size);
      height: var(--size);
    }

    .bp3-radio .bp3-control-indicator {
      box-shadow: none;
    }

    .bp3-control.bp3-radio input:checked ~ .bp3-control-indicator::before {
      top: 50%;
      left: 50%;
      display: block;
      position: absolute;
      border-radius: 50%;
      background: #fc2d76;
      background-image: none;
      transform: translate(-50%, -50%);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    display: block;

    > div:last-child {
      margin-top: 3rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpointLarge}) {
    grid-gap: 2rem 5rem;
  }
`;

const StyledAssessmentContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    margin: 1rem 0;
  }
`;

const StyledAssessmentQuestion = styled.div`
  display: flex;
  align-items: flex-end;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    justify-content: space-between;
  }
`;

const StyledAssessmentButton = styled.button`
  color: #fff;
  display: block;
  cursor: pointer;
  font-weight: bold;
  margin: 4rem auto;
  letter-spacing: 3px;
  border-radius: 200px;
  padding: 0.75rem 2rem;
  transition: 0.3s ease;
  background: var(--assessmentColorPrimary);

  &:hover {
    opacity: 0.8;
  }
`;

const StyledAssessmentText = styled.p`
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: var(--assessmentColorPrimary);

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    font-size: 1rem;
    line-height: 1.4rem;
  }
`;

const StyledAssessmentMulti = styled.div`
  display: grid;
  grid-gap: 1rem;

  /** IE Fallback */
  @media all and (-ms-high-contrast: none) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    font-size: 1.2rem;
    font-weight: bold;
    grid-template-columns: repeat(4, 1fr);
  }
`;

const StyledOptionText = styled.p`
  font-size: 1rem;
  font-weight: 400;
`;
