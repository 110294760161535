import { capitalize } from "lodash";
import styled from "styled-components";
import { Route, NavLink } from "react-router-dom";
import React, { Fragment, Component } from "react";

interface Props {
  tabs?: any;
  basePath?: string;
}

export default class Tabs extends Component<Props> {
  createPath = (path: any) => {
    // @ts-ignore
    const { basePath } = this.props;

    return basePath ? `/${basePath}/${path}` : `/${path}`;
  };

  render() {
    // @ts-ignore
    const { tabs, className, basePath } = this.props;

    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <div className={className}>
        <StyledTabsHeader>
          {tabs.map((tab: any, key: number) => (
            <StyledTabsTab
              key={key}
              disabled={tab.disabled}
              exact={Boolean(basePath)}
              to={this.createPath(tab.path)}
            >
              {tab.tab || capitalize(tab.path)}
            </StyledTabsTab>
          ))}
        </StyledTabsHeader>

        <Fragment>
          {tabs.map((tab: any, key: number) => (
            <Route
              key={key}
              component={tab.panel}
              exact={Boolean(basePath)}
              path={this.createPath(tab.path)}
            />
          ))}
        </Fragment>
      </div>
    );
  }
}

const StyledTabsHeader = styled.div`
  display: flex;
  overflow-y: auto;
  padding-top: 0.3rem;
  margin-bottom: 2rem;
  -webkit-overflow-scrolling: touch;
`;

const StyledTabsTab = styled(NavLink)`
  color: initial;
  cursor: pointer;
  font-weight: 400;
  padding: 0.5rem 0;
  text-align: center;
  position: relative;
  margin-right: 2rem;
  white-space: nowrap;
  display: inline-block;

  &:after {
    content: "|";
    margin-left: 1rem;
    font-size: 0.9rem;
    position: absolute;
    color: var(--borderColor);
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  &.active,
  &:hover {
    color: var(--brandColorPrimary);
  }
  &:hover {
    opacity: 0.95;
  }

  ${(props: { disabled: boolean }) =>
    props.disabled &&
    `
    cursor: default;
    pointer-events: none;
    color: var(--brand-color-text-4);
  `}
`;
