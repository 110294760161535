import React from "react";
import { Tag } from "@blueprintjs/core";
import styled from "styled-components";

interface Props {
  max: number;
  string: string;
}

const TagLimit = ({ max, string }: Props) => {
  const total: number = max - string.length;

  return total < 0 ? <></> : <StyledTag minimal>{total}</StyledTag>;
};

const StyledTag = styled(Tag)`
  &&& {
    font-size: 0.8rem;
    padding: 2px 5px 0;
    text-align: center;
    margin: 5px 5px 0 15px;

    .bp3-fill {
      color: var(--textColor);
    }
  }
`;

export default TagLimit;
