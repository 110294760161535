import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import styled from "styled-components";
import debounce from "lodash/debounce";
import { Redirect } from "react-router";
import React, { Component, Fragment } from "react";

import api from "@api";
import Tick from "@components/Tick";
import { Title } from "@components/Title";
import EditCircle from "@components/EditCircle";
import ChannelCard from "@components/ChannelCard";
import InputUnderline from "@components/InputUnderline";
import UnidaysLogo from "@assets/images/unidays-logo.png";
import { setProcessing } from "@store/actions/processing";
import { refreshUser } from "@store/actions/authentication";
import { IIntegration } from "@interfaces/IntegrationInterface";
import DialogConfirmationForm from "@components/DialogConfirmationForm";

import {
  emailUpdate,
  channelsUpdate,
  passwordUpdate,
  personalisedEmailsUpdate,
} from "@store/actions/user";

const mapDispatchToProps = (dispatch: any) => ({
  dispatchRefreshUser: () => dispatch(refreshUser()),
  dispatchEmailUpdate: (email: any) => dispatch(emailUpdate(email)),
  dispatchChannelsUpdate: (channels: any) => dispatch(channelsUpdate(channels)),
  dispatchSetProcessing: (processing: any) =>
    dispatch(setProcessing(processing)),
  dispatchPersonalisedEmailsUpdate: (checked: any) =>
    dispatch(personalisedEmailsUpdate(checked)),
  dispatchPasswordUpdate: (current: string, password: string) =>
    dispatch(passwordUpdate(current, password)),
});

const mapStateToProps = ({ user, channels }: any) => ({
  user,
  channels,
});

class AccountContainer extends Component<any> {
  state = {
    integrations: [],
    displayEmail: "",
    displayPassword: "",
    selectedChannels: [],
    dialogEmailOpen: false,
    redirectToLogout: false,
    dialogPasswordOpen: false,
    personalisedEmailsChecked: false,
  };

  channelsDebounce = debounce(() => this.attemptChannelsUpdate(), 1000);

  personalisedEmailsDebounce = debounce(
    () => this.attemptPersonalisedEmailsUpdate(),
    250
  );

  componentDidMount() {
    // @ts-ignore
    const { dispatchRefreshUser } = this.props;

    dispatchRefreshUser().then((user: any) => {
      this.setState({
        displayEmail: user.work_email,
        displayPassword: "passwordtext",
        selectedChannels: user.channels,
        personalisedEmailsChecked: user.personalised_email_optin,
      });
    });

    api
      .get("/integrations")
      .then(({ data }) => {
        this.setState({
          ...this.state,
          integrations: data.payload.integrations,
        });
      })
      .catch((err) => console.log(err));
  }

  attemptChannelsUpdate = async () => {
    const { selectedChannels } = this.state;
    // @ts-ignore
    const { dispatchChannelsUpdate } = this.props;

    await dispatchChannelsUpdate(selectedChannels).then(() => {
      this.refreshUserAfterWait();
    });
  };

  attemptPersonalisedEmailsUpdate = async () => {
    const { personalisedEmailsChecked } = this.state;
    // @ts-ignore
    const { dispatchPersonalisedEmailsUpdate } = this.props;

    await dispatchPersonalisedEmailsUpdate(personalisedEmailsChecked).then(
      () => {
        this.refreshUserAfterWait();
      }
    );
  };

  attemptEmailChange = async (email: string) => {
    // @ts-ignore
    const { dispatchEmailUpdate } = this.props;

    await dispatchEmailUpdate(email).then((newEmail: any) => {
      if (typeof newEmail === "string") this.redirectAccountProcessing();
    });
  };

  attemptPasswordChange = async (passwordData: Object) => {
    // @ts-ignore
    const { dispatchPasswordUpdate } = this.props;

    // @ts-ignore
    await dispatchPasswordUpdate(passwordData.current, passwordData.new).then(
      (success: boolean) => {
        if (success) this.redirectAccountProcessing();
      }
    );
  };

  redirectAccountProcessing = () => {
    // @ts-ignore
    const { dispatchSetProcessing } = this.props;

    dispatchSetProcessing(true);
    setTimeout(() => this.setState({ redirectToLogout: true }), 250);
  };

  refreshUserAfterWait = () => {
    // @ts-ignore
    const { dispatchRefreshUser } = this.props;

    setTimeout(() => dispatchRefreshUser(), 3500);
  };

  handlePersonalisedEmailsChange = () => {
    const { personalisedEmailsChecked } = this.state;

    this.setState({ personalisedEmailsChecked: !personalisedEmailsChecked });
    this.personalisedEmailsDebounce();
  };

  handleChannelChange = (key: string) => {
    const { selectedChannels } = this.state;

    let newSelected = selectedChannels;

    // @ts-ignore
    if (selectedChannels.includes(key)) {
      newSelected = selectedChannels.filter((answer) => answer !== key);
    } else {
      // @ts-ignore
      newSelected.push(key);
    }

    // @ts-ignore
    this.setState({ selectedChannels: newSelected });
    this.channelsDebounce();
  };

  render() {
    // @ts-ignore
    const { channels } = this.props;
    const { redirectToLogout } = this.state;

    if (redirectToLogout) return <Redirect to="/logout" />;

    const {
      displayEmail,
      displayPassword,
      dialogEmailOpen,
      selectedChannels,
      dialogPasswordOpen,
      personalisedEmailsChecked,
    } = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>Account - {process.env.REACT_APP_COMPANY_NAME}</title>
        </Helmet>
        <Title>Account</Title>

        <StyledSeperation>
          <StyledSection>
            <StyledTitle as="h3">Personal Settings</StyledTitle>

            <StyledInputDivide>
              <InputUnderline
                disabled={true}
                type="password"
                name="password"
                label="Password"
                defaultValue={displayPassword}
              />
              <EditCircle
                id="test__password-edit-icon"
                onClick={() => this.setState({ dialogPasswordOpen: true })}
              />
              <DialogConfirmationForm
                current={true}
                type="password"
                label="New password"
                open={dialogPasswordOpen}
                currentLabel="Current password"
                heading="Change your account password"
                onClose={() => this.setState({ dialogPasswordOpen: false })}
                onUpdate={(passwordData) =>
                  this.attemptPasswordChange(passwordData)
                }
              />
            </StyledInputDivide>

            <StyledInputDivide>
              <InputUnderline
                disabled={true}
                name="email"
                label="Email address"
                defaultValue={displayEmail}
              />
              <EditCircle
                id="test__email-edit-icon"
                onClick={() => this.setState({ dialogEmailOpen: true })}
              />
              <DialogConfirmationForm
                label="New email"
                open={dialogEmailOpen}
                heading="Change your email"
                // @ts-ignore
                onUpdate={(email) => this.attemptEmailChange(email)}
                onClose={() => this.setState({ dialogEmailOpen: false })}
              />
            </StyledInputDivide>

            <StyledInputDivide>
              <InputUnderline
                disabled={true}
                name="marketing"
                label="Weekly email"
                defaultValue="Personalised emails"
              />
              <Tick
                id="test__weekly-email-tick"
                ticked={personalisedEmailsChecked}
                onClick={this.handlePersonalisedEmailsChange}
              />
            </StyledInputDivide>

            {this.state.integrations.length > 0 && (
              <>
                <StyledTitle as="h3">Account Integrations</StyledTitle>
                {this.state.integrations.map((integration: IIntegration) => (
                  <>
                    <StyledIntegrationContainer>
                      <StyledUnidaysLogo src={UnidaysLogo} />
                      <StyledIntegrationName>
                        {integration.title} - you can use {integration.title} to
                        login to Miindset.
                      </StyledIntegrationName>
                    </StyledIntegrationContainer>
                  </>
                ))}
              </>
            )}
          </StyledSection>

          <StyledSection>
            <StyledTitle as="h3">Channels</StyledTitle>

            <StyledChannels>
              {channels.map((channel: any) => {
                const key = channel.slug;

                return (
                  <StyledChannel key={key}>
                    <ChannelCard slug={key} name={channel.name} />
                    <Tick
                      // @ts-ignore
                      ticked={selectedChannels.includes(key)}
                      id={`test__channel-${channel.slug}-tick`}
                      onClick={() => this.handleChannelChange(key)}
                    />
                  </StyledChannel>
                );
              })}
            </StyledChannels>
          </StyledSection>
        </StyledSeperation>
      </Fragment>
    );
  }
}

const StyledSeperation = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    display: block;
  }
`;

const StyledSection = styled.div`
  width: 45%;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    width: 100%;
  }
`;

const StyledTitle = styled.h3`
  padding-bottom: 1rem;
`;

const StyledInputDivide = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    flex-grow: 1;
    padding-right: 2rem;
  }
`;

const StyledChannels = styled.ul`
  padding: 0;
`;

const StyledChannel = styled.li`
  display: flex;
  padding: 1rem 0 0.8rem;
  justify-content: space-between;
  border-bottom: 1px solid var(--brandColorSecondary);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const StyledIntegrationContainer = styled.div`
  padding: 1rem;
  display: flex;
  border-radius: 8px;
  align-items: center;
  border: 1px solid #ccc;
`;

const StyledIntegrationName = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  margin-left: 12px;
`;

const StyledUnidaysLogo = styled.img`
  height: 40px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(AccountContainer);
