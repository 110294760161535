import React from "react";
import styled from "styled-components";

import { ResponsiveContainer } from "recharts";

const ResponsivePieContainer = ({ children, className }: any) => (
  <StyledContainer className={className}>{children}</StyledContainer>
);

const StyledContainer = styled(ResponsiveContainer)`
  width: 100%;
  max-width: 500px;
  min-height: 580px;
  margin: 0 auto 2rem;

  &.small {
    min-height: 350px;
  }

  &.legend {
    min-height: 30px;
  }

  .recharts-legend-item {
    padding: 6px 4px;

    .recharts-legend-item-text {
      padding-left: 4px;
    }

    .recharts-surface {
      width: 13px;
      height: 14px;
      margin-top: -3px;
      border-radius: 4px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    min-height: 300px;
  }
`;
export default ResponsivePieContainer;
