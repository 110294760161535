import styled from "styled-components";
import React, { Component } from "react";
import { first, isEqual } from "lodash-es";
import { Button, MenuItem } from "@blueprintjs/core";
import { Select as BlueprintSelect } from "@blueprintjs/select";

import FormGroup from "@components/FormGroup";

interface SelectItemInterface {
  id?: string;
  name?: string;
  /** The text that is visually displayed in the dropdown */
  text: string;
  /** The option value of the item */
  value: string;
  label?: string;
  icon?: React.Component;
}

interface Props {
  id?: string;
  name?: string;
  label?: string;
  disabled?: boolean;
  className?: string;
  hasSearch?: boolean;
  helperText?: string;
  placeholder?: string;
  defaultValue?: string;
  /** If the input should immediately clear on selection */
  clearOnSelect?: boolean;
  /** An array of items that should be excluded from the select dropdown */
  excludedItems?: string[];
  /* call a custom method onChange */
  onChange?: (value: string, name: string, inputName: string) => void;
  /**
   * Items can either be a very simple array of strings, or they can
   * be a more complex object of values
   *
   * @see SelectInterface for a full list of properties
   */
  items?: SelectItemInterface[] | string[];
}

// Select<T> is a generic component to work with your data types.
// In TypeScript, you must first obtain a non-generic reference:
const GenericSelect = BlueprintSelect.ofType<SelectItemInterface>();

export class Select extends Component<Props> {
  public state = {
    selectedItem: {
      text: "",
      value: "",
    },
    items: [
      {
        text: "",
        value: "",
      },
    ] as SelectItemInterface[],
  };

  public static defaultProps = {
    hasSearch: true,
  };

  public componentDidMount() {
    this.setItems();
  }

  /**
   * @param {SelectInterface} nextProps
   */
  public componentDidUpdate(nextProps: Props) {
    const { items, excludedItems } = this.props;

    if (
      !isEqual(nextProps.items, items) ||
      !isEqual(nextProps.excludedItems, excludedItems)
    ) {
      this.setItems();
    }
  }

  /**
   * Set the current list item's and exclude any
   * that have already been selected previously.
   */
  private setItems(): void {
    const { items, excludedItems } = this.props;

    const convertedItems = excludedItems
      ? this.convertItemsToObjects(items).filter(
          (item) => !excludedItems.includes(item.value)
        )
      : this.convertItemsToObjects(items);

    this.setState({
      selectedItem: {
        text: "",
        value: "",
      },
      items: convertedItems,
    });
  }

  /**
   * If the items props were passed as an array of strings
   * auto convert these to match the SelectItemInterface interface
   *
   * @param   {SelectItemInterface[] | string[]} items
   * @returns {SelectItemInterface[]}
   */
  private convertItemsToObjects = (
    items: SelectItemInterface[] | string[]
  ): SelectItemInterface[] => {
    if (typeof first(items as any) === "string") {
      return (items as string[]).map((item: string) => {
        return {
          text: item,
          value: item,
        };
      });
    }

    return items as SelectItemInterface[];
  };

  /**
   * Set the currently selected item,
   * and call a callback (if one exists)
   *
   * @param {SelectItemInterface} item
   */
  private handleOnChange = (item: SelectItemInterface): void => {
    const { onChange, name } = this.props;

    this.setState({ selectedItem: item });
    if (onChange) onChange(item.value, item.text, name);
  };

  /**
   * Determine if any items match
   * the specified query.
   *
   * @param   {string} query
   * @param   {SelectItemInterface}
   * @returns {boolean}
   */
  private handleSearch = (
    query: string,
    items: SelectItemInterface[]
  ): SelectItemInterface[] => {
    const { hasSearch } = this.props;

    return hasSearch
      ? items
          .filter(
            (item) =>
              query && item.text.toLowerCase().includes(query.toLowerCase())
          )
          .filter((_, index) => index < 5)
      : items;
  };

  public render(): JSX.Element {
    const { selectedItem, items = [] } = this.state;
    const {
      id,
      name,
      label,
      disabled,
      hasSearch,
      className,
      helperText,
      placeholder,
      defaultValue,
      clearOnSelect,
    } = this.props;

    const defaultSelectedItem = items.find(
      (item) => item.value === defaultValue
    );

    const currentlySelectedItemText = !clearOnSelect
      ? selectedItem.text || (defaultSelectedItem && defaultSelectedItem.text)
      : "";

    const currentlySelectedItemValue = !clearOnSelect
      ? selectedItem.value ||
        (defaultSelectedItem && defaultSelectedItem.value) ||
        ""
      : "";

    return (
      <StyledSelect label={label} helperText={helperText}>
        <GenericSelect
          items={items}
          onItemSelect={null}
          disabled={disabled}
          resetOnClose={true}
          filterable={hasSearch}
          itemListPredicate={this.handleSearch}
          popoverProps={{ fill: true, usePortal: false }}
          itemRenderer={(item: SelectItemInterface) => (
            <MenuItem
              key={item.text}
              text={item.text}
              label={item.label}
              id={`test_${item.text}`}
              onClick={() => this.handleOnChange(item)}
            />
          )}
          noResults={<MenuItem disabled={true} text="No results found" />}
        >
          <StyledSelectButton
            id={id}
            fill={true}
            disabled={disabled}
            className={className}
            rightIcon="double-caret-vertical"
            text={
              currentlySelectedItemText ||
              (placeholder === "" ? " " : placeholder || "Please select...")
            }
          />
        </GenericSelect>
        <input name={name} type="hidden" value={currentlySelectedItemValue} />
      </StyledSelect>
    );
  }
}

const StyledSelect = styled(FormGroup)`
  .bp3-popover-target {
    width: 100%;
    display: block;

    .bp3-button.bp3-fill {
      height: 44px;
      width: 100%;
      display: flex;
      padding-left: 0;
      border-radius: 0;
      box-shadow: none;
      font-weight: 600;
      font-size: 1.2em;
      margin-bottom: 0.8rem;
      background-image: none;
      background-color: white;
      color: var(--textColor);
      justify-content: space-between;
      border-bottom: 1px solid var(--textColor);

      .bp3-icon > svg {
        margin-top: -3px;
        color: var(--borderColor);
      }
    }
  }
`;

const StyledSelectButton = styled(Button)``;
