import { startCase, lowerCase } from "lodash";

export default class Text {
  /**
   * Convert string to seperate words.
   * usefull for things such as converting url slugs.
   *
   * "my-url-slug" becomes "My Url Slug"
   *
   * @param  {string} text
   * @return {string}
   */
  static toWords = (text) =>
    text === "lgbtq" ? "LGBTQ+" : startCase(lowerCase(text));
}
