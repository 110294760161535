import { createGlobalStyle } from "styled-components";

const ThemeStyles = createGlobalStyle`
  :root {
    --bodyText: #01273e;
    --textColor: #4E4D4D;
    --textColor2: #1b1b1b;
    --borderColor: #e2e2e2;
    --cookieNoticeLink: #f6a21d;

    --danger: #a01919;

    --brandColorPrimary: #E40D7E;
    --brandColorPrimary2: #c93c6e;
    --brandColorPrimary3: #da789b;
    --brandColorPrimary4: #91284d;
    --brandColorPrimary5: #91284d;
    --brandColorPrimaryDarker: #7d1f41;
    
    --brandColorTertiary: #f0c6ab;
    --brandColorSecondary: #012536;

    --managersMutedText: #828282;
    --managerPlaceholder: #eaeaea;
    --cookieNoticeButton: #fea602;

    --assessmentColorGrey: #666;
    --assessmentColorPrimary: var(--brandColorPrimary);
    --assessmentColorSecondary: #666;


    --defaultTransition: 0.3s ease;

    --sidebarWidth: 400px;
    --containerWidth: 1200px;

    --gridGap: 2rem;
    --playButtonBackground: #0a0a0a;
    --contentHoverBackground: #f9f9f9;
    --placeholderImageBackground: #e9e9e9;
  }
`;

export default ThemeStyles;
