export const generateUnidaysLink = (): string => {
  const params = new URLSearchParams();

  params.append("response_type", "code");
  params.append("client_id", window.env.REACT_APP_UNIDAYS_CLIENT_ID);
  params.append("scope", "openid name email verification offline_access");
  // prettier-ignore
  params.append("redirect_uri", window.env.REACT_APP_UNIDAYS_AUTHORIZATION_URL);

  return `https://account.myunidays.com/oauth/authorize?${params.toString()}`;
};
