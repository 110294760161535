import styled from "styled-components";

export default styled.aside`
  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 2.5rem;
    width: var(--sidebarWidth);
    flex-basis: var(--sidebarWidth);
  }
`;
