import { useSelector } from "react-redux";

import { OrganisationInterface } from "@interfaces/OrganisationInterface";

interface Props {
  organisation: OrganisationInterface;
}

export const useOrganisation = () => {
  const { organisation }: Props = useSelector(({ organisation }: Props) => ({
    organisation,
  }));

  return { organisation };
};

export default useOrganisation;
