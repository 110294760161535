import { merge } from "lodash";
import styled from "styled-components";
import React, { Component } from "react";
import { Dialog, Button } from "@blueprintjs/core";

import InputUnderline from "./InputUnderline";

interface Props {
  onClose: any;
  label: string;
  type?: string;
  open: boolean;
  heading: string;
  current?: boolean;
  currentLabel?: string;
  onUpdate: (newData: string | Object) => any;
}

export default class DialogConfirmationForm extends Component<Props> {
  state = {
    open: false,
    newData: "",
    loading: false,
    currentData: "",
    newDataIntent: "",
    newDataConfirm: "",
    currentDataIntent: "",
    newDataHelperText: "",
    newDataConfirmIntent: "",
    currentDataHelperText: "",
    newDataConfirmHelperText: "",
  };

  static defaultProps = {
    type: "text",
    current: false,
    currentLabel: "",
  };

  componentDidMount() {
    const { open } = this.props;

    this.setState({ open });
  }

  componentDidUpdate(prevProps: Object) {
    const { open } = this.props;

    // @ts-ignore
    if (open !== prevProps.open) {
      this.changeOpenState(open);
    }
  }

  handleInputFocus = (name: string) => {
    this.setState({ [`${name}Intent`]: "", [`${name}HelperText`]: "" });
  };

  handleInputChange = (name: string, value: string) => {
    this.setState({ [name]: value });
  };

  handleFormSubmit = (event: Object) => {
    // @ts-ignore
    event.preventDefault();

    const { current } = this.props;
    const { currentData, newData, newDataConfirm } = this.state;

    const currentDataState = !currentData && {
      currentDataIntent: "danger",
      currentDataHelperText: "This is a required field",
    };

    const newDataState = !newData && {
      newDataIntent: "danger",
      newDataHelperText: "This is a required field",
    };

    const newDataConfirmState = (!newDataConfirm ||
      newDataConfirm !== newData) && {
      newDataConfirmIntent: "danger",
      newDataConfirmHelperText: "The fields you provided do not match",
    };

    const state = merge(
      current && currentDataState,
      newDataState,
      newDataConfirmState
    );

    if (Object.keys(state).length) {
      this.setState(state);
    } else {
      this.setState({ loading: true }, this.attemptFormSubmit);
    }
  };

  attemptFormSubmit = async () => {
    const { current, onUpdate } = this.props;
    const { currentData, newData } = this.state;

    await onUpdate(!current ? newData : { current: currentData, new: newData });

    this.setState({ loading: false });
  };

  changeOpenState(open: boolean) {
    this.setState({ open });
  }

  render() {
    const { onClose, heading, label, current, currentLabel, type } = this.props;

    const {
      open,
      loading,
      newData,
      currentData,
      newDataIntent,
      newDataConfirm,
      currentDataIntent,
      newDataHelperText,
      newDataConfirmIntent,
      currentDataHelperText,
      newDataConfirmHelperText,
    } = this.state;

    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <Dialog
        isOpen={open}
        title={heading}
        canEscapeKeyClose
        onClose={onClose}
        canOutsideClickClose
      >
        <StyledDialogInner
          onSubmit={(event: any) => this.handleFormSubmit(event)}
        >
          {current && (
            <InputUnderline
              type={type}
              name="currentData"
              label={currentLabel}
              intent={currentDataIntent}
              defaultValue={currentData}
              onFocus={this.handleInputFocus}
              onChange={this.handleInputChange}
              helperText={currentDataHelperText}
            />
          )}
          <InputUnderline
            type={type}
            name="newData"
            label={label}
            intent={newDataIntent}
            defaultValue={newData}
            helperText={newDataHelperText}
            onFocus={this.handleInputFocus}
            onChange={this.handleInputChange}
          />
          <InputUnderline
            type={type}
            name="newDataConfirm"
            intent={newDataConfirmIntent}
            defaultValue={newDataConfirm}
            onFocus={this.handleInputFocus}
            onChange={this.handleInputChange}
            helperText={newDataConfirmHelperText}
            label={`Confirm ${label.toLowerCase()}`}
          />
          <StyledButtonArea>
            <Button
              large
              type="submit"
              intent="primary"
              loading={loading}
              rightIcon="chevron-right"
            >
              Save Changes
            </Button>
          </StyledButtonArea>
        </StyledDialogInner>
      </Dialog>
    );
  }
}

const StyledDialogInner = styled.form`
  padding: 2rem 1.5rem;
`;

const StyledButtonArea = styled.div`
  display: block;
  text-align: right;
`;
