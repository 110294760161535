export default class PasswordStrength {
  /**
   * Checks if string has number
   *
   * @param  {string} password
   * @return {boolean}
   */
  static hasNumber = (password) => new RegExp(/[0-9]/).test(password);

  /**
   * Checks if string has special characters
   *
   * @param  {string} password
   * @return {boolean}
   */
  static hasSpecial = (password) =>
    new RegExp(/[!#@$%^&*)(+=._-]/).test(password);

  /**
   * Checks if string has mixed characters
   *
   * @param  {string} password
   * @return {boolean}
   */
  static hasCapitalMixed = (password) =>
    new RegExp(/[a-z]/).test(password) && new RegExp(/[A-Z]/).test(password);
}
