import { Toaster, Intent, Position } from "@blueprintjs/core";

const toaster = Toaster.create({
  className: "toaster-primary",
  position: Position.TOP_RIGHT,
});

export default class Toast {
  static show = ({ intent, message, icon }) => {
    toaster.show({
      icon,
      intent,
      message,
    });
  };

  static info = (args) => {
    Toast.show({ ...{ intent: Intent.INFO }, ...args });
  };

  static success = (args) => {
    Toast.show({
      ...{
        icon: "tick-circle",
        intent: Intent.SUCCESS,
      },
      ...args,
    });
  };

  static danger = (args) => {
    Toast.show({
      ...{
        icon: "ban-circle",
        intent: Intent.DANGER,
      },
      ...args,
    });
  };
}
