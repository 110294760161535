import styled from "styled-components";

export default styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  background: var(--brandColorPrimary);
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: ${({ theme }) => theme.breakpointLarge}) {
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    position: fixed;
    min-width: 19rem;
    overflow-y: auto;
    flex-direction: column;
    transform: translateX(-100%);
    transition: var(--defaultTransition);

    &.active {
      transform: translateX(0%);
    }
  }
`;
