import style from "styled-components";
import { NavLink } from "react-router-dom";

export default style(NavLink)<{ to: string }>`
    height: 100%;
    padding: 0.95rem;
    display: block;
    color: inherit;
    font-weight: bold;
    cursor: pointer;
    font-size: 0.9rem;
    position: relative;
    padding-top: 1rem;
    letter-spacing: 0.3px;
    text-decoration: none;
    text-transform: capitalize;
    transition: var(--defaultTransition);

    &:hover {
      color: white;
      font-size: 0.9rem;
      text-decoration: none;
      background: rgba(255,255,255,.05);
    }

    &.manager {
      background-color: var(--brandColorPrimaryDarker) !important;
    }

    /** The line that displays under the link */
    &:after{
        content: "";
        right: 0;
        bottom: 0;
        height: 0;
        width: 100%;
        display: block;
        background: #fff;
        position: absolute;
        transition: var(--defaultTransition);
    }

    &.active:after{
        height: 3px;
    }

    @media(max-width: ${({ theme }) => theme.breakpointLarge}){
        border-bottom: 1px solid rgba(0,0,0,0.2);

        &:after{
            width: 0;
            height: 100%;
        }

        &.active:after{
            width: 3px;
            height: 100%;
        }
    }
`;
