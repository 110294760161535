import { QuestionInterface } from "@interfaces/QuestionInterface";

export default class Assesment {
  /**
   * Normalise the data into a
   * more useable format.
   *
   * @param  {any} questions
   * @return {Array}
   */
  static normalize = (questions: any): QuestionInterface[] => {
    const questionValues = Object.values<QuestionInterface>(questions);

    return questionValues.map((question) => {
      return {
        answer: question.answer,
        number: Number(question.number),
        // Some questions that come back from the api contain
        // erroneous characters, let's strip those out.
        text: question.text.replace("\\", ""),
        options: Object.values(question.options),
        slugs: Object.keys(question.options),
        // The only way to tell if a question should be multiple choice is to check if it
        // has an answer, sliders do not seem to have any answers in the response.
        // The last question is the only one with sliders.
        type:
          question.number === questionValues.length
            ? "multiple-choice"
            : "slider",
      };
    });
  };
}
