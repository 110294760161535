import moment from "moment";
import styled from "styled-components";
import React, { useState } from "react";
import FormGroup from "@components/FormGroup";
import { DateInput as BPDateInput } from "@blueprintjs/datetime";

interface IDateInput {
  value?: Date;
  name?: string;
  label: string;
  minDate?: Date;
  defaultValue?: string;
  onChange?: (name: string, date: Date) => void;
}

const DateInput = ({
  name,
  value,
  label,
  minDate,
  onChange,
  defaultValue,
}: IDateInput) => {
  const [date, setDate] = useState<string>("");

  function formatDate(selectedDate: Date) {
    return moment(selectedDate).format("YYYY-MM-DD HH:mm:ss");
  }

  function parseDate(date?: string, _?: string): Date | false | null {
    return moment(date, "DD/MM/YYYY").toDate();
  }

  function handleOnChange(selectedDate: Date) {
    setDate(formatDate(selectedDate));
    if (onChange) onChange(name, selectedDate);
  }

  return (
    <StyledFormGroup label={label}>
      <StyledBPDateInput
        value={value}
        minDate={minDate}
        canClearSelection={false}
        onChange={handleOnChange}
        parseDate={parseDate}
        formatDate={(date) => moment(date).format("DD/MM/YYYY")}
      />
      <input type="hidden" name={name} value={date || defaultValue} />
    </StyledFormGroup>
  );
};

const StyledFormGroup = styled(FormGroup)`
  color: var(--textColor);

  input {
    width: 100%;
    height: 44px;
    padding-left: 0;
    font-weight: 600;
    box-shadow: none;
    border-radius: 0;
    padding-right: 0;
    line-height: 44px;
    margin-bottom: 0.8rem;
    color: var(--textColor);
    border-bottom: 1px solid;
  }

  .bp3-popover-wrapper {
    border-width: 0 !important;
  }
  .bp3-popover-target {
    width: 100%;
  }

  .bp3-icon > svg {
    margin-top: -3px;
    color: var(--borderColor);
  }

  .bp3-datepicker-caption select + .bp3-icon {
    display: none;
  }

  .bp3-datepicker-year-select {
    min-width: 90px;
  }

  .DayPicker-Day.DayPicker-Day--selected {
    color: white;
    background-color: var(--brandColorPrimary);
  }

  .DayPicker-Day {
    height: 26px;
    color: var(--textColor);
  }

  .bp3-datepicker-day-wrapper {
    padding: 4px 7px 0;
  }

  .bp3-timepicker .bp3-timepicker-input-row {
    color: white;
    height: 34px;
    margin: 0 4px;
    display: block;
    box-shadow: none;
    background-color: var(--brandColorPrimary);
  }

  .bp3-timepicker .bp3-timepicker-divider-text {
    width: 10px;
    color: white;
    font-size: 0.9rem;
  }

  .DayPicker-Weekday {
    color: #a0acb6;
    font-weight: 300;
  }

  .DayPicker-Caption {
    margin-bottom: 5px;
  }

  .bp3-timepicker .bp3-timepicker-input {
    width: 80px;
    color: white;
    height: auto;
    padding: 6px 0;
    margin-top: 3px;
    text-align: left;
    border-radius: 0;

    &:focus,
    &:hover {
      color: #d2b2bd;
      box-shadow: none;
    }

    &.bp3-timepicker-hour {
      text-align: right;
    }
  }

  .bp3-icon > svg:not([fill]) {
    fill: var(--borderColor);
  }
`;

const StyledBPDateInput = styled(BPDateInput)`
  .bp3-html-select select {
    font-size: 14px !important;
  }
`;

export default DateInput;
