import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Button } from "@blueprintjs/core";
import React, { PureComponent, useEffect } from "react";
import { Link, RouteComponentProps } from "react-router-dom";

import ContainerCentered from "@components/ContainerCentered";
import { loginStudent } from "@store/actions/student";

const mapDispatchToProps = (dispatch: any) => ({
  loginStudentUser: (token: string) => dispatch(loginStudent(token)),
});

interface Props extends RouteComponentProps {
  match: {
    url: string;
    path: string;
    isExact: boolean;
    params: {
      partialId: string;
    };
  };
  dispatch: any;
  loginStudentUser: (token: string) => Promise<boolean | void>;
}

export const StudentOAuthSuccessContainer = connect(
  null,
  mapDispatchToProps
)(({ match, history, loginStudentUser }: Props) => {
  useEffect(() => {
    const { params } = match;

    loginStudentUser(params.partialId).then((success) => {
      if (success) {
        setTimeout(() => history.push("/"), 200);
      }
    });
  }, []);

  return (
    <ContainerCentered>
      <Helmet>
        <title>Successfully Authorised</title>
      </Helmet>
      <div>
        <h2>Successfully authorised with UNiDAYS</h2>
      </div>
    </ContainerCentered>
  );
});
