import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import React, { PureComponent, Fragment } from "react";

import Container from "./Container";
import ChannelCard from "./ChannelCard";
import { UserInterface } from "@interfaces/UserInterface";

interface Props {
  channels?: any;
  user?: UserInterface;
}

const mapStateToProps = ({ user, channels }: any) => ({
  user,
  channels,
});

class Footer extends PureComponent<Props> {
  render() {
    const { user, channels } = this.props;

    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <Fragment>
        <StyledFooter>
          {user.isAuthenticated && (
            <StyledFooterContainer>
              <div>
                <StyledFooterNavPrimary>
                  {channels.map((channel: any) => (
                    <StyledFooterChannelNavItem key={channel.slug}>
                      <Link to={`/channel/${channel.slug}`}>
                        <ChannelCard
                          fontSize={"1rem"}
                          name={channel.name}
                          slug={channel.slug}
                        />
                      </Link>
                    </StyledFooterChannelNavItem>
                  ))}
                </StyledFooterNavPrimary>
              </div>
            </StyledFooterContainer>
          )}
          <StyledFooterBottom>
            <div>
              <span className="mr-4">{`Copyright ${new Date().getFullYear()} ${
                process.env.REACT_APP_COMPANY_NAME
              }`}</span>
              <Link className="mr-4" to="/privacy-policy">
                Privacy Policy
              </Link>
              <Link className="mr-4" to="/terms-and-conditions">
                Terms & Conditions
              </Link>
              <Link className="mr-4" to="/security">
                Security
              </Link>
            </div>
          </StyledFooterBottom>
        </StyledFooter>
      </Fragment>
    );
  }
}

const StyledFooter = styled.footer`
  &&& {
    color: black;
    margin-top: auto;
    background-color: #f0f0f0;

    a {
      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

const StyledFooterContainer = styled(Container)`
  padding: 5rem 1rem;

  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: ${({ theme }) => theme.breakpointSmall}) {
    padding: 2.5rem 1rem;
  }
`;

const StyledFooterNav = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0 0 1rem 0;
`;

const StyledFooterNavPrimary = styled(StyledFooterNav)`
  /** IE Fallback */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledFooterChannelNavItem = styled.li`
  padding-bottom: 0.7rem;
  text-transform: capitalize;

  // IE Fallback
  @media all and (-ms-high-contrast: none) {
    width: calc(100% / 4);
  }

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    flex-basis: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    flex-basis: 20%;
  }

  @media (max-width: ${({ theme }) => theme.breakpointSmall}) {
    display: inline-block;
    padding-right: 1.2rem;
  }
`;

const StyledFooterBottom = styled.div`
  color: #ccc;
  padding: 1rem;
  display: flex;
  justify-content: center;
  background: var(--brandColorSecondary);

  @media (max-width: ${({ theme }) => theme.breakpointSmall}) {
    font-size: 0.9rem;
    line-height: 1.5rem;

    a {
      margin-right: 0.5rem;
      display: inline-block;
    }
  }
`;

export default connect(mapStateToProps, null)(Footer);
