export default class Enums {
  static getEnumPair(e: any): any[] {
    const types: any[] = [];
    const namesValuesList = Enums.getNamesAndValues(e);
    namesValuesList.forEach((pair) => {
      const type = { id: pair.value.toString(), name: pair.name };
      types.push(type);
    });
    return types;
  }

  static getNamesAndValues<T>(e: any): { name: string; value: T }[] {
    return this.getNames(e).map((_name) => {
      return { name: _name, value: e[_name] as T };
    });
  }

  static getNames(e: any): string[] {
    return Object.keys(e);
  }

  static getNameFromValue<T>(e: any, value: T): string | null {
    const all = this.getNamesAndValues(e).filter(
      (pair) => pair.value === value
    );
    return all.length === 1 ? all[0].name : null;
  }

  static getValues<T>(e: any): T[] {
    return this.getNames(e).map((name) => e[name]) as T[];
  }
}
