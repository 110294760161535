import React from "react";

import styled from "styled-components";
import { Button } from "@blueprintjs/core";
import { PaginationInterface } from "@interfaces/PaginationInterface";

interface Props {
  className: string;
  isLoading?: boolean;
  currentPage?: number;
  pagination?: PaginationInterface;
  onPageChange?: (page: number) => void;
}

export const Pagination = ({
  className,
  currentPage,
  onPageChange,
  isLoading = false,
}: Props): JSX.Element => {
  return (
    <StyledButtons className={className}>
      <Button
        disabled={currentPage < 1 || isLoading}
        className="mr-4"
        onClick={() => onPageChange(currentPage - 1)}
      >
        Prev
      </Button>
      <Button
        disabled={isLoading}
        onClick={() => onPageChange(currentPage + 1)}
      >
        Next
      </Button>
    </StyledButtons>
  );
};

const StyledButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
