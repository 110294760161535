import { connect } from "react-redux";
import { PureComponent } from "react";
import { withRouter } from "react-router";

import { logout } from "../store/actions/authentication";

const mapDispatchToProps = (dispatch: any) => ({
  dispatchLogout: () => dispatch(logout()),
});

interface Props {
  history: $TSFixMe;
  dispatchLogout: $TSFixMe;
}

class LoginContainer extends PureComponent<Props> {
  state = {
    shouldRedirect: false,
  };

  async componentDidMount() {
    const { dispatchLogout, history } = this.props;

    await dispatchLogout();

    history.push("/login");
  }

  render() {
    return null;
  }
}

export default withRouter(
  // @ts-ignore
  connect(null, mapDispatchToProps)(LoginContainer)
);
