import { connect } from "react-redux";
import styled from "styled-components";
import React, { Component } from "react";

import Loader from "./Loader";

import { setProcessing } from "../store/actions/processing";

const mapStateToProps = ({ processing }: any) => ({
  processing,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSetProcessing: (processing: any) =>
    dispatch(setProcessing(processing)),
});

interface Props {
  children: any;
  processing: boolean;
  dispatchSetProcessing: (processing: boolean) => any;
}

/**
 * This is a fake "processing" component often the API
 * takes a while to process requests so this is
 * in place whilst that happens
 */
class FormProcessor extends Component<Props> {
  state = {
    timeout: null,
  };

  componentDidMount() {
    const { processing, dispatchSetProcessing } = this.props;

    if (processing === true) {
      const timeout = setTimeout(() => dispatchSetProcessing(false), 12000);
      this.setState({ timeout });
    }
  }

  componentWillUnmount() {
    const { timeout } = this.state;
    const { dispatchSetProcessing } = this.props;

    dispatchSetProcessing(false);
    // @ts-ignore
    clearTimeout(timeout);
  }

  render() {
    const { processing, children } = this.props;

    if (!processing) return children;

    return (
      <StyledFormProcessorContainer>
        <StyledFormProcessorChildren>{children}</StyledFormProcessorChildren>
        <StyledFormProcessorWrapper>
          <StyledFormProcessorWrapperInner>
            <StyledFormProcessorText>
              We are updating your account, please be patient this may take a
              few moments.
            </StyledFormProcessorText>
            <Loader delay={0} />
          </StyledFormProcessorWrapperInner>
        </StyledFormProcessorWrapper>
      </StyledFormProcessorContainer>
    );
  }
}

const StyledFormProcessorContainer = styled.div`
  position: relative;
`;

const StyledFormProcessorChildren = styled.div`
  opacity: 0.4;
`;

const StyledFormProcessorWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.85);
`;

const StyledFormProcessorWrapperInner = styled.div``;

const StyledFormProcessorText = styled.p`
  margin: 0;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  max-width: 310px;
  text-align: center;
`;

export default connect(mapStateToProps, mapDispatchToProps)(FormProcessor);
