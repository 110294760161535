import React from "react";

import Tabs from "../../../components/Tabs";
import Wrapper from "../../../components/managers/Wrapper";

import TabsWellnessContainer from "./ManagersOverviewWellnessContainer";
import TabsEngagementContainer from "./ManagersOverviewEngagementContainer";
import TabsIndicatorsContainer from "./ManagersOverviewIndicatorsContainer";

const ManagersOverviewContainer = () => (
  // eslint-disable-next-line react/jsx-filename-extension
  <Wrapper>
    <Tabs
      basePath="managers/overview"
      tabs={[
        {
          path: "",
          tab: "Engagement",
          panel: TabsEngagementContainer,
        },
        {
          tab: "Wellbeing Checker",
          path: "wellbeing-checker",
          panel: TabsWellnessContainer,
        },
        {
          tab: "Indicators",
          path: "indicators",
          panel: TabsIndicatorsContainer,
        },
      ]}
    />
  </Wrapper>
);

export default ManagersOverviewContainer;
