import React from "react";
import styled from "styled-components";

interface ITitle {
  as?: never;
  id?: string;
  color?: string;
  className?: string;
  children: React.ReactNode;
}

export const Title = ({
  id,
  as,
  children,
  color = "",
  className = "",
}: ITitle) => (
  <StyledTitle className={className} color={color} as={as}>
    <StyledTitleInner id={id}>{children}</StyledTitleInner>
  </StyledTitle>
);

const StyledTitle = styled.h3`
  font-weight: bold;
  position: relative;
  text-transform: capitalize;
  color: #${(props) => props.color};

  &:after {
    content: "";
    top: 50%;
    height: 1px;
    width: 100%;
    display: block;
    position: absolute;
    background: currentColor;
  }
`;

const StyledTitleInner = styled.div`
  z-index: 1;
  background: #fff;
  position: relative;
  padding-right: 1rem;
  display: inline-block;
`;
