import { connect } from "react-redux";
import { isEmpty, trim } from "lodash";
import styled from "styled-components";
import React, { Component } from "react";
import { Button, TextArea, FileInput } from "@blueprintjs/core";

import Toast from "../../../utils/Toast";
// @ts-ignore
import example from "./../../../assets/docs/example-import.csv";
import EmailPreview from "../../../components/managers/EmailPreview";

import { sendInvites } from "../../../store/actions/managers";

const CSV_FORMAT: string = "First name, Last name, Email address";

const mapStateToProps = ({ organisation }: any) => ({
  organisation,
});

const mapDispatchToProps = (dispatch: any) => ({
  sendInvites: (id: any, invites: any) => dispatch(sendInvites(id, invites)),
});

interface Props {
  sendInvites: (
    organisationId: string,
    invites: {
      name: string;
      email: string;
      country?: string;
      department?: string;
    }[]
  ) => Promise<void>;
  organisation: {
    organisation_id: string;
  };
}

class ManagersOverviewInvitationsContainer extends Component<Props> {
  state = {
    invitees: "",
    isLoading: false,
    reader: new FileReader(),
  };

  handleFormSubmit = () => {
    const { invitees } = this.state;
    const { sendInvites, organisation } = this.props;

    const invites = invitees
      .split(/\r|\n/)
      .map((invite) => {
        const [
          firstName,
          lastName,
          email,
          department = "",
          country = "",
        ] = invite.split(",").map(trim);

        if (email.includes("@")) {
          return {
            email,
            country,
            department,
            name: `${firstName} ${lastName}`,
          };
        }

        return null;
      })
      .filter(Boolean);

    if (isEmpty(invites)) {
      Toast.danger({
        message: `The invites you provided are not in a valid format (e.g ${CSV_FORMAT})`,
      });
    } else {
      this.setState({ isLoading: true });
      sendInvites(organisation.organisation_id, invites)
        .then(() => {
          this.setState({ invitees: "" });
        })
        .finally(() => this.setState({ isLoading: false }));
    }
  };

  handleFileRead = () => {
    const { reader, invitees } = this.state;

    this.setState({
      reader: new FileReader(),
      invitees: trim(`${invitees}\n${reader.result}\n`),
    });
  };

  handleFileChange = (file: any) => {
    const { reader } = this.state;

    if (file.type.includes("csv")) {
      reader.onloadend = this.handleFileRead;
      reader.readAsText(file);
    } else {
      Toast.danger({
        message: `You can only provide .csv files with the required format (i.e ${CSV_FORMAT})`,
      });
    }
  };

  render() {
    const { invitees, isLoading } = this.state;

    return (
      <StyledContainer>
        <div>
          <p>
            Upload a CSV file or manually type employees you wish to join the{" "}
            {process.env.REACT_APP_COMPANY_NAME} platform below.{" "}
            <StyledLink
              href={example}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here
            </StyledLink>{" "}
            to download an example CSV file.
          </p>
          <FileInput
            fill
            // @ts-ignore
            onInputChange={(e) => this.handleFileChange(e.target.files[0])}
          />
          <StyledDescription>
            <strong>Example:</strong> Joe, Williams, joe@company.com
          </StyledDescription>
          <StyledTextArea
            fill
            large
            rows={15}
            value={invitees}
            id="test_invite-list"
            placeholder={`${CSV_FORMAT}\n`.repeat(3)}
            onChange={(e) => this.setState({ invitees: e.target.value })}
          />
          <Button
            large
            type="submit"
            intent="primary"
            id="test_submit"
            loading={isLoading}
            rightIcon="chevron-right"
            onClick={this.handleFormSubmit}
          >
            Send Invites
          </Button>
        </div>

        <StyledPreview>
          <EmailPreview />
        </StyledPreview>
      </StyledContainer>
    );
  }
}

const StyledContainer = styled.div`
  display: grid;
  grid-gap: 4rem;
  padding-top: 0.7rem;
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    display: block;
  }
`;

const StyledTextArea = styled(TextArea)`
  margin: 0.8rem 0 1.6rem;

  &&&:focus {
    box-shadow: 0 0 0 0 rgba(145, 40, 77, 0), 0 0 0 0 rgba(145, 40, 77, 0),
      inset 0 0 0 1px rgba(16, 22, 26, 0.15),
      inset 0 1px 1px rgba(16, 22, 26, 0.2);
  }
`;

const StyledPreview = styled.div`
  margin-top: 0.2rem;
`;

const StyledDescription = styled.div`
  padding: 2rem 0 0;
`;

const StyledLink = styled.a`
  color: var(--brandColorPrimary);
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagersOverviewInvitationsContainer);
