export const SET_CHANNELS = "SET_CHANNELS";

const channels = (state = [], action) => {
  switch (action.type) {
    case SET_CHANNELS:
      return [...action.payload];
    default:
      return state;
  }
};

export default channels;
