import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import { Analytics } from "./components/Analytics";
import ScrollToTop from "./components/ScrollToTop";
import { EapContainer } from "./containers/EapContainer";
import PrivateRoute from "./components/PrivateRoute";
import RootContainer from "./containers/RootContainer";
import HomeContainer from "./containers/HomeContainer";
import LoginContainer from "./containers/LoginContainer";
import TopicContainer from "./containers/TopicContainer";
import LogoutContainer from "./containers/LogoutContainer";
import SignUpContainer from "./containers/SignUpContainer";
import ChannelContainer from "./containers/ChannelContainer";
import AccountContainer from "./containers/AccountContainer";
import ArticleContainer from "./containers/ArticleContainer";
import AssessmentContainer from "./containers/AssessmentContainer";
import PrivateManagersRoute from "./components/PrivateManagersRoute";
import PageNotFoundContainer from "./containers/PageNotFoundContainer";
import StudentSignUpContainer from "./containers/StudentSignUpContainer";
import PasswordResetContainer from "./containers/PasswordResetContainer";
import ForgotPasswordContainer from "./containers/ForgotPasswordContainer";
import ResendActivationContainer from "./containers/ResendActivationContainer";
import StudentVerifyEmailContainer from "./containers/StudentVerifyEmailContainer";
import AdvertDailyChallengeContainer from "./containers/AdvertDailyChallengeContainer";
import ManagersStudentsContainer from "./containers/managers/ManagersStudentsContainer";
import { StudentOAuthFailureContainer } from "@containers/StudentOAuthFailureContainer";
import { StudentOAuthSuccessContainer } from "./containers/StudentOAuthSuccessContainer";
import ManagersContentContainer from "./containers/managers/content/ManagersContentContainer";
import ManagersInvitationsContainer from "./containers/managers/ManagersInvitationsContainer";
import ManagersOverviewContainer from "./containers/managers/overview/ManagersOverviewContainer";
import ManagersReviewContentContainer from "./containers/managers/content/ManagersReviewContentContainer";
import ManagersReviewContentsContainer from "./containers/managers/content/ManagersReviewContentsContainer";
import ManagersContentCreateEditContainer from "./containers/managers/content/ManagersContentCreateEditContainer";

/**
 * Generic Pages
 */
import TermsContainer from "./containers/TermsContainer";
import SecurityContainer from "./containers/SecurityContainer";
import PrivacyPolicyContainer from "./containers/PrivacyPolicyContainer";
import { MadWorldSignUpContainer } from "@containers/MadWorldSignUpContainer";

export default class Routes extends React.PureComponent {
  render() {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <Analytics />
        <ScrollToTop>
          <RootContainer {...this.props}>
            <Switch>
              <Route
                exact
                path="/login"
                forceRefresh
                component={LoginContainer}
              />
              <Route
                exact
                path="/signin"
                forceRefresh
                component={LoginContainer}
              />
              <Route exact path="/logout" component={LogoutContainer} />
              <Route exact path="/sign-up" component={SignUpContainer} />
              <Route exact path="/eap" component={EapContainer} />
              <Route
                exact
                path="/mad-world-signup"
                component={MadWorldSignUpContainer}
              />
              <Route
                exact
                path="/student-sign-up"
                component={StudentSignUpContainer}
              />
              <Route
                exact
                path="/verify/:confirmation_code"
                component={StudentVerifyEmailContainer}
              />
              <Route
                exact
                path="/invite/:invite_code"
                component={SignUpContainer}
              />
              <Route
                exact
                path="/invite/:invite_code/:email_address"
                component={SignUpContainer}
              />
              <Route
                exact
                path="/forgot-password"
                component={ForgotPasswordContainer}
              />
              <Route
                exact
                path="/reset/:reset_code"
                component={PasswordResetContainer}
              />
              <Route
                exact
                path="/resend-activation"
                component={ResendActivationContainer}
              />

              {/* Generic */}
              <Route exact path="/security" component={SecurityContainer} />
              <Route
                exact
                path="/terms-and-conditions"
                component={TermsContainer}
              />
              <Route
                exact
                path="/privacy-policy"
                component={PrivacyPolicyContainer}
              />

              {/* OAuth routes */}
              <Route
                exact
                path="/oauth/success/:partialId"
                component={StudentOAuthSuccessContainer}
              />
              <Route
                exact
                path="/oauth/failure/:errorId"
                component={StudentOAuthFailureContainer}
              />

              {/* Managers Portal */}
              <PrivateManagersRoute
                path="/managers/overview"
                component={ManagersOverviewContainer}
              />
              <PrivateManagersRoute
                exact
                path="/managers/invitations"
                component={ManagersInvitationsContainer}
              />
              <PrivateManagersRoute
                exact
                path="/managers/students"
                component={ManagersStudentsContainer}
              />
              <PrivateManagersRoute
                exact
                path="/managers/content/review"
                component={ManagersReviewContentsContainer}
              />
              <PrivateManagersRoute
                exact
                path="/managers/content/review/:slug"
                component={ManagersReviewContentContainer}
              />
              <PrivateManagersRoute
                exact
                component={ManagersContentContainer}
                path="/managers/content/:preselect_type"
              />
              <PrivateManagersRoute
                exact
                path="/managers/content/create/:prefill_type"
                component={ManagersContentCreateEditContainer}
              />
              <PrivateManagersRoute
                exact
                path="/managers/content/edit/:slug"
                component={ManagersContentCreateEditContainer}
              />

              {/* Authenticated */}
              <PrivateRoute exact path="/" component={HomeContainer} />
              <PrivateRoute
                exact
                path="/account"
                component={AccountContainer}
              />
              <PrivateRoute
                exact
                path="/my-wellbeing"
                component={AssessmentContainer}
              />
              <PrivateRoute
                exact
                path="/channel/:slug"
                component={(props) => (
                  <ChannelContainer key={props.match.params.slug} {...props} />
                )}
              />
              <PrivateRoute
                exact
                path="/daily-challenge/:slug"
                component={(props) => (
                  <AdvertDailyChallengeContainer
                    {...props}
                    key={props.match.params.slug}
                  />
                )}
              />
              <PrivateRoute
                exact
                path="/topic/:topic"
                component={(props) => (
                  <TopicContainer key={props.match.params.topic} {...props} />
                )}
              />
              <PrivateRoute
                exact
                path="/:type/:slug"
                component={(props) => (
                  <ArticleContainer key={props.match.params.slug} {...props} />
                )}
              />

              <Route component={PageNotFoundContainer} />
            </Switch>
          </RootContainer>
        </ScrollToTop>
      </Router>
    );
  }
}
