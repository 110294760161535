import styled from "styled-components";
import { Link } from "react-router-dom";
import React, { Component } from "react";

import ChannelLinks from "./ChannelLinks";
import ContentBlock from "./ContentBlock";
import TitleSecondary from "./TitleSecondary";
import { TruncatedText } from "./TruncatedText";

import ContentBlockFeatured from "./ContentBlockFeatured";

interface Props {
  articles: any;
  className?: string;
}

export default class ContentBlockPrimary extends Component<Props> {
  state = {};

  render() {
    const { articles, className } = this.props;

    // @ts-ignore
    if (!articles || !articles.length) {
      return null;
    }

    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <StyledContentBlock className={className}>
        <ContentBlockFeatured article={articles[0]} />
        {articles.length > 1 && (
          <StyledContentBlockSecondary className="mb-4">
            <StyledLink to={`/${articles[1].type}/${articles[1].slug}`}>
              <img
                alt=""
                className="mb-4 w-100"
                src={articles[1].image}
                style={{ objectFit: "cover", maxHeight: "165px" }}
              />
              <TitleSecondary as="h3">{articles[1].headline}</TitleSecondary>
              <TruncatedText lines={5}>{articles[1].abstract}</TruncatedText>
            </StyledLink>
            <ChannelLinks channels={articles[1].channels} />
          </StyledContentBlockSecondary>
        )}
        <StyledContentBlockSidebar>
          {articles.slice(2, 7).map((article: any) => (
            <StyledContentBlockSidebarItem key={article.article_id}>
              <StyledLink to={`/${article.type}/${article.slug}`}>
                <TitleSecondary as="h3">{article.headline}</TitleSecondary>
              </StyledLink>
              <ChannelLinks channels={article.channels} />
            </StyledContentBlockSidebarItem>
          ))}
        </StyledContentBlockSidebar>
        <ContentBlock
          columns={4}
          articles={articles.slice(9, 13)}
          style={{ gridArea: "tertiary" }}
        />
      </StyledContentBlock>
    );
  }
}

const StyledContentBlock = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-auto-rows: minmax(100px, auto);
  grid-template-columns: repeat(5, 1fr);
  grid-template-areas:
    "primary  primary  primary  secondary sidebar"
    "tertiary tertiary tertiary tertiary  sidebar";

  @media (max-width: ${({ theme }) => theme.breakpointLarge}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "primary  primary  primary sidebar"
      "secondary  secondary secondary sidebar"
      "tertiary tertiary tertiary tertiary";
  }

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    display: block;
    img {
      width: 100%;
    }
  }
`;

const StyledContentBlockSecondary = styled.div`
  grid-area: secondary;
`;

const StyledContentBlockSidebar = styled.div`
  grid-area: sidebar;
  padding-left: 1.5rem;

  @media (max-width: ${({ theme }) => theme.breakpointLarge}) {
    padding-left: 0;
  }
`;

const StyledContentBlockSidebarItem = styled.div`
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const StyledLink = styled(Link)`
  &:hover {
    h2,
    h3 {
      text-decoration: underline;
    }
  }
`;
