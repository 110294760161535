import styled from "styled-components";
import React, { Component } from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "../components/Container";
import CookieNotice from "../components/CookieNotice";
import ChannelsLoader from "../components/ChannelsLoader";

interface Props {
  user: any;
  children: any;
  channels: any;
}

/**
 * The root container wraps all other containers
 * and can be used to have some global page structure (eg a Header/Footer).
 */
export default class RootContainer extends Component<Props> {
  state = {};

  render() {
    const { channels, children, user } = this.props;

    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <StyledRootContainer>
        <CookieNotice />
        {
          // @todo fix this
          // @ts-ignore
          <Header channels={channels} user={user} />
        }
        <StyledMain>
          <ChannelsLoader>
            <Container>{children}</Container>
          </ChannelsLoader>
        </StyledMain>
        {
          // @todo fix this
          // @ts-ignore
          <Footer
            // @ts-ignore
            channels={channels}
          />
        }
      </StyledRootContainer>
    );
  }
}

const StyledRootContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const StyledMain = styled.main``;
