import React from "react";
import styled from "styled-components";

interface Props {
  gap?: string;
  rows?: string;
  columns?: string;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

// prettier-ignore
export const Grid = styled.div<Props>(({ columns, gap, rows, theme, children }) =>`
  display: grid;
  grid-gap: ${gap ? gap : "1.5rem 2rem"};
  
  /**
   * Prevents overflowing text from 
   * increasing the size of the columns.  
   */
  > * {
    min-width: 0;
  }
  grid-template-rows: ${rows || "initial"};
  grid-template-columns: ${!columns ? `repeat(${Array.isArray(children) ? children.length : 1}, 1fr);` : columns || "1fr"};
  @media (max-width: ${theme.breakpointMedium}) {
    grid-template-columns: 1fr;
  }
`);
