import { has, flatMap } from "lodash";

import { ChannelList } from "@enums/managers/ChannelList";

export default class Content {
  /**
   * Normalise the data into a more
   * useable format on multiple content
   *
   * @param {any[]} articles
   * @return {ManagersContentInterface[]}
   */
  static normalizeArticles = (articles: any[]) =>
    !articles
      ? []
      : flatMap(articles).map((article: any) => {
          return Content.normalizeArticle(article);
        });

  /**
   * Normalise an article
   *
   * @param {unknown} article
   * @return {ManagersContentInterface}
   */
  static normalizeArticle = (article: any) => {
    if (has(article, "channelList")) {
      article.channelList = Object.keys(article.channelList) as ChannelList[];
    }

    return article;
  };
}
