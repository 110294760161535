import { useSelector } from "react-redux";

import { UserInterface } from "@interfaces/UserInterface";

interface Props {
  user: UserInterface;
}

export const useUser = () => {
  const { user }: Props = useSelector(({ user }: Props) => ({
    user,
  }));

  return { user };
};
