import { isEmpty } from "lodash";
import api from "../../api";
import Toast from "../../utils/Toast";

import { SET_ORGANISATIONS } from "../reducers/organisations";
import { SET_ORGANISATION } from "../reducers/organisation";

/**
 * Set current organisation
 */
export const setOrganisation = (selected, organisation) => (dispatch) => {
  dispatch({
    type: SET_ORGANISATION,
    payload: {
      selected: parseInt(selected, 10),
      ...organisation,
    },
  });
};

/**
 * Get organisations
 */
export const getOrganisations = () => (dispatch) =>
  api.post("users/me").then((response) => {
    const { organisations } = response.data.payload;

    if (!isEmpty(organisations[0])) {
      dispatch({
        type: SET_ORGANISATIONS,
        payload: [...organisations],
      });

      dispatch({
        type: SET_ORGANISATION,
        payload: {
          selected: 0,
          ...organisations[0],
        },
      });
    }
  });

/**
 * Send invites
 */
export const sendInvites = (id, invites) => () =>
  api
    .put(`management/${id}/employees`, { employees: invites })
    .then(() => Toast.success({ message: "Your invites have been sent!" }))
    .catch((error) => Toast.danger({ message: error.message }));

/**
 * Engagement
 */
export const getEngagement = (organisation_id) => () =>
  api
    .get(`management/${organisation_id}/channel_engagement`)
    .then((response) => response.data.payload.engagement);

/**
 * Wellness
 */
export const getWellness = (organisation_id) => () =>
  api
    .get(`management/${organisation_id}/wellness`)
    .then((response) => response.data.payload.wellness);

/**
 * Indicators
 */
export const getIndicators = (organisation_id) => () =>
  api.get(`management/${organisation_id}/indicators`).then((response) => {
    const { indicators } = response.data.payload;

    /** Change date format */
    indicators.graph.map((row) => {
      row.stress = Math.round(row.stress);
      row.anxiety = Math.round(row.anxiety);
      row.depression = Math.round(row.depression);
      return row;
    });

    return indicators;
  });
