import React from "react";
import styled from "styled-components";

export const NoArticlesResultsMessage = () => (
  <StyledContainer>
    We don&apos;t appear to have any content for this section yet, please come
    back at another time.
  </StyledContainer>
);

const StyledContainer = styled.div`
  font-weight: 600;
  text-align: left;
  font-size: 1.1rem;
  line-height: 1.8rem;
  padding: 0.5rem 0 1.5rem;
`;
