import ReactMarkdown from "react-markdown";
import React, { useEffect, useState } from "react";

import api from "@api";
import styled from "styled-components";

const SponsoredLegal = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    api
      .get("pages/partners")
      .then(({ data }) => setContent(data.payload.markdown));
  });

  return !content ? (
    <></>
  ) : (
    <StyledContainer>
      {<ReactMarkdown source={content.replace(/<br>/g, "")} />}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid var(--borderColor);
`;

export default SponsoredLegal;
