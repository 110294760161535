import styled from "styled-components";
import { Image } from "cloudinary-react";
import { HTMLTable } from "@blueprintjs/core";

export const Table = styled(HTMLTable)`
  &&& {
    width: 100%;
    line-height: 1.4;
    margin-top: 1.5rem;

    thead {
      th {
        color: white;
        font-weight: 600;
        padding: 12px 12px 10px;
        background-color: var(--brandColorPrimary);

        &:first-child {
          border-top-left-radius: 3px;
        }

        &:last-child {
          border-top-right-radius: 3px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        width: 72px;
        white-space: nowrap;
        vertical-align: middle;
        padding: 12px 12px 10px;
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 3px;
          }

          &:last-child {
            border-bottom-right-radius: 3px;
          }
        }
      }
    }
  }
`;

export const TableCellStretch = styled.td`
  &&& {
    width: auto;
    white-space: normal;
  }
`;

export const TableImage = styled(Image)`
  --size: 48px;
  display: block;
  object-fit: cover;
  width: var(--size);
  border-radius: 2px;
  height: var(--size);
  background-color: var(--managerPlaceholder);
`;

export const TableAbstract = styled.div`
  opacity: 0.7;
  margin: 0.3rem 0 0;
`;

export const TableNoRows = styled.td`
  &&& {
    height: 74px;
    text-align: center;
    vertical-align: middle;
  }
`;

export const TableDate = styled.td`
  &&& {
    width: 115px;
    text-align: center;
  }
`;
