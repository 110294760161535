import { isEmpty } from "lodash";

import api from "../../api";
import Toast from "../../utils/Toast";
import { getOrganisations } from "./managers";
import LoginCounter from "../../utils/LoginCounter";
import { SET_COUNTRIES } from "../reducers/countries";
import { SET_UNIVERSITIES } from "../reducers/universities";
import { SET_USER } from "../reducers/user";

/**
 * Get student universities
 */
export const getUniversities = () => (dispatch) =>
  api
    .get("/students/universities")
    .then((response) => {
      const { universities } = response.data.payload;
      if (!isEmpty(universities)) {
        dispatch({
          type: SET_UNIVERSITIES,
          payload: [...universities],
        });
      }
    })
    .catch((error) =>
      Toast.danger({ message: "Could not fetch universities" })
    );

/**
 * Get student countries
 */
export const getCountries = () => (dispatch) =>
  api
    .get("/students/countries")
    .then((response) => {
      const { countries } = response.data.payload;
      if (!isEmpty(countries)) {
        dispatch({
          type: SET_COUNTRIES,
          payload: [...Object.values(countries)],
        });
      }
    })
    .catch((error) => Toast.danger({ message: "Could not fetch countries" }));

/**
 * Logs student in using one-time token
 */
export const loginStudent = (token) => (dispatch) => {
  return api
    .get("/students/token", { params: { token } })
    .then((response) => {
      const payload = response.data.payload.user;
      payload.isAuthenticated = Boolean(payload.user_id);

      // Append successful login count
      const counter = new LoginCounter();
      counter.add();

      dispatch(getOrganisations());
      dispatch({ type: SET_USER, payload });

      return true;
    })
    .catch((error) =>
      Toast.danger({ message: "Could not log you in successfully." })
    );
};
