import { merge } from "lodash";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Button } from "@blueprintjs/core";
import React, { PureComponent } from "react";

import PasswordStrength from "../utils/PasswordStrength";
import InputUnderline from "../components/InputUnderline";
import ContainerCentered from "../components/ContainerCentered";

import { passwordReset } from "../store/actions/authentication";

const mapDispatchToProps = (dispatch: any) => ({
  dispatchPasswordReset: (data: any) => dispatch(passwordReset(data)),
});

class PasswordResetContainer extends PureComponent<any> {
  state = {
    resetCode: "",
    loading: false,
    newPassword: "",
    currentEmail: "",
    resetCodeIntent: "",
    newPasswordIntent: "",
    currentEmailIntent: "",
    resetCodeHelperText: "",
    newPasswordHelperText: "",
    currentEmailHelperText: "",
  };

  componentDidMount() {
    // @ts-ignore
    const { match } = this.props;

    // Invite code passed from emails
    const resetCode = match.params.reset_code;
    if (resetCode) this.setState({ resetCode });
  }

  attemptPasswordReset = async () => {
    // @ts-ignore
    const { dispatchPasswordReset } = this.props;
    const { currentEmail, newPassword, resetCode } = this.state;

    await dispatchPasswordReset({
      email: currentEmail,
      reset_code: resetCode,
      password: newPassword,
    });

    this.setState({ loading: false });
  };

  handleInputChange = (name: string, value: string) => {
    this.setState({ [name]: value });
  };

  handleInputFocus = (name: string) => {
    this.setState({ [`${name}Intent`]: "", [`${name}HelperText`]: "" });
  };

  handleFormSubmit(e: Object) {
    // @ts-ignore
    e.preventDefault();

    const { currentEmail, newPassword, resetCode } = this.state;

    const currentEmailState = !currentEmail && {
      currentEmailIntent: "danger",
      currentEmailHelperText: "You must enter your email address",
    };

    const newPasswordState = !newPassword && {
      newPasswordIntent: "danger",
      newPasswordHelperText: "You must provide your new password",
    };

    const newPasswordStrengthNumberState = !PasswordStrength.hasNumber(
      newPassword
    ) && {
      newPasswordIntent: "danger",
      newPasswordHelperText: "Your new password must contain a number",
    };

    const resetCodeState = !resetCode && {
      resetCodeIntent: "danger",
      resetCodeHelperText: "You must provide your reset code",
    };

    const state = merge(
      currentEmailState,
      newPasswordStrengthNumberState,
      newPasswordState,
      resetCodeState
    );

    if (Object.keys(state).length) {
      this.setState(state);
    } else {
      this.setState({ loading: true }, this.attemptPasswordReset);
    }
  }

  render() {
    const {
      loading,
      resetCode,
      resetCodeIntent,
      newPasswordIntent,
      currentEmailIntent,
      resetCodeHelperText,
      newPasswordHelperText,
      currentEmailHelperText,
    } = this.state;

    return (
      <ContainerCentered>
        <Helmet>
          <title>Password Reset - {process.env.REACT_APP_COMPANY_NAME}</title>
        </Helmet>
        <form onSubmit={(e) => this.handleFormSubmit(e)}>
          <h2>Please reset your password</h2>

          <InputUnderline
            type="email"
            name="currentEmail"
            intent={currentEmailIntent}
            label="Your email address"
            onFocus={this.handleInputFocus}
            onChange={this.handleInputChange}
            helperText={currentEmailHelperText}
          />

          <InputUnderline
            type="password"
            name="newPassword"
            label="New password"
            intent={newPasswordIntent}
            onFocus={this.handleInputFocus}
            onChange={this.handleInputChange}
            helperText={newPasswordHelperText}
          />

          <InputUnderline
            name="resetCode"
            label="Reset code"
            defaultValue={resetCode}
            intent={resetCodeIntent}
            onFocus={this.handleInputFocus}
            helperText={resetCodeHelperText}
            onChange={this.handleInputChange}
          />

          <Button
            loading={loading}
            intent="primary"
            type="submit"
            large
            rightIcon="chevron-right"
          >
            Reset my password
          </Button>
        </form>
      </ContainerCentered>
    );
  }
}

export default connect(null, mapDispatchToProps)(PasswordResetContainer);
