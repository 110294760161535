import { has } from "lodash";
import styled from "styled-components";
import { Redirect } from "react-router";
import React, { PureComponent } from "react";
import { Dialog, Button } from "@blueprintjs/core";

import LoginCounter from "../utils/LoginCounter";

export default class LoginCounterDialog extends PureComponent {
  state = {
    title: "",
    open: false,
    message: "",
    redirectToWellbeing: false,
  };

  componentDidMount() {
    const counter = new LoginCounter();

    let state = {};
    if (counter.count === 1) {
      // First time login
      state = {
        open: true,
        title: `Welcome to ${process.env.REACT_APP_COMPANY_NAME}`,
        message: "Great, now that you're here, let's personalise your feed!",
      };
    } else if (counter.count % 10 === 0) {
      // Show every 10th login
      state = {
        open: true,
        title: "Welcome back",
        message:
          "Welcome back! Remember to keep personalising your feed to get the most relevant content for you.",
      };
    }

    if (has(state, "open")) this.setState(state, () => counter.add());
  }

  handleOnClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { open, title, message, redirectToWellbeing } = this.state;

    if (redirectToWellbeing) return <Redirect to="/my-wellbeing" />;

    return (
      <Dialog
        isOpen={open}
        title={title}
        canEscapeKeyClose
        canOutsideClickClose
        onClose={() => this.handleOnClose()}
      >
        <StyledDialogInner>
          {message}
          <StyledButtonArea>
            <Button
              large
              type="submit"
              intent="primary"
              rightIcon="chevron-right"
              loading={redirectToWellbeing}
              onClick={() => this.setState({ redirectToWellbeing: true })}
            >
              My Wellbeing Checker
            </Button>
          </StyledButtonArea>
        </StyledDialogInner>
      </Dialog>
    );
  }
}

const StyledDialogInner = styled.form`
  line-height: 1.3rem;
  text-align: center;
  padding: 2rem 1.5rem;
`;

const StyledButtonArea = styled.div`
  display: block;
  padding: 2.8rem 0 0;
  text-align: center;
`;
