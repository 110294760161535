export const CHANNEL_SLUGS: Record<string, string> = {
  mental_health: "MENTHEAL-0000-0000-0000-000000000000",
  entertainment: "ENTERTAI-0000-0000-0000-000000000000",
  food: "FOOD0000-0000-0000-0000-000000000000",
  education: "EDUCATIO-0000-0000-0000-000000000000",
  sport: "SPORT000-0000-0000-0000-000000000000",
  family_and_relationships: "FAMIRELA-0000-0000-0000-000000000000",
  money: "MONEY000-0000-0000-0000-000000000000",
  lgbtq: "LGBTQ000-0000-0000-0000-000000000000",
  neurodiversity: "NEURODIV-0000-0000-0000-000000000000",
  opinion: "OPINION0-0000-0000-0000-000000000000",
  physical_health: "PHYSHEAL-0000-0000-0000-000000000000",
  sleep: "SLEEP000-0000-0000-0000-000000000000",
  travel: "TRAVEL00-0000-0000-0000-000000000000",
  work: "WORK0000-0000-0000-0000-000000000000",
  diversity: "DIVERSIT-0000-0000-0000-000000000000",
  science_and_technology: "SCIETECH-0000-0000-0000-000000000000",
};
