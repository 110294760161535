import { createGlobalStyle } from "styled-components";

const AppStyles = createGlobalStyle`

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  min-width: 300px;
  min-height: 100%;
  background: #fff;
  color: var(--bodyText);
}

body {
  overflow-y: scroll;
} 

a {
  color: var(--bodyText);
}

a,
a:hover {
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}

blockquote {
  margin: 0;
  padding: .5rem 1rem;
  border-left: 3px solid rgba(0,0,0,0.1);
}

h1,
h2,
h3,
h4,
h5 {
  padding: 0;
}

h1 {
  font-size: 2rem;
  line-height: 2.4rem;
}

h2 {
  font-size: 1.7rem;
  line-height: 2.2rem;
}

button {
  cursor: pointer;
}

input,
button,
select,
textarea {
  background: #fff;
  box-shadow: none;
  border-radius: 3px;
  appearance: none;
  font-size: inherit;
  padding: 0.5rem 1rem;
  font-family: inherit;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

input,
textarea {
  width: 100%;
}

input:focus,
button:focus,
select:focus,
textarea:focus,
.bp3-input:focus,
.bp3-input.bp3-active {
  outline: none;
  box-shadow: none;
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1.2rem;
}

img {
  display: block;
  max-width: 100%;
}

/**
 * Utility classes
 */
.w-100 {
  width: 100%;
}

.d-block{
  display: block;
}

.text-center {
  text-align: center;
}

.mt-a {
  margin-top: auto;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}

.mr-a {
  margin-right: auto;
}
.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-8 {
  margin-right: 2rem;
}

.mb-a {
  margin-bottom: auto;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-7 {
  margin-bottom: 1.75rem;
}
.mb-8 {
  margin-bottom: 2rem;
}

.ml-a {
  margin-left: auto;
}
.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}

.my-a {
  margin-top: auto;
  margin-bottom: auto;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}
.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mx-a {
  margin-left: auto;
  margin-right: auto;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}
.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}


.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pt-4 {
  padding-top: 1rem;
}

.pr-0 {
  padding-right: 0;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-3 {
  padding-right: 0.75rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pr-8 {
  padding-right: 2rem;
}

.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pb-4 {
  padding-bottom: 1rem;
}

.pl-0 {
  padding-left: 0;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pl-4 {
  padding-left: 1rem;
}

.py-a {
  padding-top: auto;
  margin-bottom: auto;
}
.py-0 {
  padding-top: 0;
  margin-bottom: 0;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.px-a {
  padding-left: auto;
  padding-right: auto;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

/**
 *Sweet Alert
 */
.swal2-input{
  margin-bottom: 0;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel{
  min-width: 8rem;
}

.swal2-styled.swal2-confirm{
  background: var(--brandColorPrimary);
}

.swal2-styled.swal2-cancel{
  color: #182026;
  background: #fff;
  border: 1px solid var(--borderColor);
}

.swal2-icon.swal2-info{
  color: var(--brandColorPrimary);
  border-color:  var(--brandColorPrimary);
}
`;

export default AppStyles;
