/* eslint-disable implicit-arrow-linebreak */
import posed from "react-pose";
import { connect } from "react-redux";
import styled from "styled-components";
import React, { Component } from "react";
import { has, last, isEmpty } from "lodash";

import { DEMO_EMAIL } from "@constants/demo";
import Loader from "@components/managers/Loader";
import { DUMMY_WELLNESS } from "@constants/dummy";
import HighlightBox from "@components/managers/HighlightBox";
import PoseAnimated from "@components/managers/PoseAnimated";
import NoDataMessage from "@components/managers/NoDataMessage";
import PrintableWrapper from "@components/managers/PrintableWrapper";
import { WellnessResultsInterface } from "@interfaces/WellnessResultsInterface";

import { getWellness } from "@store/actions/managers";

const mapStateToProps = ({ organisation, channels, user }: any) => ({
  organisation,
  channels,
  user,
});

const mapDispatchToProps = (dispatch: any) => ({
  getWellness: (organisation_id: any) => dispatch(getWellness(organisation_id)),
});

class ManagersOverviewWellnessContainer extends Component<any> {
  state = {
    pose: "closed",
    isLoading: true,
    wellness: {
      results: [],
      count: 0,
      userCount: 0,
    },
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(nextProps: any) {
    // @ts-ignore
    const { organisation } = this.props;

    if (organisation.selected !== nextProps.organisation.selected) {
      // @ts-ignore
      this.getData();
    }
  }

  getData() {
    // @ts-ignore
    const { organisation, getWellness } = this.props;

    this.setState({ isLoading: true, pose: "closed" });
    getWellness(organisation.organisation_id).then((data: any) => {
      // When logged in as a demo user, show hard coded stats
      const wellness: WellnessResultsInterface =
        this.props.user.work_email === DEMO_EMAIL ? DUMMY_WELLNESS : data;

      this.setState(
        {
          wellness,
          isLoading: false,
        },
        () => setTimeout(() => this.setState({ pose: "open" }), 1)
      );
    });
  }

  render() {
    // @ts-ignore
    const { organisation } = this.props;
    const { pose, wellness, isLoading } = this.state;

    return isLoading && has(wellness, "results") ? (
      <Loader />
    ) : (
      <PrintableWrapper report="Indicators" name={organisation.name}>
        <AnimatedList pose={pose}>
          {wellness.results.length <= 1 && (
            <PoseAnimated>
              <NoDataMessage />
            </PoseAnimated>
          )}

          <StyledWellnessContainer>
            {wellness.results.slice(0, -1).map((question, key) => (
              <StyledWellnessItem key={key}>
                <PoseAnimated
                  // @ts-ignore
                  key={question.question_number}
                >
                  <HighlightBox
                    // @ts-ignore
                    value={question.answer}
                    // The backend tries to escape the quotes but we don't want that.
                    // @ts-ignore
                    question={question.text.replace("\\’", "’")}
                    // @ts-ignore
                    title={`Q${question.question_number}.`}
                  />
                </PoseAnimated>
              </StyledWellnessItem>
            ))}
          </StyledWellnessContainer>
          <StyledWellnessLastContainer>
            <PoseAnimated>
              {has(last(wellness.results), "answers") &&
                !isEmpty(last(wellness.results).answers) && (
                  <HighlightBox
                    // @ts-ignore
                    title={`Q${last(wellness.results).question_number}.`}
                    // @ts-ignore
                    answers={last(wellness.results).answers}
                    // @ts-ignore
                    percentageTotal={last(wellness.results).total}
                  />
                )}
            </PoseAnimated>
          </StyledWellnessLastContainer>
          <StyledCenteredArea className="mt-1">
            {wellness.count} assessments submitted by {wellness.userCount} user
            {wellness.userCount > 1 && "s"}
          </StyledCenteredArea>
        </AnimatedList>
      </PrintableWrapper>
    );
  }
}

const AnimatedList = posed.div({
  open: {
    delayChildren: 200,
    staggerChildren: 50,
  },
  closed: { delay: 300 },
});

const StyledWellnessContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const StyledWellnessItem = styled.div`
  width: 33%;
  flex-grow: 1;
  padding: 0.75rem;

  & > div {
    height: 100%;
  }
`;

const StyledWellnessLastContainer = styled.div`
  padding: 0.75rem;
`;

const StyledCenteredArea = styled.p`
  opacity: 0.5;
  text-align: center;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagersOverviewWellnessContainer);
