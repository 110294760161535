import { connect } from "react-redux";
import React, { Component } from "react";

import NetworkErrorMessage from "./NetworkErrorMessage";

import { getChannels, setChannels } from "../store/actions/channels";

const mapStateToProps = ({ channels }: any) => ({
  channels,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchGetChannels: () => dispatch(getChannels()),
  dispatchSetChannels: (channels: any) => dispatch(setChannels(channels)),
});

interface Props {
  children: any;
  channels: any;
  dispatchSetChannels: (channels: object) => any;
  dispatchGetChannels: (channels: object) => any;
}

class ChannelsLoader extends Component<Props> {
  state = {
    timeout: null,
  };

  componentDidMount() {
    const { channels } = this.props;

    this.getChannels();

    const timeout = setTimeout(() => {
      // Potential server outage, retry for channels
      if (!channels.length) this.getChannels();
    }, 2500);

    this.setState({ timeout });
  }

  componentDidUpdate(prevProps: any) {
    const { channels } = this.props;

    if (channels !== prevProps.channels) {
      /** Channels fetched from API */
      if (!channels.length) {
        this.getChannels();
      }
    }
  }

  componentWillUnmount() {
    const { timeout } = this.state;

    // @ts-ignore
    clearTimeout(timeout);
  }

  getChannels = async () => {
    const { dispatchGetChannels, dispatchSetChannels } = this.props;

    // @ts-ignore
    await dispatchGetChannels().then((channels: any) => {
      if (typeof channels === "object" && channels.length > 0) {
        dispatchSetChannels(channels);
      }
    });
  };

  render() {
    const { children, channels } = this.props;

    return channels.length > 0 ? children : <NetworkErrorMessage />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsLoader);
