import moment from "moment";
import styled from "styled-components";
import React, { useState } from "react";
import { Image } from "cloudinary-react";
import { useHistory } from "react-router-dom";
import { useAsyncEffect } from "use-async-effect";
import { flatten, isEmpty, startCase } from "lodash";
import { Button, ButtonGroup, Intent } from "@blueprintjs/core";

import Toast from "@utils/Toast";
import { MutedText } from "@components/MutedText";
import { DataTable } from "@components/DataTable";
import Wrapper from "@components/managers/Wrapper";
import ContentType from "@enums/managers/ContentType";
import ContentStatus from "@enums/managers/ContentStatus";
import YouTubeThumbnail from "@components/YouTubeThumbnail";
import { getContent, putContent } from "@store/actions/managers/content";
import ManagersContentInterface from "@interfaces/managers/ManagersContentInterface";

import {
  promptForRejectionReason,
  castReviewFormDataToCorrectTypes,
} from "@utils/managers/Review";

export default function ManagersReviewContainer(): JSX.Element {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [articles, setArticles] = useState<ManagersContentInterface[]>([]);

  useAsyncEffect(async () => {
    await reloadArticles();
    setIsLoading(false);
  }, []);

  async function reloadArticles() {
    const data = await Promise.all([
      getContent({
        isReview: true,
        type: ContentType.article,
      }),
    ]);

    if (data) {
      setArticles(flatten(data));
    }
  }

  async function handleOnSubmit({
    article,
    approved,
  }: {
    article: $TSFixMe;
    approved: boolean;
  }): Promise<void> {
    let review_rejection_reason = "";

    if (!approved) {
      review_rejection_reason = await promptForRejectionReason();

      if (!review_rejection_reason) return;
    }

    const formData = {
      ...castReviewFormDataToCorrectTypes(article),
      review_accepted: approved,
      podcast_link: article.podcast_file_url,
      homepage_link: article.podcast_homepage,
      youtube_url: article.video_link,
      review_rejection_reason: !isEmpty(review_rejection_reason)
        ? review_rejection_reason
        : undefined,
      status: review_rejection_reason
        ? ContentStatus.draft
        : ContentStatus.live,
    };

    await putContent({
      data: formData,
      type: article.type,
      contentId: article.article_id,
    });

    await reloadArticles();

    review_rejection_reason
      ? Toast.info({ message: "Post has been rejected" })
      : Toast.success({ message: "Post has been accepted" });
  }

  /**
   * @return {unknown}
   */
  function formatData() {
    return articles
      .filter((article) => !article.review_accepted)
      .map((article) => {
        return {
          id: article.article_id,
          slug: article.slug,
          image: (
            <StyledImage id={"test_review-content"}>
              {
                /**
                 * Video thumbanils are generated
                 * on the app and not stored
                 */
                article.type === ContentType.video ? (
                  <YouTubeThumbnail id={article.video_link} />
                ) : (
                  <Image
                    publicId={article.image_id}
                    cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
                  />
                )
              }
            </StyledImage>
          ),
          post: (
            <div>
              <div className="mb-2">
                <strong>
                  {startCase(article.type)} - {article.headline}
                </strong>
              </div>
              <div className="mb-2">
                {moment(article.created_at).format("Do MMM YYYY")} -{" "}
                {article.abstract}
              </div>
              <div>
                <MutedText className="mb-1">
                  Channels:{" "}
                  {article.channelList.length ? (
                    article.channelList.join(", ")
                  ) : (
                    <strong>No channels selected</strong>
                  )}
                </MutedText>
                <MutedText className="mb-1">
                  Topics: {article.topicList.join(", ")}
                </MutedText>
                <MutedText>
                  Sentiment: {article.sentiment} / Characters:{" "}
                  {article.character_count} / Stress:{" "}
                  {article.stress_target_lower}-{article.stress_target_upper} /
                  Anxiety: {article.anxiety_target_lower}-
                  {article.anxiety_target_upper} / Depression:{" "}
                  {article.depression_target_lower}-
                  {article.depression_target_upper}
                </MutedText>
              </div>
            </div>
          ),
          actions: (
            <ButtonGroup>
              <Button
                intent={Intent.PRIMARY}
                disabled={Boolean(!article.channelList.length)}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.stopPropagation();

                  if (article.channelList.length) {
                    handleOnSubmit({ article, approved: true });
                  }
                }}
              >
                Approve
              </Button>
              <Button
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.stopPropagation();
                  handleOnSubmit({ article, approved: false });
                }}
              >
                Reject
              </Button>
            </ButtonGroup>
          ),
        };
      });
  }

  return (
    <Wrapper>
      <DataTable
        className="pt-4"
        onRowClick={(data) =>
          history.push(`/managers/content/review/${data.slug}`)
        }
        columns={[
          { key: "image", width: "170px" },
          { key: "post" },
          { key: "actions", width: "160px" },
        ]}
        data={formatData()}
        isLoading={isLoading}
      />
    </Wrapper>
  );
}

const StyledImage = styled.div`
  img {
    --size: 8.8rem;
    object-fit: cover;
    border-radius: 3px;
    width: var(--size);
    height: var(--size);
  }
`;
