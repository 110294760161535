import styled from "styled-components";
import React, { useState } from "react";
import { Image } from "cloudinary-react";
import { useDropzone } from "react-dropzone";
import { Spinner, Intent } from "@blueprintjs/core";

import { Grid } from "@components/Grid";
import { upload } from "@store/actions/managers/cloudinary";

interface Props {
  name?: string;
  required?: boolean;
  className?: string;
  defaultValue?: string;
  showPreview?: boolean;
  selectableImages?: string[];
  cloudinaryImageID?: string | null;
  onChange?: (public_id: string) => void;
}

const ImageUploader = ({
  name,
  onChange,
  required,
  className,
  defaultValue,
  selectableImages,
  cloudinaryImageID,
  showPreview = true,
}: Props) => {
  const [preview, setPreview] = useState<string>("");
  const [imageId, setImageId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  /**
   * On drop or select
   *
   * @param {File[] | string} file multiple array but in this case we only need single
   * @return {void}
   */
  async function onDrop(files: File[] | string) {
    const [image] = typeof files === "string" ? [files] : files;

    if (image) {
      setIsLoading(true);

      /** Perform cloudinary upload */
      const imageResponse = await upload(image);

      setPreview(imageResponse.url);
      onChange && imageResponse.public_id && onChange(imageResponse.public_id);

      setImageId(imageResponse.public_id || "");

      setIsLoading(false);
    }
  }

  if (!showPreview) {
    return (
      <div className={className}>
        <StyledDropZone {...getRootProps({ multiple: false })}>
          Drop files here to upload, or select files
        </StyledDropZone>
        <input name="image" readOnly {...getInputProps()} />
      </div>
    );
  }

  return (
    <>
      <StyledContainer
        {...getRootProps({ multiple: false })}
        className={className}
      >
        {isLoading ? (
          <Spinner intent={Intent.PRIMARY} size={38} />
        ) : (
          <>
            {cloudinaryImageID && !preview && (
              <StyledCloudinary
                cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
                publicId={cloudinaryImageID}
              />
            )}
            {preview && (
              <img
                src={preview}
                alt="Upload preview"
                style={{ objectFit: "contain" }}
              />
            )}
          </>
        )}
        <StyledText>Upload</StyledText>
        <input name="image" {...getInputProps()} />
        <input
          name={name}
          type="text"
          value={imageId || defaultValue}
          required={required}
          style={{
            width: 0,
            height: 0,
            margin: 0,
            padding: 0,
            opacity: 0,
            pointerEvents: "none",
          }}
        />
      </StyledContainer>
      <Grid columns="1fr 1fr 1fr">
        {selectableImages?.map((image) => (
          <StyledImage
            key={image}
            src={image}
            onClick={async () => await onDrop(image)}
          />
        ))}
      </Grid>
    </>
  );
};

const StyledText = styled.div`
  z-index: 1;
  color: white;
  display: block;
  line-height: 1;
  --margin: 1rem;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  right: var(--margin);
  bottom: var(--margin);
  padding: 7px 10px 6px;
  background-color: var(--brandColorPrimary);
`;

const StyledContainer = styled.div`
  display: flex;
  height: 198px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border-radius: 3px;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: var(--managerPlaceholder);

  &:hover {
    ${StyledText} {
      background-color: #a93a60;
    }
  }
`;

const StyledCloudinary = styled(Image)`
  object-fit: contain;
`;

const StyledDropZone = styled.div`
  height: 10rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  transition: 0.3s ease;
  justify-content: center;
  color: rgba(0, 0, 0, 0.5);
  border: rgba(0, 0, 0, 0.1) 2px dashed;
  background: var(--managerPlaceholder);

  &:hover {
    opacity: 0.8;
  }
`;

const StyledImage = styled.img`
  --size: 100px;
  cursor: pointer;
  margin-top: 1rem;
  object-fit: cover;
  width: var(--size);
  height: var(--size);
  transition: 0.3s ease;
  &:hover {
    opacity: 0.8;
  }
`;

export default ImageUploader;
