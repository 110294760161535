export const BREAKPOINT_TINY: number = 380;
export const BREAKPOINT_SMALL: number = 700;
export const BREAKPOINT_MEDIUM: number = 930;
export const BREAKPOINT_LARGE: number = 1100;
export const BREAKPOINT_EXTRA_LARGE: number = 1300;
export const BREAKPOINT_EXTRA_SMALL: number = 500;

/**
 * A collection of all breakpoints, used for the styled-components `ThemeProvider`
 */
export const BREAKPOINTS = Object.freeze({
  breakpointTiny: BREAKPOINT_TINY,
  breakpointSmall: BREAKPOINT_SMALL,
  breakpointLarge: BREAKPOINT_LARGE,
  breakpointMedium: BREAKPOINT_MEDIUM,
  breakpointExtraLarge: BREAKPOINT_EXTRA_LARGE,
  breakpointExtraSmall: BREAKPOINT_EXTRA_SMALL,
});
