import styled from "styled-components";
import React, { Component } from "react";
import { camelCase, isEmpty, merge } from "lodash";

import Content from "../utils/Content";
import ContentBlock from "./ContentBlock";

interface Props {
  theme?: any;
  type: string;
  channel: string;
  titleClassName?: string;
  featuredPostColumns?: number;
}

export default class ContentBlockPaginated extends Component<Props> {
  static defaultProps = {
    titleClassName: "",
    featuredPostColumns: 0,
  };

  state = {
    page: 1,
    articles: [],
    canShowMore: false,
  };

  componentDidMount() {
    this.getContent();
  }

  getContent = (shallow: boolean = false) => {
    const { page, articles: currentArticles } = this.state;
    const { channel, type, featuredPostColumns } = this.props;

    // @ts-ignore
    const limit = featuredPostColumns > 0 && page === 1 ? 3 : 4;

    Content.get(
      merge(
        {
          page,
          limit,
          channel,
          paginate: true,
          order_dir: "DESC",
          order_col: "updated_at",
          type: type.slice(0, -1),
        },
        channel === "organisation" && { organisational: true }
      )
    ).then((results: any) => {
      if (!shallow) {
        // Standard call
        this.setState(
          {
            page: page + 1,
            articles: [...currentArticles, ...results.all],
          },
          () => this.getContent(true) // Make shallow request
        );
      } else {
        this.setState({ canShowMore: !isEmpty(results.all) });
      }
    });
  };

  handleShowMore = () => this.getContent();

  render() {
    const { articles, canShowMore } = this.state;
    const { titleClassName, featuredPostColumns, type } = this.props;

    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <>
        <ContentBlock
          columns={2}
          hideAbstract
          hideChannels
          // @ts-ignore
          articles={articles}
          title={camelCase(type)}
          titleClassName={titleClassName}
          featuredPostColumns={featuredPostColumns}
        />
        {canShowMore && (
          <StyledShowMore onClick={() => this.handleShowMore()}>
            Show more
          </StyledShowMore>
        )}
      </>
    );
  }
}

const StyledShowMore = styled.div`
  text-align: right;
  color: var(--brandColorPrimary);

  &:hover {
    cursor: pointer;
    color: var(--assessmentColorSecondary);
  }
`;
