import styled from "styled-components";
import React, { useState } from "react";
import TagLimit from "./managers/TagLimit";
import { InputGroup, Intent } from "@blueprintjs/core";

import FormGroup from "@components/FormGroup";

interface Props {
  name: string;
  max?: number;
  min?: number;
  intent?: any;
  type?: string;
  label?: string;
  large?: boolean;
  className?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  rightElement?: any;
  helperText?: string;
  defaultValue?: string;
  lightBorder?: boolean;
  autoComplete?: boolean;
  onFocus?: (name: string) => any;
  onChange?: (name: string, value: string) => any;
}

const InputUnderline = ({
  max,
  min,
  name,
  label,
  onFocus,
  onChange,
  required,
  disabled,
  maxLength,
  className,
  helperText,
  large = true,
  rightElement,
  type = "text",
  readOnly = false,
  defaultValue = "",
  autoComplete = true,
  lightBorder = false,
  intent = Intent.NONE,
}: Props) => {
  const [value, setValue] = useState(defaultValue);

  /** Only use tag lmit if max length is set on input */
  // eslint-disable-next-line
  const setRightElement =
    rightElement ||
    (maxLength && (
      // eslint-disable-next-line
      <TagLimit max={maxLength} string={value} />
    ));

  return (
    <StyledContainer lightBorder={lightBorder}>
      <FormGroup
        label={label}
        labelFor={name}
        intent={intent}
        className={className}
        helperText={helperText}
      >
        <InputGroup
          id={name}
          max={max}
          min={min}
          type={type}
          name={name}
          large={large}
          intent={intent}
          readOnly={readOnly}
          disabled={disabled}
          required={required}
          maxLength={maxLength}
          className="underline"
          defaultValue={defaultValue}
          rightElement={setRightElement}
          onFocus={onFocus && (() => onFocus(name))}
          autoComplete={autoComplete ? undefined : "off"}
          // @ts-ignore
          onChange={(event: any) => {
            /** Sets temp value for limits */
            setValue(event.target.value);

            /** Updates parent component if required */
            onChange && onChange(name, event.target.value);
          }}
        />
      </FormGroup>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ lightBorder: boolean }>`
  input {
    padding-left: 0;
    font-weight: 600;
    box-shadow: none;
    border-radius: 0;
    padding-right: 0;
    margin-bottom: 0.8rem;
    color: var(--textColor);
    border-bottom: 1px solid
      var(
        ${({ lightBorder }) => (lightBorder ? "--borderColor" : "--textColor")}
      );
  }

  .bp3-input:disabled,
  .bp3-input.bp3-disabled,
  .bp3-input-group.bp3-disabled {
    cursor: default;
    background: none;
  }

  .bp3-input-group.bp3-large .bp3-input {
    font-size: 1.2rem;
  }

  .bp3-intent-danger input {
    background-color: #f9f3f3;
    box-shadow: none !important;
    border-bottom-color: var(--danger);
  }

  .bp3-input-group .bp3-input-action:last-child,
  .bp3-input-group > .bp3-button:last-child,
  .bp3-input-group > .bp3-icon:last-child {
    top: -1px;
    right: -4px;
  }
`;

export default InputUnderline;
