import api from "../../api";
import Toast from "../../utils/Toast";

import { SET_SEARCH, UNSET_SEARCH } from "../reducers/search";

/**
 * Perform search
 * @param {string} term
 */
export const performSearch = (term) => (dispatch) =>
  api
    .get(`employee/search?q=${term}`)
    .then((response) => {
      dispatch({
        type: SET_SEARCH,
        payload: response.data.payload.results,
      });
    })
    .catch((error) => Toast.danger({ message: error.message }));

/**
 * Clear search
 */
export const clearSearch = () => (dispatch) => {
  dispatch({
    type: UNSET_SEARCH,
  });
};
