import React, { useState } from "react";
import styled from "styled-components";
import { EapCategory, EAP_PARTNERS } from "@constants/eap";
import { EapCard } from "@components/EapCard";

const CATEGORIES = Object.values(EapCategory);

export const EapContainer = () => {
  const [activeCategory, setActiveCategory] = useState<EapCategory | null>(
    null
  );

  return (
    <StyledContainer>
      <StyledHeading>EAP Apps</StyledHeading>
      <StyledSubheading>
        Miindset curates helpful information <strong>IMMEDIATELY</strong> such
        as articles, <strong>videos</strong> and <strong>podcasts</strong> based
        on how you feel. There is no need to wait days to improve your mental
        wellbeing, see a doctor or watch an online training program. We have
        also partnered with lots of beneficial apps and businesses.
      </StyledSubheading>

      <StyledTabs>
        {CATEGORIES.map((category) => (
          <StyledTab
            isActive={activeCategory === category}
            onClick={() => {
              if (activeCategory === category) return setActiveCategory(null);
              return setActiveCategory(category);
            }}
            key={category}
          >
            {category}
          </StyledTab>
        ))}
      </StyledTabs>
      <StyledPartnersContainer>
        {EAP_PARTNERS.filter((partner) =>
          activeCategory ? partner.category === activeCategory : true
        ).map((partner) => (
          <EapCard key={partner.name} {...partner} />
        ))}
      </StyledPartnersContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  padding-bottom: 3rem;
`;

const StyledHeading = styled.h1`
  font-size: 26px;
  color: var(--brandColorPrimary);
`;

const StyledPartnersContainer = styled.div`
  display: grid;
  @media (min-width: ${({ theme }) => theme.breakpointLarge}) {
    grid-template-columns: repeat(3, 1fr);
  }

  grid-gap: 3rem;
  grid-template-columns: repeat(1, 1fr);
`;

const StyledSubheading = styled.div`
  font-size: 16px;
  margin-bottom: 2rem;
`;

const StyledTabs = styled.div`
  margin-bottom: 4rem;
`;

const StyledTab = styled.a<{ isActive?: boolean }>`
  display: inline-block;
  margin-left: -10px;
  padding: 20px;
  padding-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
  cursor: pointer;
  color: ${({ isActive }) =>
    isActive ? "var(--brandColorPrimary)" : "rgba(0, 0, 0, 0.5)"};

  :hover {
    color: ${({ isActive }) =>
      isActive ? "var(--brandColorPrimary)" : "rgba(0, 0, 0, 0.5)"};
  }
  font-weight: 600;
`;
