/* eslint no-underscore-dangle: 0
 */
import styled from "styled-components";
import { Link } from "react-router-dom";
import React, { PureComponent } from "react";

import SearchResultImage from "./SearchResultImage";
import SearchResultTitle from "./SearchResultTitle";

interface Props {
  result: any;
  onClickSearchResult: () => void;
}

export default class SearchResult extends PureComponent<Props> {
  render() {
    const { result, onClickSearchResult } = this.props;

    const type = result.type;

    const isVideo = type === "video";

    const overview = (
      <StyledResultContainer>
        <SearchResultImage result={result} isVideo={isVideo} />
        <SearchResultTitle title={result.headline} type={type} />
      </StyledResultContainer>
    );

    return (
      <Link to={`/${type}/${result.slug}`} onClick={onClickSearchResult}>
        {overview}
      </Link>
    );
  }
}

const StyledResultContainer = styled.div`
  display: flex;
  padding: 1rem;
  border-radius: 1px;

  &:hover {
    cursor: pointer;
    background-color: var(--placeholderImageBackground);
  }
`;
