import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import React, { PureComponent, Fragment } from "react";

import api from "../api";
import Toast from "@utils/Toast";
import Loader from "@components/Loader";
import analytics from "@utils/analytics";
import { Title } from "@components/Title";
import Assessment from "@utils/Assessment";
import Survey from "@components/Assessment";
import TitleSecondary from "@components/TitleSecondary";
import { refreshUser } from "@store/actions/authentication";
import { EVENT_WELLBEING_COMPLETED } from "@constants/analytics";
import { QuestionInterface } from "@interfaces/QuestionInterface";
import { AssessmentInterface } from "@interfaces/AssessmentInterface";

const mapDispatchToProps = (dispatch: any) => ({
  dispatchRefreshUser: () => dispatch(refreshUser()),
});

class AssessmentContainer extends PureComponent<any> {
  state = {
    assessmentId: null,
    assessmentCompleted: false,
    questions: [] as QuestionInterface[],
  };

  errorCount = 0;

  errorCountMax = 3;

  componentDidMount() {
    this.getAssessmentId();
  }

  componentWillUnmount() {
    // @ts-ignore
    clearTimeout(this.timeoutID);
  }

  /**
   * Gets the current assement id, a new assesment cannot be started
   * until the current one has been completed.
   */
  getAssessmentId = async () => {
    // @ts-ignore
    await api
      .post("employee/assessments/start")
      .then((assessmentId) => {
        // The api can sometimes return an error, let's check for that
        // and refetch the data if something goes wrong.
        if (typeof assessmentId !== "string") {
          this.handleGetAssessmentIdError();
        } else {
          this.setState({ assessmentId }, () => this.getCurrentAssessment());
        }
      })
      .catch((error) => {
        console.error(error);
        Toast.danger(error.message);
      });
  };

  handleGetAssessmentIdError = () => {
    if (this.errorCount >= this.errorCountMax) {
      Toast.danger({
        message:
          "Apologies, the assesment is not available at this moment in time. Please try again later.",
      });
      window.location.href = "/";
    } else {
      this.errorCount += 1;
      // @ts-ignore
      this.timeoutID = setTimeout(() => this.getAssessmentId(), 2000);
    }
  };

  /**
   * Gets the details of the current assessment.
   */
  getCurrentAssessment = async () => {
    const { assessmentId } = this.state;

    await api.get(`employee/assessments/${assessmentId}`).then((response) => {
      this.setState({
        questions: Assessment.normalize(
          response.data.payload.assessment.questions
        ),
      });
    });
  };

  /**
   * @param {AssessmentInterface[]} answers
   */
  validateAnswers = (assessment: AssessmentInterface[]) => {
    // Clone the assesment so we don't modify the original copy
    const assessmentClone = JSON.parse(JSON.stringify(assessment));
    const lastAnswer = assessmentClone.pop().answer;

    const answersChanged = assessmentClone.every(({ answer }: any) => {
      return answer !== null;
    });

    if (!answersChanged) {
      Toast.info({
        message: `We have noticed that the 1-${
          this.state.questions.length - 1
        } questions have remained untouched. For ${
          process.env.REACT_APP_COMPANY_NAME
        }'s wellbeing checker to offer you unique assistance, the questions require for you to change them individually`,
      });
      return false;
    }

    // Special case for question 7, the answers cannot be submitted to
    // the api unless at least one answer has been selected.
    if (!lastAnswer.length) {
      Toast.info({
        message: `We have noticed that question ${this.state.questions.length}'s checkboxes have remained unchecked. For ${process.env.REACT_APP_COMPANY_NAME}'s wellbeing checker to offer you unique assistance please check at least one of the boxes`,
      });
      return false;
    }
    return true;
  };

  /**
   * Submits the assessment answers to the api.
   *
   * @param {AssessmentInterface[]} answers
   */
  submitAssessmentAnswers = (answers: AssessmentInterface[]) => {
    const { assessmentId } = this.state;

    // @ts-ignore
    const assessment = answers.filter((answer) => answer); // Reset the array keys for the api

    if (!this.validateAnswers(assessment)) {
      return;
    }

    api
      .post(`employee/assessments/${assessmentId}/answer`, {
        assessment: this.convertAnswersToEnums(assessment),
      })
      .then(() => this.completeAssessment())
      .catch(() => {
        Toast.danger({
          message:
            "Appologies, there was an issue submitting your answers, please try again.",
        });
      });
  };

  /**
   * Convert the numbers into thier enum form.
   * The last question (slider) remains untouched.
   *
   * @param {AssessmentInterface[]} answers
   */
  convertAnswersToEnums = (answers: AssessmentInterface[]) => {
    const getEnum = (value: number): string => {
      if (value === 1) return "never";
      if (value === 2) return "rarely";
      if (value === 3) return "sometimes";
      if (value === 4) return "often";

      return "very_often";
    };

    return answers.map((answer) => {
      return {
        ...answer,
        answer:
          answer.question === answers.length
            ? answer.answer
            : getEnum(answer.answer),
      };
    });
  };

  completeAssessment = () => {
    // @ts-ignore
    const { dispatchRefreshUser } = this.props;

    analytics.logEvent(EVENT_WELLBEING_COMPLETED);

    // @ts-ignore
    this.setState({ assessmentCompleted: true }, window.scrollTo(0, 0));
    localStorage.setItem("hasCompletedWellbeing", "true");

    setTimeout(() => {
      dispatchRefreshUser().then(() => {
        window.location.href = "/";
      });
    }, 3000);
  };

  render() {
    const { questions, assessmentCompleted } = this.state;

    if (!questions.length) return <Loader />;

    return (
      <Fragment>
        <Helmet>
          <title>My Wellbeing - {process.env.REACT_APP_COMPANY_NAME}</title>
        </Helmet>
        <Fragment>
          {assessmentCompleted ? (
            <StyledAssessmentContainerSuccess>
              <TitleSecondary className="mb-4">
                Wellbeing Checker Completed
              </TitleSecondary>
              <p>
                Thank you for completing your wellbeing checker, you will be
                redirected to the <Link to="/">homepage</Link> in a few seconds.
              </p>
              <Loader delay={0} />
            </StyledAssessmentContainerSuccess>
          ) : (
            <div>
              <StyledTitle className="pb-4">My Wellbeing</StyledTitle>
              <h3 className="mt-4 pt-4 mb-8">
                Please read the statements below and think about how often you
                have felt that way over the past week.
              </h3>
              <Survey
                questions={questions}
                submitAssessmentAnswers={this.submitAssessmentAnswers}
              />
            </div>
          )}
        </Fragment>
      </Fragment>
    );
  }
}

const StyledAssessmentContainerSuccess = styled.div`
  width: 90%;
  max-width: 550px;
  text-align: center;
  margin: 3rem auto 5rem;
`;

export default withRouter(
  // @ts-ignore
  connect(null, mapDispatchToProps)(AssessmentContainer)
);

const StyledTitle = styled(Title)`
  &&& {
    &::after {
      top: 29%;
    }
  }
`;
