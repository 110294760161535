export interface EapPartner {
  name: string;
  type: string;
  description: string;
  link: string;
  category: EapCategory;
  image: any;
}

export enum EapCategory {
  support = "Support",
  work = "Work / Legal",
  physicalHealth = "Physical Health",
  healthAndFitness = "Health and Fitness",
}

export const EAP_PARTNERS: EapPartner[] = [
  {
    name: "Cognomie",
    link: "https://cognomie.com/",
    type: "Mental Fitness Coaching",
    category: EapCategory.healthAndFitness,
    description:
      "We make measurable improvements in individual and team performance, driven by data insights to develop and improve Mental Fitness Personalised web-based coaching, training and organisational performance consulting. Delivered globally. Optimised and deployed rapidly.",
    image: "cognomie.png",
  },
  {
    name: "Kineticoach",
    link: "https://www.kineticoach.com/",
    type: "Fitness",
    category: EapCategory.physicalHealth,
    description:
      "Get LEAN & FIT in a HOTEL, HOME, or GYM with the easy-to-use KINETICOACH Custom Workout Builder. Select your equipment, time available, & how you’re feeling—KINETICOACH instantly delivers professional workouts personalized to your goals & experience. Track results & join teams to stay motivated. Download FREE today! Custom Workouts. Zero Excuses.",
    image: "kineticoach.png",
  },
  {
    name: "Moodbeam",
    link: "https://www.moodbeam.co.uk/",
    type: "Mental Health Tracker",
    category: EapCategory.physicalHealth,
    description:
      "Moodbeam is a free solution enabling your people to feedback on the highs and lows of their working day in real time. This is so much more than a survey.",
    image: "moodbeam.png",
  },
  {
    name: "Stellar Education",
    type: "Training Provider",
    link: "https://stellareducation.co.uk/",
    description: `Stellar Education was the first UK SKILLSFIRST accredited training provider to offer an insight into the world of online learning and examination in financial trading, both at Certificate (L3) and Diploma (L5) level.
        Since then, we have extended our product range to provide key online learning and examinations in finance and well-being, both at L1 and L2 levels which can be used across a wide range of industries.
        Stellar Education cater for two key industry sectors, Financial Services and Further Education and now have 7 SKILLSFIRST accredited courses.`,
    image: "stellar.png",
    category: EapCategory.support,
  },
  {
    name: "Zurich",
    category: EapCategory.support,
    type: "Insurance",
    link: "https://www.zurich.co.uk/",
    description: `At Zurich we believe in creating a brighter future for our customers, partners, our people and the planet by providing products and services which have a positive impact on peoples lives. We want to be one of the most responsible businesses in the world.
      In the UK, we provide a suite of general insurance products covering business, casualty, motor, property and travel insurance, life insurance and pensions products to individuals and corporate customers. We employ over 4,500 people in the UK and are based across a number of locations.`,
    image: "zurich.jpg",
  },
  {
    name: "AXA",
    type: "Health Insurance",
    link: "https://www.axahealth.co.uk/",
    category: EapCategory.healthAndFitness,
    description:
      "At AXA Health, we’re here to help you bring out your best self. That means a healthy you – reassured that you can access the clinical expertise you need, whenever you need it. It means a confident you – up-to-date on the latest health, lifestyle and fitness information, and feeling fully supported in finding your own route to wellbeing. Whatever healthy looks like for you, we’re here to help you get there.",
    image: "axa.png",
  },
  {
    name: "Metlife",
    link: "https://www.metlife.co.uk/",
    type: "Insurance & EBP",
    category: EapCategory.work,
    description:
      "Stay covered with MetLife UK employee benefits, income protection, retirement, investments and financial solutions. Providing greater certainty worldwide. MetLife Inc. has been helping people plan their future since 1868. Millions of customers already benefit from our expertise across the globe.",
    image: "metlife.jpg",
  },
  {
    name: "Vitality",
    link: "https://www.vitality.co.uk/",
    category: EapCategory.healthAndFitness,
    type: "Health & Life Insurance",
    description: `Award-Winning Health & Life Cover That Rewards You For Being Healthy. Get A Quote Now! Get Discounts With Our Partners and Rewards For Being Healthy. Get A Quote Today! Quote Online Today. Award Winning Cover. Instant Online Quote. Rewards for being healthy.
      Health Shield.`,
    image: "vitality.jpg",
  },
  {
    name: "Westfield Health",
    type: "Private Health Insurance",
    link: "https://www.westfieldhealth.com/",
    category: EapCategory.healthAndFitness,
    description:
      "Or get in touch to discuss developing a health & wellbeing strategy for your business. Improving people's quality of life by putting their health and wellbeing first.",
    image: "westfield.png",
  },

  {
    name: "Simplyhealth",
    category: EapCategory.work,
    link: "https://www.simplyhealth.co.uk/",
    type: "Health & Dental Plans",
    description:
      "At Simplyhealth, we know that prevention is better than cure.  We want to help you stay healthy, now and in the future. With our new free app, SimplyMe, you can take your health and wellbeing into your own hands. Through our health plans, we make sure you can access – and afford to stay healthy through dental treatments, visits to the optician, complementary therapies and much more.",
    image: "simplyHealth.jpg",
  },
  {
    name: "Yulife",
    type: "Yulife Insurance",
    link: "https://yulife.com/",
    category: EapCategory.healthAndFitness,
    description:
      "Perks like no other. Earn Air Miles for staying healthy and get discounts on major brands. Give your people a wellbeing benefit they'll love and life insurance they'll value. Gym Memberships. Gift Cards. Protect Your People.",
    image: "yulife.jpg",
  },
  {
    name: "PHC",
    link: "https://www.thephc.co.uk/",
    type: "Private Medical Insurance",
    category: EapCategory.healthAndFitness,
    description:
      "At PHC we really care about our clients and their company members’ health. That’s why we work closely with carefully selected intermediaries and our mutual clients to understand their specific requirements. So, we can provide a tailored and flexible private healthcare solution that reflects both the company’s needs and requirements, as well as those of their company members.",
    image: "phc.jpg",
  },
  {
    name: "Freedom Health Insurance",
    link: "https://www.freedomhealthinsurance.co.uk/",
    category: EapCategory.healthAndFitness,
    type: "Private Medical Insurance",
    description:
      "We make life a bit easier by giving you the peace of mind that tomorrow is taken care of with a health insurance plan that you can trust.Whether it’s for yourself, your family or your business, we are committed to giving you the freedom of being in charge of your healthcare and the support to help you better manage it, through straightforward and affordable health insurance plans that can be tailored to your specific needs and circumstances.",
    image: "freedom.jpg",
  },
  {
    name: "CanadaLife",
    type: "Life Insurance",
    link: "https://www.canadalife.co.uk/",
    category: EapCategory.healthAndFitness,
    description:
      "We provide retirement, investment and protection solutions to our customers. Beyond our products and solutions, we care deeply about the communities in which we live. Industry-recognised. Experts who care. Customer innovation. Aviva",
    image: "canada.jpg",
  },
  {
    name: "WPA",
    type: "Health Insurance",
    link: "https://www.wpa.org.uk/",
    category: EapCategory.healthAndFitness,
    description:
      "We provide access to the sort of healthcare that we want for ourselves and our families. We’ve been helping our members as a not-for-profit organisation since 1901. That’s quite a while now and, because we're good at it, that’s all we do. Just health insurance. No distractions.",
    image: "wpa.jpg",
  },
  {
    name: "iGlobal Law",
    type: "Global Employment law",
    link: "https://www.igloballaw.com/",
    category: EapCategory.work,
    description:
      "iGlobal Law is a London based niche law firm specialising in international employment and compliance law advice and related services.",
    image: "iglobal.png",
  },
  {
    name: "Smart Recovery",
    category: EapCategory.support,
    link: "https://smartrecovery.org.uk/",
    type: "Self-Management and Recovery Training",
    description:
      "To empower people with practical skills, tools and support so that they may manage their addictive behaviour and lead satisfying and meaningful lives.",
    image: "smart-recovery.png",
  },
  {
    name: "AM Mindfulness",
    link: "https://ammindfulness.com/gsc?yes",
    category: EapCategory.support,
    type: "Self-Management and Recovery Training",
    description: `Am Mindfulness, a clinically effective way to begin your personalised mindfulness journey.
        Track your emotions and feel the change within you.`,
    image: "mobio.png",
  },
  {
    name: "SHOUT",
    category: EapCategory.support,
    type: "Mental Health Support",
    link: "https://giveusashout.org/",
    description:
      "Shout 85258 is a free, confidential, 24/7 text messaging support service for anyone who is struggling to cope. We launched publicly in May 2019 and we’ve had more than 900,000 conversations with people who are anxious, stressed, depressed, suicidal or overwhelmed and who need immediate support.",
    image: "shout.png",
  },
];
