import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import React, { useEffect, useState } from "react";

import api from "@api";
import Loader from "@components/Loader";
import ContainerContent from "@components/ContainerContent";

const TermsContainer = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    api
      .get("pages/terms")
      .then(({ data }) => setContent(data.payload.markdown));
  });

  return (
    <ContainerContent>
      <Helmet>
        <title>Terms - {process.env.REACT_APP_COMPANY_NAME}</title>
      </Helmet>
      {!content ? <Loader /> : <ReactMarkdown source={content} />}
    </ContainerContent>
  );
};

export default TermsContainer;
