import style from "styled-components";

export default style.li`
  position: relative;
  border-right: 1px solid rgba(0,0,0,0.2);

  :first-child {
    border-left: 1px solid rgba(0,0,0,0.2);
  }
`;
