import React, { PureComponent } from "react";

import Youtube from "../utils/Youtube";

interface Props {
  id: string;
}

export default class YouTubeThumbnail extends PureComponent<Props> {
  render() {
    const { id } = this.props;

    // Incase a full YouTube URL is passed
    const source = id.includes("http") ? Youtube.getIDFromLink(id) : id;

    return (
      <img src={`https://img.youtube.com/vi/${source}/1.jpg`} alt="Thumbnail" />
    );
  }
}
