import api from "../../../api";

import Toast from "@utils/Toast";
import { IManagersStudentsData } from "@interfaces/managers/IManagersStudentsData";

/**
 * Organisation content
 *
 * @return {Promise<IManagersStudentsData>}
 */
export const getManagersStudentData = () =>
  api
    .get("admin/students")
    .then(
      ({ data: { payload } }: { data: { payload: IManagersStudentsData } }) =>
        payload
    )
    .catch(() => Toast.danger({ message: "Unable to retrieve students data" }));
