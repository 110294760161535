export const SET_ORGANISATION = "SET_ORGANISATION";
export const UNSET_ORGANISATION = "UNSET_ORGANISATION";

const initialState = {
  name: "",
  selected: 0,
  image_id: null,
  organisation_id: null,
};

const organisation = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANISATION:
      return { ...action.payload };
    case UNSET_ORGANISATION:
      return initialState;
    default:
      return state;
  }
};

export default organisation;
