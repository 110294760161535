import styled from "styled-components";
import { Link } from "react-router-dom";
import React, { Component } from "react";

import Icon from "./Icon";
import Container from "./Container";

export default class CookieNotice extends Component {
  state = {
    hideCookieNotice: false,
  };

  componentDidMount() {
    this.setState({
      hideCookieNotice: Boolean(localStorage.getItem("hideCookieNotice")),
    });
  }

  agree = () => {
    this.setState({ hideCookieNotice: true });
    // @ts-ignore
    localStorage.setItem("hideCookieNotice", true);
  };

  render() {
    const { hideCookieNotice } = this.state;

    if (hideCookieNotice) {
      return null;
    }

    return (
      <StyledCookie>
        <StyledCookieContainer>
          <StyledCookieTitle className="mb-4">
            Do you agree to our policy?
          </StyledCookieTitle>
          <StyledCookieDisclaimer className="mb-4">
            We use{" "}
            <StyledCookieLink to="/privacy-policy">cookies</StyledCookieLink> to
            give you the best online experience.
            <br />
            Please let us know if you agree to all of these cookies.
          </StyledCookieDisclaimer>
          <div className="mb-4">
            <StyledCookieButton id="test__cookie-agree" onClick={this.agree}>
              <StyledIcon icon="tick" /> Yes, I agree
            </StyledCookieButton>
            <StyledCookieButton>
              <Link to="/privacy-policy">
                <StyledIcon icon="cross" /> No, take me to the settings
              </Link>
            </StyledCookieButton>
          </div>
        </StyledCookieContainer>
      </StyledCookie>
    );
  }
}

const StyledCookie = styled.div`
  padding: 1rem 0 0.1rem;
  background: var(--brandColorSecondary);
`;

const StyledCookieContainer = styled(Container)`
  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const StyledCookieTitle = styled.div`
  color: white;
  font-size: 1rem;
  padding: 0.3rem 0;
  font-weight: bold;
`;

const StyledCookieLink = styled(Link)`
  color: var(--cookieNoticeLink);
`;

const StyledIcon = styled(Icon)`
  padding-right: 0.1rem;
`;

const StyledCookieDisclaimer = styled.div`
  color: #ccc;
  font-size: 0.9rem;
`;

const StyledCookieButton = styled(StyledCookieTitle)`
  color: #fea602;

  &:hover {
    color: #fff;
    cursor: pointer;
  }
`;
