import React from "react";
import { Helmet } from "react-helmet";
import { Button } from "@blueprintjs/core";

import { Link } from "@components/Link";
import ContainerCentered from "@components/ContainerCentered";
import { RouteComponentProps } from "react-router-dom";

const UNIDAYS_ERROR_MESSAGES = [
  "We were unable to authorise you with UNiDAYS. Please click below to sign up as standard.",
  "This email address is already taken. Please try signing in as usual or registering with a different email.",
  "This email address is already registered with a non-unidays account. Please try signing up with a different email.",
];

interface Props extends RouteComponentProps {
  match: {
    url: string;
    path: string;
    isExact: boolean;
    params: {
      errorId: string;
    };
  };
}

export const StudentOAuthFailureContainer = ({ match: { params } }: Props) => {
  /**
   * Returns the correct error message for the error presented
   *
   * @returns {string}
   */
  const getErrorMessage = () => {
    const errorId = Number(params.errorId) || 0;

    return UNIDAYS_ERROR_MESSAGES[errorId];
  };

  return (
    <ContainerCentered>
      <Helmet>
        <title>Unable to authorise</title>
      </Helmet>
      <div>
        <h2>We encountered an error while attempting to sign you up.</h2>
        <p>{getErrorMessage()}</p>
      </div>
      <Link to="/login">
        <Button large type="submit" intent="primary" rightIcon="chevron-right">
          Back to login
        </Button>
      </Link>
    </ContainerCentered>
  );
};
