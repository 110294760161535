import { Helmet } from "react-helmet";
import styled from "styled-components";
import React, { Component } from "react";

export default class PageNotFoundContainer extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>404 - {process.env.REACT_APP_COMPANY_NAME}</title>
        </Helmet>
        <StyledPageNotFoundContainer>
          404 page not found
        </StyledPageNotFoundContainer>
      </>
    );
  }
}

const StyledPageNotFoundContainer = styled.h1`
  margin-top: 5rem;
  text-align: center;
`;
