import React from "react";
import posed from "react-pose";

const PoseAnimated = ({ className, pose, children }: any) => (
  <Animated className={className} pose={pose}>
    {children}
  </Animated>
);

const Animated = posed.div({
  open: { y: 0, opacity: 1 },
  closed: { y: 10, opacity: 0 },
});

export default PoseAnimated;
