import React from "react";
import styled from "styled-components";
import { Dialog } from "@blueprintjs/core";

import MindLogo from "../assets/images/mind-compressed.png";
import ShoutLogo from "../assets/images/shout-compressed.png";
import ChildlineLogo from "../assets/images/childline-compressed.jpg";
import SamaritansLogo from "../assets/images/samaritans-compressed.jpg";

interface IShoutAppModal {
  isOpen: boolean;
  handleClose: () => any;
}

export const ShoutAppModal = ({ isOpen, handleClose }: IShoutAppModal) => {
  return (
    <StyledDialog
      isOpen={isOpen}
      title="Start a conversation"
      onClose={handleClose}
    >
      <StyledShoutContainer>
        <StyledMessageHeaderText>Text 'WAVE'</StyledMessageHeaderText>
        <StyledMessageHeaderBubbleContainer>
          <StyledMessageHeaderBubble>
            <StyledDot />
            <StyledDot />
            <StyledDot />
          </StyledMessageHeaderBubble>
        </StyledMessageHeaderBubbleContainer>
        <StyledMessageHeaderText>To: 85258</StyledMessageHeaderText>
        <StyledSecondaryHeaderText>
          Start a conversation
        </StyledSecondaryHeaderText>
        <StyledMainBodyText>
          Miindset are in partnership with Shout. By texting the word 'WAVE' to
          85258, you can anonymously correspond with a trained Shout volunteer
          to help you 24/7 for as long as you require.
        </StyledMainBodyText>
        <StyledOrganisationsGrid>
          <StyledOrganisation>
            <StyledOrganisationImageContainer>
              <StyledOrganisationImage src={SamaritansLogo} />
            </StyledOrganisationImageContainer>
            <StyledOrganisationActionText>
              Phone 116 123
            </StyledOrganisationActionText>
            <StyledOrganisationBodyText>
              For everyone. 24/7
            </StyledOrganisationBodyText>
          </StyledOrganisation>
          <StyledOrganisation>
            <StyledOrganisationImageContainer>
              <StyledOrganisationImage src={ChildlineLogo} />
            </StyledOrganisationImageContainer>
            <StyledOrganisationActionText>
              Call 0800 1111
            </StyledOrganisationActionText>
            <StyledOrganisationBodyText>
              For children and young people under 19. 24/7
            </StyledOrganisationBodyText>
          </StyledOrganisation>
          <StyledOrganisation>
            <StyledOrganisationImageContainer>
              <StyledOrganisationImage src={MindLogo} />
            </StyledOrganisationImageContainer>
            <StyledOrganisationActionText>
              Call 0300 123 3393
            </StyledOrganisationActionText>
            <StyledOrganisationBodyText>
              For everyone. 9am - 6pm, Mon - Fri
            </StyledOrganisationBodyText>
          </StyledOrganisation>
          <StyledOrganisation>
            <StyledOrganisationImageContainer>
              <StyledOrganisationImage src={ShoutLogo} />
            </StyledOrganisationImageContainer>
            <StyledOrganisationActionText>
              Text 'SHOUT' to 85258
            </StyledOrganisationActionText>
            <StyledOrganisationBodyText>
              For everyone. 24/7
            </StyledOrganisationBodyText>
          </StyledOrganisation>
        </StyledOrganisationsGrid>
      </StyledShoutContainer>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  width: 700px;
  max-width: 100%;
`;

const StyledShoutContainer = styled.div`
  padding: 1.2rem;
`;

const StyledMessageHeaderText = styled.p`
  margin: 0;
  color: #000;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
`;

const StyledMessageHeaderBubbleContainer = styled.div`
  padding: 10px 0;
`;

const StyledMessageHeaderBubble = styled.div`
  height: 56px;
  display: flex;
  padding: 1rem;
  margin: 0 auto;
  min-width: 100px;
  width: max-content;
  position: relative;
  margin-bottom: 14px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  background-color: #5f5ced;

  &:after {
    width: 0;
    height: 0;
    bottom: 0;
    left: 30%;
    content: "";
    position: absolute;
    border: 9px solid transparent;
    border-top-color: #5f5ced;
    border-bottom: 0;
    border-left: 0;
    margin-left: -3.5px;
    margin-bottom: -9px;
  }
`;

const StyledDot = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 4px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 1);

  &:last-child {
    margin: 0;
  }
`;

const StyledSecondaryHeaderText = styled.p`
  margin: 0;
  color: #5f5ced;
  font-size: 20px;
  font-weight: 700;
  margin-top: 0.8rem;
  text-align: center;
`;

const StyledMainBodyText = styled.p`
  margin: 0;
  color: #000;
  font-size: 16px;
  margin-top: 0.8rem;
  text-align: center;
`;

const StyledOrganisationsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const StyledOrganisation = styled.div`
  padding: 1rem;
  display: flex;
  flex-basis: 50%;
  align-items: center;
  flex-direction: column;
`;

const StyledOrganisationImageContainer = styled.div`
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const StyledOrganisationImage = styled.img`
  width: 150px;
`;

const StyledOrganisationActionText = styled.p`
  margin: 0.5rem 0;
  font-size: 20px;
  font-weight: 700;
  color: var(--brandColorPrimary);
`;

const StyledOrganisationBodyText = styled.p`
  text-align: center;
`;
