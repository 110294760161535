import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import error from "../assets/images/error.png";

export default function Image(props: any) {
  return (
    <LazyLoadImage
      {...props}
      onError={(e: any) => {
        e.target.onerror = null;
        e.target.src = error;
      }}
    />
  );
}
