import moment from "moment";
import styled from "styled-components";
import React, { useState } from "react";
import {
  DatePicker as BluePrintDatePicker,
  TimePrecision,
} from "@blueprintjs/datetime";

interface Props {
  name?: string;
  value?: Date;
  defaultValue?: string;
  onChange?: (date: Date) => void;
}

const DatePicker = ({ value, onChange, defaultValue, name }: Props) => {
  const [date, setDate] = useState<string>("");

  function formatDate(selectedDate: Date) {
    return moment(selectedDate).format("YYYY-MM-DD HH:mm:ss");
  }

  function handleOnChange(selectedDate: Date) {
    setDate(formatDate(selectedDate));
  }

  return (
    <div>
      <StyledDatePicker
        canClearSelection={false}
        timePrecision={TimePrecision.MINUTE}
        value={value || moment(defaultValue).toDate()}
        onChange={onChange ? onChange : handleOnChange}
      />
      <input type="hidden" name={name} value={date || defaultValue} />
    </div>
  );
};

const StyledDatePicker = styled(BluePrintDatePicker)`
  &&& {
    border-radius: 4px;
    margin-top: 1.5rem;
    background-color: white;
    border: 1px solid var(--borderColor);

    .bp3-datepicker-caption select + .bp3-icon {
      display: none;
    }

    .bp3-datepicker-year-select {
      min-width: 90px;
    }

    .DayPicker-Day.DayPicker-Day--selected {
      color: white;
      background-color: var(--brandColorPrimary);
    }

    .DayPicker-Day {
      height: 26px;
      color: var(--textColor);
    }

    .bp3-datepicker-day-wrapper {
      padding: 4px 7px 0;
    }

    .bp3-timepicker .bp3-timepicker-input-row {
      color: white;
      height: 34px;
      margin: 0 4px;
      display: block;
      box-shadow: none;
      background-color: var(--brandColorPrimary);
    }

    .bp3-timepicker .bp3-timepicker-divider-text {
      width: 10px;
      color: white;
      font-size: 0.9rem;
    }

    .DayPicker-Weekday {
      color: #a0acb6;
      font-weight: 300;
    }

    .DayPicker-Caption {
      margin-bottom: 5px;
    }

    .bp3-timepicker .bp3-timepicker-input {
      width: 80px;
      color: white;
      height: auto;
      padding: 6px 0;
      margin-top: 3px;
      text-align: left;
      border-radius: 0;

      &:focus,
      &:hover {
        color: #d2b2bd;
        box-shadow: none;
      }

      &.bp3-timepicker-hour {
        text-align: right;
      }
    }

    .bp3-icon > svg:not([fill]) {
      fill: var(--borderColor);
    }
  }
`;

export default DatePicker;
