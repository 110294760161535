import React from "react";
import styled from "styled-components";

import { Link } from "@components/Link";
import { Table } from "@components/Table";
import { Title } from "@components/Title";

interface Props {
  type: string;
  total: number;
  title?: string;
  className?: string;
  engagements: Record<string, number>[];
}

export const TopEngagementsTable = ({
  type,
  title,
  total,
  className,
  engagements,
}: Props) => {
  /**
   * Returns a single formatted row
   *
   * @param {Record<string, number | string>} row
   * @returns {JSX.Element[]}
   */
  const formatRow = (row: Record<string, number | string>): JSX.Element[] => {
    return Object.keys(row)
      .filter((key) => key !== "slug" && key !== "engagement_type")
      .map((key, index) => (
        <td key={index}>
          <Link to={`/${type}/${row["slug"]}`}>{row[key]}</Link>
        </td>
      ));
  };

  /**
   * Formats the data as rows
   *
   * @param {Record<string, number | string>[]} data
   * @returns
   */
  const formatData = (
    data: Record<string, number | string>[]
  ): JSX.Element[] => {
    return data.map((element, index) => (
      <tr key={index}>{formatRow(element)}</tr>
    ));
  };

  /**
   * Filters table data to get the table headers
   *
   * @param {Record<string, number | string>[]} data
   * @returns {string[]}
   */
  const getHeaders = (data: Record<string, number | string>[]): string[] => {
    return Object.keys(data[0]).filter(
      (key) => key !== "slug" && key !== "engagement_type"
    );
  };

  if (engagements.length === 0) {
    return null;
  }

  return (
    <>
      {title && <Title>{title}</Title>}
      <Table striped bordered interactive className={className}>
        <thead>
          <tr>
            {getHeaders(engagements).map((key, index) => (
              <th key={index}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {formatData(engagements)}
          <tr>
            <StyledSumCell>Total</StyledSumCell>
            <StyledSumCell>{total}</StyledSumCell>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

const StyledSumCell = styled.td`
  border-top: 1px solid #ccc;
`;
