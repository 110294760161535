/* eslint-disable camelcase */
import { merge } from "lodash";
import api from "../../../api";

import Toast from "../../../utils/Toast";
import { removeEmptyProperties } from "@utils/Object";
import Content from "../../../utils/managers/Content";
import ContentType from "../../../enums/managers/ContentType";
import ManagersContentPayloadInterface from "../../../interfaces/managers/ManagersContentPayloadInterface";
import ManagersContentDeletePayloadInterface from "../../../interfaces/managers/ManagersContentDeletePayloadInterface";
import ManagersContentPutCreateInterface from "../../../interfaces/managers/ManagersContentPutCreateInterface";
import ManagersContentCreatePayloadInterface from "../../../interfaces/managers/ManagersContentCreatePayloadInterface";

/**
 * Organisation content
 *
 * @param {{
    page?: number;
    limit?: number;
    type: ContentType;
    isReview?: boolean,
    organisationId?: string;
  }}
 * @return {Promise<ManagersContentInterface>}
 */
export const getContent = ({
  type,
  page = 1,
  limit = 100,
  isReview = false,
  organisationId = "",
}: {
  page?: number;
  limit?: number;
  type: ContentType;
  isReview?: boolean;
  organisationId?: string;
}) =>
  api
    .get(`admin/${type}s`, {
      params: removeEmptyProperties({
        page,
        limit,
        paginate: true,
        order_dir: "DESC",
        is_review: isReview,
        order_col: "posted_at",
        organisation_id: organisationId,
      }),
    })
    .then(
      ({
        data: {
          payload: { articles, videos, podcasts },
        },
      }: ManagersContentPayloadInterface) =>
        Content.normalizeArticles(merge(videos, podcasts, articles))
    )
    .catch((error: any) => Toast.danger({ message: error.message }));

/**
 * Organisation single content
 *
 * @param {string} slug
 * @return {Promise<ManagersContentInterface>}
 */
export const getContentSingle = (slug: string) =>
  api
    .get(`admin/articles/${slug}`)
    .then(
      ({
        data: {
          payload: { article, video, podcast },
        },
      }: ManagersContentPayloadInterface) =>
        Content.normalizeArticle(merge(video, article, podcast))
    )
    .catch(() =>
      Toast.danger({ message: "We could not find what you were looking for" })
    );

/**
 * Create content
 *
 * @param {ContentType} type
 * @param {ManagersContentPutCreateInterface} data
 * @return {Promise<string>}
 */
export const createContent = (
  type: ContentType,
  data: ManagersContentPutCreateInterface
) =>
  api
    .post(`admin/${type}s`, {
      ...data,
      status: data.status.toLowerCase(),
    })
    .then(
      ({
        data: {
          payload: { slug },
        },
      }: ManagersContentCreatePayloadInterface) => {
        Toast.success({ message: "Content was successfully created" });
        return slug;
      }
    )
    .catch((error: any) => Toast.danger({ message: error.message }));

/**
 * Put content
 *
 * @param {ContentType} type
 * @param {string} contentId
 * @param {ManagersContentPutCreateInterface} data
 * @return {Promise<void>}
 */
export const putContent = ({
  type,
  data,
  contentId,
  showToast,
}: {
  type: ContentType;
  contentId: string;
  showToast?: boolean;
  data: ManagersContentPutCreateInterface;
}) =>
  api
    .put(`admin/${type}s/${contentId}`, {
      ...data,
      status: data.status.toLowerCase(),
    })
    .then(() =>
      showToast
        ? Toast.success({ message: "Content was successfully updated" })
        : null
    )
    .catch((error: any) => Toast.danger({ message: error.message }));

/**
 * Delete content
 *
 * @param {string} contentId
 * @return {Promise<number>}
 */
export const deleteContent = (contentId: string) =>
  api
    .delete(`admin/articles/${contentId}`)
    .then(
      ({
        data: {
          payload: { deleted },
        },
      }: ManagersContentDeletePayloadInterface) => deleted
    )
    .catch(() =>
      Toast.danger({ message: "We could not find what you were looking for" })
    );
