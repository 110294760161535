import React from "react";
import styled from "styled-components";

import editImage from "../assets/images/edit.png";

interface Props {
  id?: string;
  onClick: () => unknown;
}

const EditCircle = ({ id, onClick }: Props) => (
  <StyledButton id={id} as="button" type="button" onClick={onClick}>
    <StyledEditImage as="img" src={editImage} alt="Edit" />
  </StyledButton>
);

const StyledButton = styled.button`
  padding: 0;
  border: none;
  cursor: pointer;
  margin: -4px 0 0;

  &:hover {
    opacity: 0.7;
  }
`;

const StyledEditImage = styled.img`
  width: 34px;
  height: 34px;
`;

export default EditCircle;
