import styled from "styled-components";
import React, { useState, useEffect } from "react";

import { ShoutAppModal } from "@components/ShoutAppModal";

interface IShoutAppBubble {
  message: string | string[];
}

export const ShoutAppBubble = ({ message }: IShoutAppBubble) => {
  const [currentMessage, setCurrentMessage] = useState<string>("");
  const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false);

  const messages = typeof message === "object" ? message : [message];

  /**
   * Updates the contents of the bubble
   *
   * @param {boolean} messageIsDisplaying
   * @param {number} index
   */
  const updateBubble = (index: number) => {
    setCurrentMessage(messages[(index + 1) % messages.length]);
    setTimeout(() => updateBubble((index + 1) % messages.length), 4000);
  };

  /**
   * Invoked on bubble click
   */
  const handleClick = () => {
    setIsPromptOpen(true);
  };

  const handleClose = () => {
    setIsPromptOpen(false);
  };

  useEffect(() => {
    setCurrentMessage(messages[0]);
    updateBubble(0);
  }, []);

  return (
    <StyledWrapper>
      <StyledExternalContainer onClick={handleClick}>
        <StyledBubbleText>{currentMessage}</StyledBubbleText>
      </StyledExternalContainer>
      <ShoutAppModal isOpen={isPromptOpen} handleClose={handleClose} />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  right: 0;
  bottom: 0;
  position: sticky;
`;

const StyledExternalContainer = styled.div`
  right: 0;
  bottom: 0;
  height: 56px;
  display: flex;
  padding: 1rem;
  cursor: pointer;
  min-width: 100px;
  margin-right: 5px;
  position: absolute;
  border-radius: 50px;
  align-items: center;
  margin-bottom: 14px;
  justify-content: center;
  background-color: #5f5ced;

  @media (min-width: 1200px) {
    margin-right: calc((var(--containerWidth) - 100vw) / 2);
  }

  &:after {
    width: 0;
    height: 0;
    bottom: 0;
    left: 30%;
    content: "";
    position: absolute;
    border: 9px solid transparent;
    border-top-color: #5f5ced;
    border-bottom: 0;
    border-left: 0;
    margin-left: -3.5px;
    margin-bottom: -9px;
  }
`;

const StyledBubbleText = styled.p`
  margin: 0;
  color: #fff;
  font-weight: 700;
`;
