export function getGermanChannelName(name: string): string {
  const nameLowercase = name.toLowerCase();

  switch (nameLowercase) {
    case "physical health":
      return "Körperliche Gesundheit";
    case "science & technology":
      return "Wissenshaft u. Technologie";
    case "science and technology":
      return "Wissenshaft u. Tech";
    case "food":
      return "Lebensmittel";
    case "travel":
      return "Reisen";
    case "diversity":
      return "Diversität";
    case "mental health":
      return "Psychische Gesundheit";
    case "lgbtq+":
      return "LGBTQ+";
    case "entertainment":
      return "Unterhaltung";
    case "sport":
      return "Sport";
    case "sleep":
      return "Schlaf";
    case "education":
      return "Ausbildung";
    case "work":
      return "Arbiet";
    case "family & relationships":
      return "Familienbeziehungen";
    case "family and relationships":
      return "Familienbeziehungen";
    case "money":
      return "Geld";
    case "opinion":
      return "Meinung";
    case "neurodiversity":
      return "Neurodiversität";
    default:
      return name;
  }
}
