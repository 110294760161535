import React, { PureComponent } from "react";

import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Checkbox, Button, FormGroup } from "@blueprintjs/core";

import Toast from "@utils/Toast";
import FormHeader from "@components/FormHeader";
import PasswordStrength from "../utils/PasswordStrength";
import InputUnderline from "../components/InputUnderline";
import ContainerCentered from "../components/ContainerCentered";
import { signUp, signIn } from "../store/actions/authentication";

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSignUp: (data: any) => dispatch(signUp(data)),
  dispatchSignIn: (data: any) => dispatch(signIn(data)),
});

class SignUpContainer extends PureComponent<any> {
  state = {
    password: "",
    username: "",
    loading: false,
    inviteEmail: "",
    activationCode: "",
    usernameIntent: "",
    passwordIntent: "",
    passwordConfirm: "",
    checkedTerms: false,
    checkedEmails: true,
    checkedPrivacy: false,
    inviteEmailIntent: "",
    passwordHelperText: "",
    usernameHelperText: "",
    activationCodeIntent: "",
    passwordConfirmIntent: "",
    inviteEmailHelperText: "",
    checkedTermsHelperText: "",
    activationCodeHelperText: "",
    checkedPrivacyHelperText: "",
    passwordConfirmHelperText: "",
  };

  componentDidMount() {
    // @ts-ignore
    const { match } = this.props;

    // Invite code passed from emails
    const code = match.params.invite_code;
    if (code) this.setState({ activationCode: code });

    // Email address passed from emails
    const email = match.params.email_address;
    if (email) {
      this.setState({
        inviteEmail: decodeURIComponent(email.replace(/\$/g, ".")),
      });
    }
  }

  attemptSignUp = async () => {
    const {
      username,
      password,
      inviteEmail,
      checkedEmails,
      activationCode,
    } = this.state;

    // @ts-ignore
    const { dispatchSignUp, dispatchSignIn } = this.props;

    await dispatchSignUp({
      password,
      last_name: "",
      gender: "other",
      postcode: "CF312HG",
      first_name: username,
      work_email: inviteEmail,
      invite_code: activationCode,
      personalised_email_optin: checkedEmails,
    }).then((success: any) => {
      if (success === true) {
        setTimeout(() => {
          dispatchSignIn({
            email: inviteEmail,
            password,
            remember_me: true,
          }).then(() => {
            window.location.href = "/";
          });
        }, 3000);
      }

      return false;
    });

    this.setState({ loading: false });
  };

  handleInputChange = (name: string, value: string) => {
    this.setState({ [name]: value });
  };

  handleInputFocus = (name: string) => {
    this.setState({ [`${name}Intent`]: "", [`${name}HelperText`]: "" });
  };

  handleFormSubmit(e: Object) {
    // @ts-ignore
    e.preventDefault();

    const { password, passwordConfirm } = this.state;

    // Set in the order they appear in the form
    const requiredFields = {
      username: "You must provide a username",
      inviteEmail: "You must provide an invitation email",
      activationCode: "You must provide your activation code",
      password: "You must provide a password",
      passwordConfirm: "You must provide a password confirmation",
      checkedTerms:
        "You must accept our terms and conditions in order to register",
      checkedPrivacy: "You must accept our privacy policy in order to register",
    };

    if (
      Object.keys(requiredFields).some((field) => {
        if (!this.state[field]) {
          Toast.danger({ message: requiredFields[field] });
          return true;
        }

        return false;
      })
    ) {
      return;
    }

    if (!PasswordStrength.hasNumber(password)) {
      Toast.danger({ message: "Your password must contain a number" });
      return;
    }
    if (!PasswordStrength.hasSpecial(password)) {
      Toast.danger({
        message:
          "Your password must contain a special characters (e.g #, @ or $)",
      });
      return;
    }
    if (password !== passwordConfirm) {
      Toast.danger({ message: "The passwords you provided did not match" });
      return;
    }

    this.setState({ loading: true }, this.attemptSignUp);
  }

  render() {
    const {
      loading,
      password,
      username,
      inviteEmail,
      checkedTerms,
      checkedEmails,
      usernameIntent,
      passwordIntent,
      checkedPrivacy,
      activationCode,
      passwordConfirm,
      inviteEmailIntent,
      activationCodeIntent,
      passwordConfirmIntent,
    } = this.state;

    const inputProps = {
      onFocus: this.handleInputFocus,
      onChange: this.handleInputChange,
    };

    return (
      <ContainerCentered>
        <Helmet>
          <title>Sign Up - {process.env.REACT_APP_COMPANY_NAME}</title>
        </Helmet>
        <form onSubmit={(event) => this.handleFormSubmit(event)}>
          <FormHeader>Sign up below and we will get you started</FormHeader>

          <InputUnderline
            {...inputProps}
            name="username"
            label="Username"
            defaultValue={username}
            intent={usernameIntent}
            helperText={
              "Please avoid usernames that could be used to personally identify you."
            }
          />
          <InputUnderline
            type="email"
            {...inputProps}
            name="inviteEmail"
            defaultValue={inviteEmail}
            intent={inviteEmailIntent}
            label="Your invite email"
            helperText={
              "This should be an email that your original invite was sent out to."
            }
          />
          <StyledActivationContainer>
            <InputUnderline
              {...inputProps}
              name="activationCode"
              label="Activation code"
              defaultValue={activationCode}
              intent={activationCodeIntent}
            />
            <Link to="/resend-activation">(Re-send code)</Link>
          </StyledActivationContainer>
          <InputUnderline
            type="password"
            {...inputProps}
            name="password"
            defaultValue={password}
            intent={passwordIntent}
            label="Choose a password"
            helperText={
              "Must contain numbers, special characters and a mix of upper/lowercase letters."
            }
          />
          <InputUnderline
            type="password"
            {...inputProps}
            name="passwordConfirm"
            label="Confirm password"
            defaultValue={passwordConfirm}
            intent={passwordConfirmIntent}
          />

          <StyledCheckbox>
            <FormGroup
              labelFor="emails"
              label="Receive weekly emails with useful articles, videos and podcasts (you can always change this later)."
            >
              <Checkbox
                large
                id="emails"
                checked={checkedEmails}
                onChange={() =>
                  this.setState({ checkedEmails: !checkedEmails })
                }
              />
            </FormGroup>
          </StyledCheckbox>

          <StyledCheckbox>
            <FormGroup
              labelFor="terms"
              label={
                <p>
                  You must accept our{" "}
                  <StyledLink target="_blank" to="/terms-and-conditions">
                    terms and conditions.
                  </StyledLink>
                </p>
              }
            >
              <Checkbox
                large
                id="terms"
                checked={checkedTerms}
                onChange={() =>
                  this.setState({
                    checkedTerms: !checkedTerms,
                  })
                }
              />
            </FormGroup>
          </StyledCheckbox>

          <StyledCheckbox>
            <FormGroup
              labelFor="privacy"
              label={
                <p>
                  You must accept our{" "}
                  <StyledLink target="_blank" to="/privacy-policy">
                    privacy policy.
                  </StyledLink>
                </p>
              }
            >
              <Checkbox
                large
                id="privacy"
                checked={checkedPrivacy}
                onChange={() =>
                  this.setState({
                    checkedPrivacy: !checkedPrivacy,
                  })
                }
              />
            </FormGroup>
          </StyledCheckbox>

          <StyledButtonArea>
            <Button
              large
              type="submit"
              intent="primary"
              loading={loading}
              rightIcon="chevron-right"
            >
              Sign Up
            </Button>
          </StyledButtonArea>

          <StyledOptionsTextContainer>
            <StyledOptionsText>
              Already registered?
              <Link to="/login">Login here</Link>
            </StyledOptionsText>
          </StyledOptionsTextContainer>
        </form>
      </ContainerCentered>
    );
  }
}

const StyledOptionsTextContainer = styled.div`
  margin: 3rem 0 0;
`;

const StyledLink = styled(Link)`
  color: var(--brandColorPrimary);
`;

const StyledOptionsText = styled.div`
  display: block;
  font-weight: 300;
  margin: 0.4rem 0 0;

  a {
    display: inline;
    padding: 0 0.3rem;
    color: var(--brandColorPrimary);
  }
`;

const StyledButtonArea = styled.div`
  padding-top: 1.5rem;
`;

const StyledCheckbox = styled.div`
  color: ${(props) => props.color};
  position: relative;
  padding: 0.4rem 0 0 2.2rem;

  .bp3-form-group .bp3-control {
    margin-top: 0.33rem;
  }

  .bp3-checkbox {
    top: -1px;
    left: -1px;
    position: absolute;
  }
`;

const StyledActivationContainer = styled.div`
  position: relative;

  a {
    top: 1px;
    right: 0;
    opacity: 0.5;
    font-size: 0.9rem;
    position: absolute;

    &:hover {
      opacity: 0.7;
      font-size: 0.9rem;
    }
  }
`;

export default connect(null, mapDispatchToProps)(SignUpContainer);
