import api from "../../api";
import Toast from "../../utils/Toast";

/**
 * Update users channels
 * @param {array} array of channels selected
 */
export const channelsUpdate = (channels) => () =>
  api
    .post("users/channels", { channels })
    .then(() =>
      Toast.success({ message: "Your channels were successfully updated" })
    )
    .catch((error) => Toast.danger({ message: error.message }));

/**
 * Update personalised emails
 * @param {boolean} checked should of should not recieve emails
 */
export const personalisedEmailsUpdate = (checked) => () =>
  api
    .post("users/personalised-email-optin", { optin: checked })
    .then(() =>
      Toast.success({
        message: "Your email preferences were successfully updated",
      })
    )
    .catch((error) => Toast.danger({ message: error.message }));

/**
 * Update personalised emails
 * @param {string} email should of should not recieve emails
 */
export const emailUpdate = (email) => () =>
  api
    .post("users/email", { new_email: email })
    .then(() => {
      Toast.success({
        message: "Your login email was successfully updated",
      });

      return email;
    })
    .catch((error) => Toast.danger({ message: error.message }));

/**
 * Update password
 * @param {string} current
 * @param {string} password
 */
export const passwordUpdate = (current, password) => () =>
  api
    .post("users/password", { old_password: current, new_password: password })
    .then(() => {
      Toast.success({
        message: "Your password was successfully updated",
      });

      return true;
    })
    .catch((error) => Toast.danger({ message: error.message }));
