import moment from "moment";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { GENDERS } from "../constants/genders";
import React, { FormEvent, PureComponent } from "react";
import { Checkbox, Button, FormGroup, Intent } from "@blueprintjs/core";

import Toast from "@utils/Toast";
import analytics from "@utils/analytics";
import { Select } from "@components/Select";
import { withRouter } from "react-router-dom";
import DateInput from "@components/DateInput";
import FormHeader from "../components/FormHeader";
import PasswordStrength from "../utils/PasswordStrength";
import InputUnderline from "../components/InputUnderline";
import { EVENT_STUDENT_REGISTER } from "@constants/analytics";
import ContainerCentered from "../components/ContainerCentered";
import { signUpStudent } from "../store/actions/authentication";
import { getCountries, getUniversities } from "../store/actions/student";

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSignUpStudent: (data: any) => dispatch(signUpStudent(data)),
  getUniversityData: () => dispatch(getUniversities()),
  getCountryData: () => dispatch(getCountries()),
});

const mapStateToProps = ({
  universities,
  countries,
  user,
}: IStudentSignUpContainer) => {
  return {
    universities,
    countries,
    user,
  };
};

interface IStudentSignUpContainer {
  universities: string[];
  countries: string[];
  user?: Record<string, any>;
  intents: Record<string, Intent>;
  values: Record<string, any>;
}

class StudentSignUpContainer extends PureComponent<IStudentSignUpContainer> {
  state = {
    loading: false,
    intents: {},
    values: {
      personalizedEmailOptin: true,
    },
    partial: {
      first_name: "",
      last_name: "",
      email: "",
    },
  };

  async componentDidMount() {
    // @ts-ignore
    // eslint-disable-next-line no-shadow
    const { getUniversityData, getCountryData } = this.props;

    await Promise.all([getUniversityData(), getCountryData()]);
    this.setState({ isLoading: false });
  }

  attemptSignUp = async () => {
    const { values } = this.state;

    // @ts-ignore
    const { history, dispatchSignUpStudent } = this.props;

    const success: any = await dispatchSignUpStudent({
      ...values,
      personalized_email_optin: values["personalizedEmailOptin"] ? "1" : "0",
    });

    this.setState({ loading: false });

    if (success === true) {
      analytics.logEvent(EVENT_STUDENT_REGISTER);

      await Swal.fire({
        title: "Verify your email address",
        icon: "success",
        text:
          "Please confirm your email address by clicking the link we've just sent you",
      });

      history.push("/login");
    }
  };

  handleInputChange = (name: string, value: any) => {
    const { values } = this.state;
    this.setState({ values: { ...values, [name]: value } });
  };

  handleSelectChange = (value: string, _name: string, inputName: string) => {
    this.handleInputChange(inputName, value);
  };

  handleDateChange = (name: string, date: Date) => {
    this.handleInputChange(
      name,
      moment(date, "DD-MM-YYYY").format("MM/DD/yyyy")
    );
  };

  handleCheckboxChange = (event: FormEvent<HTMLInputElement>) => {
    const { values } = this.state;

    this.handleInputChange(
      event.currentTarget.name,
      !values[event.currentTarget.name]
    );
  };

  handleInputFocus = (name: string) => {
    this.setState({ [`${name}Intent`]: "", [`${name}HelperText`]: "" });
  };

  handleFormSubmit(e: Object) {
    // @ts-ignore
    e.preventDefault();

    const { values } = this.state;

    // Use the order that the fields appear on the form
    const requiredFields = {
      first_name: "You must provide your first name",
      last_name: "You must provide your last name",
      university: "You must select your university",
      country: "You must select a country",
      student_email: "You must provide your student email",
      address: "You must provide your address",
      postcode: "You must provide your postcode",
      city: "You must provide your city",
      gender: "You must select your gender",
      date_of_birth: "You must provide your date of birth",
      checkedTerms:
        "You must accept our terms and conditions in order to register",
      checkedPrivacy: "You must accept our privacy policy in order to register",
    };

    if (
      Object.keys(requiredFields).some((field) => {
        if (!values[field]) {
          Toast.danger({ message: requiredFields[field] });
          return true;
        }

        return false;
      })
    ) {
      return;
    }

    if (!PasswordStrength.hasNumber(values["password"])) {
      Toast.danger({ message: "Your password must contain a number" });
      return;
    }
    if (!PasswordStrength.hasSpecial(values["password"])) {
      Toast.danger({
        message:
          "Your password must contain a special characters (e.g #, @ or $)",
      });
      return;
    }
    if (values["password"] !== `${values["password"]}`) {
      Toast.danger({ message: "The passwords you provided did not match" });
      return;
    }

    this.setState({ loading: true }, this.attemptSignUp);
  }

  render() {
    const { universities, countries } = this.props;
    const { loading, intents, values } = this.state;

    const inputProps = {
      onFocus: this.handleInputFocus,
      onChange: this.handleInputChange,
    };

    return (
      <ContainerCentered>
        <Helmet>
          <title>Student Sign Up - {process.env.REACT_APP_COMPANY_NAME}</title>
        </Helmet>
        <form onSubmit={(event) => this.handleFormSubmit(event)}>
          <FormHeader>Sign up and we will get you started</FormHeader>

          <div
            className="form-group mb-2"
            style={{ flex: 1, flexDirection: "row", display: "flex" }}
          >
            <div style={{ flex: 1, paddingRight: "2rem" }}>
              <InputUnderline
                {...inputProps}
                name="first_name"
                label="First name"
                intent={intents["first_name"]}
                defaultValue={this.props.user?.first_name}
              />
            </div>
            <div style={{ flex: 1, paddingLeft: "2rem" }}>
              <div style={{ flex: 1 }}>
                <InputUnderline
                  {...inputProps}
                  name="last_name"
                  label="Last name"
                  intent={intents["last_name"]}
                  defaultValue={this.props.user?.last_name}
                />
              </div>
            </div>
          </div>
          <Select
            name="university"
            label="University"
            helperText="The university you're currently attending"
            items={universities}
            placeholder=" "
            onChange={this.handleSelectChange}
          />

          <InputUnderline
            type="email"
            {...inputProps}
            name="student_email"
            intent={intents["student_email"]}
            label="Your university email"
            helperText={
              "Must be an ac.uk email address or registered with UNiDAYS"
            }
            defaultValue={this.props.user?.email}
          />
          <InputUnderline
            type="string"
            {...inputProps}
            name="address"
            intent={intents["address"]}
            label="Address"
          />

          <div
            className="form-group mb-2"
            style={{ flex: 1, flexDirection: "row", display: "flex" }}
          >
            <div style={{ flex: 1, paddingRight: "4rem" }}>
              <InputUnderline
                {...inputProps}
                name="postcode"
                label="Postcode"
                intent={intents["postcode"]}
              />
            </div>
            <div style={{ flex: 1 }}>
              <InputUnderline
                {...inputProps}
                name="city"
                label="City"
                intent={intents["city"]}
              />
            </div>
          </div>

          <Select
            name="country"
            label="Country"
            items={countries}
            placeholder=" "
            onChange={this.handleSelectChange}
          />
          <Select
            name="gender"
            label="Gender"
            items={GENDERS}
            placeholder=" "
            hasSearch={false}
            onChange={this.handleSelectChange}
          />

          <DateInput
            label="Date of birth"
            name="date_of_birth"
            minDate={new Date(new Date().setFullYear(1930))}
            onChange={this.handleDateChange}
          />
          <InputUnderline
            type="password"
            {...inputProps}
            name="password"
            intent={intents["password"]}
            label="Choose a password"
            helperText={
              "Must contain numbers, special characters and a mix of upper/lowercase letters."
            }
          />
          <InputUnderline
            type="password"
            {...inputProps}
            name="passwordConfirm"
            label="Confirm password"
            intent={intents["passwordConfirm"]}
          />

          <StyledCheckbox>
            <FormGroup
              labelFor="emails"
              label="Receive weekly emails with useful articles, videos and podcasts (you can always change this later)."
            >
              <Checkbox
                large
                id="emails"
                name="personalizedEmailOptin"
                checked={!!values["personalizedEmailOptin"]}
                onChange={this.handleCheckboxChange}
              />
            </FormGroup>
          </StyledCheckbox>

          <StyledCheckbox>
            <FormGroup
              labelFor="checkedTerms"
              label={
                <>
                  You must accept our{" "}
                  <StyledLink target="_blank" to="/terms-and-conditions">
                    terms and conditions.
                  </StyledLink>
                </>
              }
            >
              <Checkbox
                large
                name="checkedTerms"
                id="terms"
                checked={!!values["checkedTerms"]}
                onChange={this.handleCheckboxChange}
              />
            </FormGroup>
          </StyledCheckbox>

          <StyledCheckbox>
            <FormGroup
              labelFor="checkedPrivacy"
              label={
                <>
                  You must accept our{" "}
                  <StyledLink target="_blank" to="/privacy-policy">
                    privacy policy.
                  </StyledLink>
                </>
              }
            >
              <Checkbox
                large
                id="privacy"
                name="checkedPrivacy"
                checked={!!values["checkedPrivacy"]}
                onChange={this.handleCheckboxChange}
              />
            </FormGroup>
          </StyledCheckbox>

          <StyledButtonArea>
            <Button
              large
              type="submit"
              intent="primary"
              loading={loading}
              rightIcon="chevron-right"
            >
              Sign Up
            </Button>
          </StyledButtonArea>

          <StyledOptionsTextContainer>
            <StyledOptionsText>
              Already registered?
              <Link to="/login">Login here</Link>
            </StyledOptionsText>
          </StyledOptionsTextContainer>
        </form>
      </ContainerCentered>
    );
  }
}

const StyledOptionsTextContainer = styled.div`
  margin: 3rem 0 0;
`;

const StyledLink = styled(Link)`
  color: var(--brandColorPrimary);
`;

const StyledOptionsText = styled.div`
  display: block;
  font-weight: 300;
  margin: 0.4rem 0 0;

  a {
    display: inline;
    padding: 0 0.3rem;
    color: var(--brandColorPrimary);
  }
`;

const StyledButtonArea = styled.div`
  padding-top: 1.5rem;
`;

const StyledCheckbox = styled.div`
  color: ${(props) => props.color};
  position: relative;
  padding: 0.4rem 0 0 2.2rem;

  .bp3-form-group .bp3-control {
    margin-top: 0.33rem;
  }

  .bp3-checkbox {
    top: -1px;
    left: -1px;
    position: absolute;
  }
`;

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps)(StudentSignUpContainer)
);
