import numeral from "numeral";
import { connect } from "react-redux";
import styled from "styled-components";
import React, { Component } from "react";
import { isEmpty, mapValues, flatMap } from "lodash";
import { PieChart, Pie, Cell, Legend } from "recharts";

import { DEMO_EMAIL } from "@constants/demo";
import Loader from "@components/managers/Loader";
import { DUMMY_ENGAGEMENT } from "@constants/dummy";
import PoseAnimated from "@components/managers/PoseAnimated";
import NoDataMessage from "@components/managers/NoDataMessage";
import PrintableWrapper from "@components/managers/PrintableWrapper";
import ResponsivePieContainer from "@components/managers/ResponsivePieContainer";

import { getEngagement } from "@store/actions/managers";

const mapStateToProps = ({ organisation, channels, user }: any) => ({
  organisation,
  channels,
  user,
});

const mapDispatchToProps = (dispatch: any) => ({
  getEngagement: (organisation_id: any) =>
    dispatch(getEngagement(organisation_id)),
});

class ManagersOverviewEngagementContainer extends Component<any> {
  state = {
    pose: "closed",
    engagement: [],
    isLoading: true,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(nextProps: any) {
    // @ts-ignore
    const { organisation } = this.props;

    if (organisation.selected !== nextProps.organisation.selected) {
      this.getData();
    }
  }

  getData() {
    // @ts-ignore
    const { organisation, getEngagement } = this.props;

    this.setState({ isLoading: true, pose: "closed" });

    // @ts-ignore
    getEngagement(organisation.organisation_id).then((data) => {
      // When logged in as a demo user, show hard coded stats
      const engagement =
        this.props.user.work_email === DEMO_EMAIL ? DUMMY_ENGAGEMENT : data;

      this.setState(
        {
          engagement,
          isLoading: false,
        },
        () => setTimeout(() => this.setState({ pose: "open" }), 500)
      );
    });
  }

  getColorFromKey(name: any) {
    // @ts-ignore
    const { channels } = this.props;

    // @ts-ignore
    const find = channels.filter((channel) =>
      channel.name !== name ? null : channel
    );
    const color = find?.[0]?.color || "dadada";

    return `#${color}`;
  }

  convertToPercent = (item: any) => {
    const { engagement } = this.state;

    const values = flatMap(mapValues(engagement, "value"));
    const formatted = values.map((value) => numeral(value).value());

    const total = formatted.reduce((previous, current) => previous + current);

    return numeral((item / total) * 100).format("0");
  };

  render() {
    // @ts-ignore
    const { organisation } = this.props;
    const { pose, engagement, isLoading } = this.state;

    return isLoading ? (
      <Loader />
    ) : (
      <PrintableWrapper report="Engagement" name={organisation.name}>
        <PoseAnimated pose={pose}>
          {!isLoading &&
            (isEmpty(engagement) ? (
              <NoDataMessage />
            ) : (
              <StyledTitle>
                Content breakdown - % of content clicked on within each channel
                (including articles, videos, podcasts)
              </StyledTitle>
            ))}
          <ResponsivePieContainer>
            <PieChart>
              <Pie
                label={false}
                dataKey="value"
                paddingAngle={0}
                labelLine={false}
                data={engagement}
                innerRadius="60%"
                outerRadius="70%"
              >
                {engagement.map((item: any) => (
                  <Cell
                    key={item.name}
                    fill={this.getColorFromKey(item.name)}
                  />
                ))}
              </Pie>
              <Legend
                height={36}
                wrapperStyle={{
                  position: "initial",
                  marginTop: "-5rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                verticalAlign="bottom"
                payload={engagement.map((item: any) => ({
                  id: item.name,
                  type: "square",
                  value: `${item.name} (${this.convertToPercent(item.value)}%)`,
                  color: this.getColorFromKey(item.name),
                }))}
              />
            </PieChart>
          </ResponsivePieContainer>
        </PoseAnimated>
      </PrintableWrapper>
    );
  }
}

const StyledTitle = styled.p`
  text-align: center;
  margin: 1rem auto -1rem;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagersOverviewEngagementContainer);
