import styled from "styled-components";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { useUser } from "@hooks/useUser";
import { DEMO_EMAIL } from "@constants/demo";
import { getChannelAd } from "@store/actions/ads";
import { Link as NavLink } from "@components/Link";
import { ColorInterface } from "@interfaces/ColorInterface";
import moodbeamAdImage from "@assets/images/ad/moodbeam.jpg";
import smartRecovery from "@assets/images/ad/smartrecovery.png";
import mapMyTracksAdImage from "@assets/images/ad/mapmytracks.png";
import AdvertDailyChallengeInterface from "@interfaces/AdvertDailyChallengeInterface";

interface Props {
  slug: string;
}

export const Advert = ({ slug }: Props) => {
  const { user } = useUser();

  const [ad, setAd] = useState<AdvertDailyChallengeInterface | null>();

  useEffect(() => {
    getChannelAd(slug).then((ad) => ad && setAd(ad));
  }, [slug]);

  // Only the demo user should be able to see adverts for now,
  // but ads for physical_health & should be viewable by everyone.
  if (!["physical_health", "mental_health", "sport"].includes(slug)) {
    if (user.work_email !== DEMO_EMAIL) return null;
  }

  switch (slug) {
    case "work":
      return (
        <NavLink target="_blank" to="https://www.moodbeam.co.uk/">
          <img src={moodbeamAdImage} alt="Moodbeam" />
        </NavLink>
      );
    case "organisation":
      return <img className="mb-4" src={smartRecovery} alt="" />;
    case "family_and_relationships":
      return (
        <NavLink target="_blank" to="https://www.moodbeam.co.uk/">
          <img src={moodbeamAdImage} alt="Moodbeam" />
        </NavLink>
      );
    case "sport":
      return (
        <StyledContainer color="">
          <NavLink
            target="_blank"
            to="https://www.mapmytracks.com/outfront?utm_source=miindset&utm_medium=banner&utm_campaign=step_outdoors"
          >
            <img src={mapMyTracksAdImage} alt="MapMyTracks" />
          </NavLink>
        </StyledContainer>
      );
    default:
      return !ad ? null : (
        <StyledContainer color={ad.colours.primary}>
          <StyledInner>
            {ad.logo_url && (
              <StyledImage src={ad.logo_url} alt={ad.headings.primary} />
            )}
            <StyledTitle color={ad.colours.text}>
              {ad.headings.primary}
            </StyledTitle>
            {ad.headings.secondary && (
              <StyledTitle color={ad.colours.secondary} className="second">
                {ad.headings.secondary}
              </StyledTitle>
            )}
            <StyledBlurb color={ad.colours.text}>{ad.blurb}</StyledBlurb>
          </StyledInner>
          {ad.buttons.banner && (
            <StyledFooter color={ad.colours.secondary}>
              <StyledLink
                color={ad.colours.primary}
                to={`/daily-challenge/${slug}`}
              >
                {ad.buttons.banner}
              </StyledLink>
            </StyledFooter>
          )}
        </StyledContainer>
      );
  }
};

const StyledContainer = styled.div<ColorInterface>`
  color: white;
  margin-bottom: 2rem;
  text-transform: uppercase;
  background-color: ${({ color }) => color};
`;

const StyledInner = styled.div`
  padding: 1.2rem;
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: 70%;
  max-height: 100px;
  margin-bottom: 1.2rem;
`;

const StyledTitle = styled.div<ColorInterface>`
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.2rem;
  color: ${({ color }) => color};
  &.second {
    font-size: 1.4rem;
  }
`;

const StyledBlurb = styled.p<ColorInterface>`
  font-size: 1rem;
  line-height: 1.4;
  white-space: pre-wrap;
  margin: 1rem 0 0.5rem;
  text-transform: initial;
  color: ${({ color }) => color};
`;

const StyledFooter = styled.div<ColorInterface>`
  display: flex;
  padding: 0.5rem;
  background-color: ${({ color }) => color};
`;

const StyledLink = styled(Link)<ColorInterface>`
  color: white;
  line-height: 1;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.1rem;
  margin-left: auto;
  padding: 0.8rem 0.8rem 0.5rem;
  background-color: ${({ color }) => color};
  &:hover {
    color: white;
    opacity: 0.9;
    font-size: 1.1rem;
    text-decoration: none;
  }
`;
