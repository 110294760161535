import { connect } from "react-redux";
import debounce from "lodash/debounce";
import styled from "styled-components";
import React, { Component } from "react";
import { InputGroup } from "@blueprintjs/core";

import SearchResult from "./SearchResult";
import searchIcon from "../../assets/images/search.png";

import { performSearch, clearSearch } from "../../store/actions/search";

const mapDispatchToProps = (dispatch: any) => ({
  dispatchClearSearch: () => dispatch(clearSearch()),
  dispatchPerformSearch: (term: any) => dispatch(performSearch(term)),
});

const mapStateToProps = ({ search }: any) => ({
  search,
});

interface Props {
  search: any;
  dispatchClearSearch: () => {};
  dispatchPerformSearch: (search: string) => any;
}

class Search extends Component<Props> {
  state = {
    term: "",
    open: false,
    loading: false,
  };

  searchDebounce = debounce(() => this.attemptSearch(), 500);

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setReference = (node: any) => {
    // @ts-ignore
    this.ref = node;
  };

  handleClickOutside = (event: Object) => {
    // @ts-ignore
    if (this.ref && !this.ref.contains(event.target)) {
      this.handleCloseSearchResults();
    }
  };

  handleClickSearchResult = () => {
    const { dispatchClearSearch } = this.props;

    this.handleCloseSearchResults();
    this.setState({ term: "" });
    dispatchClearSearch();
  };

  handleCloseSearchResults() {
    this.setState({ open: false });
  }

  handleInputFocus() {
    const { term } = this.state;

    if (term !== "") {
      this.setState({ open: true });
    }
  }

  attemptSearch() {
    const { term } = this.state;
    const { dispatchPerformSearch } = this.props;

    if (term === "") return;

    this.setState({ loading: true, open: true });

    dispatchPerformSearch(term).then(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    const { search } = this.props;
    const { term, loading, open } = this.state;

    const searchKeys = Object.keys(search);

    return (
      <StyledSearchContainer ref={this.setReference}>
        <StyledSearchBar>
          <InputGroup
            type="text"
            value={term}
            placeholder="Suche"
            className="ml-a mt-2 mb-2"
            style={{ borderRadius: 0 }}
            onFocus={() => this.handleInputFocus()}
            onChange={(e: any) =>
              this.setState({ term: e.target.value }, this.searchDebounce)
            }
          />
        </StyledSearchBar>
        {!loading && open && (
          <StyledResultsContainer>
            <StyledResultDetails>
              We found
              <span>{searchKeys.length}</span>
              result
              {searchKeys.length === 1 ? "" : "s"} matching your search
            </StyledResultDetails>
            <StyledResultsOverflow>
              {searchKeys.map((key) => (
                <SearchResult
                  key={key}
                  result={search[key]}
                  onClickSearchResult={() => this.handleClickSearchResult()}
                />
              ))}
            </StyledResultsOverflow>
          </StyledResultsContainer>
        )}
      </StyledSearchContainer>
    );
  }
}

const StyledSearchContainer = styled.div`
  flex-grow: 1;

  @media (min-width: ${({ theme }) => theme.breakpointExtraSmall}) {
    position: relative;
  }
`;

const StyledSearchBar = styled.div`
  .bp3-input-group {
    width: 252px;
    max-width: 100%;
    position: relative;

    > input {
      height: 32px;
      border: none;
      outline: none;
      box-shadow: none;
      padding-right: 3rem;
      background-size: 19px;
      padding: 0.15rem 0.8rem 0;
      background-repeat: no-repeat;
      border-radius: 1px !important;
      background-position: 96% 6.5px;
      background-image: url(${searchIcon});
    }

    @media (max-width: ${({ theme }) => theme.breakpointExtraSmall}) {
      width: 85%;
    }
  }
`;

const StyledResultsContainer = styled.div`
  right: 0;
  top: 48px;
  z-index: 2;
  width: 100%;
  padding: 1rem 0 0;
  position: absolute;
  background-color: white;
  color: var(--textColor);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
`;

const StyledResultDetails = styled.p`
  margin: 0.2rem 1rem 1rem;

  span {
    font-weight: 600;
    padding: 0 0.2rem;
  }
`;

const StyledResultsOverflow = styled.div`
  overflow: scroll;
  max-height: 400px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(Search);
