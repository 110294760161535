/* eslint radix: 0 */
import { isEmpty } from "lodash";

export default class LoginCounter {
  /**
   * Current count
   * @type {integer}
   */
  count;

  /**
   * localStorage key
   * @type {string}
   */
  key = "login_count";

  constructor() {
    this.count = 0;

    if (!this.hasLocalStorage()) {
      this.createFirstInstance();
    } else {
      this.count = this.getCurrentCount();
    }
  }

  /**
   * Has user ID been set previously
   *
   * @return {boolean}
   */
  hasLocalStorage = () => !isEmpty(localStorage.getItem(this.key));

  /**
   * Create initial storage instance
   * @return {Object}
   */
  createFirstInstance = () => localStorage.setItem(this.key, 1);

  /**
   * Has user ID been set previously
   *
   * @return {integer}
   */
  getCurrentCount = () => parseInt(localStorage.getItem(this.key));

  /**
   * Append successful login count
   *
   * @return {void}
   */
  add = async () => {
    localStorage.setItem(this.key, parseInt(this.count + 1));
  };
}
