import styled from "styled-components";
import React, { PureComponent } from "react";

interface Props {
  type: string;
  title: string;
}

export default class SearchResultTitle extends PureComponent<Props> {
  render() {
    const { title, type } = this.props;

    return (
      <StyledResultTitleContainer>
        {title}
        <StyledResultChannel>{type}</StyledResultChannel>
      </StyledResultTitleContainer>
    );
  }
}

const StyledResultTitleContainer = styled.h4`
  margin: 0 0 0 1rem;
`;

const StyledResultChannel = styled.span`
  opacity: 0.5;
  display: block;
  font-weight: 300;
  font-size: 0.8rem;
  padding: 0.3rem 0 0;
  text-transform: uppercase;
`;
