import SVG from "react-inlinesvg";
import styled from "styled-components";
import React, { Component } from "react";

import loader from "../assets/images/loader.svg";

interface Props {
  delay?: number;
  removeAfter?: number;
}

export default class Loader extends Component<Props> {
  timeout: any = null;
  timeoutRemoval: any = null;

  state = {
    loading: true,
    display: true,
  };

  static defaultProps = {
    delay: 500,
    removeAfter: 5000,
  };

  UNSAFE_componentWillMount() {
    clearTimeout(this.timeout);
    clearTimeout(this.timeoutRemoval);
  }

  componentDidMount() {
    const { delay, removeAfter } = this.props;

    this.timeout = setTimeout(() => this.setState({ loading: false }), delay);
    this.timeoutRemoval = setTimeout(
      () => this.setState({ display: false }),
      removeAfter
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.timeoutRemoval);
  }

  render() {
    const { loading, display } = this.state;

    return (
      // @ts-ignore
      !loading && display && <StyledLoader {...this.props} src={loader} />
    );
  }
}

const StyledLoader = styled(SVG)`
  height: 100%;
  display: flex;
  margin: 3rem 0 2rem;

  > svg {
    margin: auto;
    height: auto;
    display: block;
    stroke: var(--brandColorPrimary);
  }
`;
