import showdown from "showdown";
import ReactQuill from "react-quill";
import styled from "styled-components";
import { Dialog, Button, Intent } from "@blueprintjs/core";
import React, { useEffect, useState, useRef } from "react";
import "react-quill/dist/quill.snow.css";

import ImageUploader from "@components/managers/ImageUploader";

interface Props {
  value?: string;
  name?: string;
  defaultValue?: string;
  /** Needed for waiting on API call before rendering */
  isNewContent?: boolean;
  onChange?: (markup: string) => void;
}

const MarkdownEditor = ({
  name,
  value,
  onChange,
  isNewContent,
  defaultValue,
}: Props) => {
  const [modules, setModules] = useState({});
  const [markdown, setMarkdown] = useState("");
  const [showImageUploader, setShowImageUploader] = useState(false);

  const quillRef = useRef(null) as any;

  useEffect(() => {
    /**
     * Modules must be saved to state instead of in the render, otherwise React quill will break.
     */
    setModules({
      history: {
        delay: 2500,
        userOnly: true,
      },
      toolbar: {
        container: [
          { header: 1 },
          { header: 2 },
          "bold",
          "italic",
          "underline",
          { list: "ordered" },
          { list: "bullet" },
          "clean",
          "link",
          "image",
        ],
        handlers: {
          image: toggleImageUploader,
        },
      },
    });
  }, []);

  /**
   * Toggles the image uploader dialog
   */
  function toggleImageUploader() {
    setShowImageUploader(!showImageUploader);
  }

  /**
   * Trigger the quill onchange callback.
   *
   * @param {string} html
   */
  function handleOnChange(html: string) {
    const markdown = new showdown.Converter({
      simpleLineBreaks: false,
    }).makeMarkdown(html);

    setMarkdown(
      // Remove double line breaks for single
      markdown
        .replace(/<br>/g, "")
        .replace(/<!-- -->/g, "")
        .replace(/\n\s*\n\s*\n/g, "\n\n")
    );

    onChange && onChange(markdown);
  }

  function handleImageChange(cloudinaryImageID: string) {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection() || 0;

    editor.insertEmbed(
      range.index,
      "image",
      `${process.env.REACT_APP_CLOUDINARY_URL}${cloudinaryImageID}`,
      "user"
    );

    setShowImageUploader(false);
  }

  const defaultMarkdownValue = new showdown.Converter({
    simpleLineBreaks: true,
  }).makeHtml(value || defaultValue);

  return (
    <>
      {Boolean(defaultMarkdownValue || isNewContent || modules) && (
        <>
          <StyledReactQuill
            ref={quillRef}
            modules={modules}
            key={defaultValue}
            onChange={handleOnChange}
            defaultValue={defaultMarkdownValue}
            formats={[
              "bold",
              "italic",
              "link",
              "underline",
              "header",
              "list",
              "image",
            ]}
          />
          <Dialog isOpen={showImageUploader} onClose={toggleImageUploader}>
            <div className="py-4 px-4">
              <h4 className="mt-0 mb-4">Upload Images</h4>
              <ImageUploader showPreview={false} onChange={handleImageChange} />
              <Button
                className="mt-4"
                intent={Intent.PRIMARY}
                onClick={toggleImageUploader}
              >
                Close
              </Button>
            </div>
          </Dialog>

          {name && (
            <textarea
              rows={15}
              name={name}
              hidden={true}
              value={markdown || defaultValue}
            />
          )}
        </>
      )}
    </>
  );
};

const StyledReactQuill = styled(ReactQuill)`
  /** 
  * When switching between types this section is 
  * removed and the save buttons are pushed up
  * the padding ensures there is no jump
  */
  padding-top: 8px;

  &&& {
    .ql-editor {
      padding: 25px;
      font-size: 1rem;
      font-weight: 300;
      min-height: 270px;
      line-height: 1.5rem;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;

      a {
        text-decoration: none;
        color: var(--brandColorPrimary);

        &:hover {
          color: var(--textColor);
          text-decoration: underline;
        }
      }

      @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
        min-height: 100px;
      }
    }

    .ql-toolbar.ql-snow {
      padding: 8px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border-color: var(--brandColorPrimary);
      background-color: var(--brandColorPrimary);
    }

    .ql-snow .ql-stroke {
      stroke-width: 1;
    }

    .ql-snow.ql-toolbar {
      .ql-even,
      .ql-picker,
      .ql-stroke {
        color: white;
        stroke: white;
      }

      .ql-fill {
        fill: white;
      }
    }

    .ql-snow .ql-tooltip {
      border-radius: 3px;
    }

    .ql-snow .ql-tooltip,
    .ql-container.ql-snow {
      border-color: var(--borderColor);
    }

    .ql-snow.ql-toolbar button:hover,
    .ql-snow .ql-toolbar button:hover,
    .ql-snow.ql-toolbar button:focus,
    .ql-snow .ql-toolbar button:focus,
    .ql-snow.ql-toolbar button.ql-active,
    .ql-snow .ql-toolbar button.ql-active,
    .ql-snow.ql-toolbar .ql-picker-label:hover,
    .ql-snow .ql-toolbar .ql-picker-label:hover,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
      color: white;
    }

    .ql-snow.ql-toolbar button {
      width: 30px;
      height: 28px;
      padding: 3px 4px;
    }

    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
      border-color: transparent;
    }

    .ql-picker-options {
      color: var(--textColor);

      .ql-picker-item:hover {
        color: var(--textColor2);
      }
    }

    .ql-snow .ql-picker-label {
      font-weight: 400;
    }

    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
      border-radius: 3px;
      border-color: var(--borderColor);
    }
  }
`;

export default MarkdownEditor;
