/* eslint react/prop-types: 0, react/destructuring-assignment: 0 */
import numeral from "numeral";
import { isEmpty, keys } from "lodash";
import styled from "styled-components";
import React, { Component } from "react";

import { AnswersHighlightInterface } from "@interfaces/AnswersHighlightInterface";

interface Props {
  value?: any;
  title?: any;
  answers?: any;
  question?: any;
  percentageTotal?: any;
}

export default class HighlightBox extends Component<Props> {
  getValueString() {
    // @ts-ignore
    const { value } = this.props;

    let string = "";
    if (value >= 0 && value <= 10) string = "Rarely";
    if (value > 10 && value <= 25) string = "Not Often";
    if (value > 25 && value <= 50) string = "Sometimes";
    if (value > 50 && value <= 75) string = "Quite Often";
    if (value > 75) string = "Very Often";

    return string;
  }

  getPercentage = (value: any) => {
    // @ts-ignore
    const { percentageTotal } = this.props;

    const percentage = (value / percentageTotal) * 100;
    return `${numeral(percentage).format("0")}%`;
  };

  /**
   * Sorts the array of answers so that the largest percentages are first
   *
   * @param {any} answers
   */
  formatAnswers = (answers: any): AnswersHighlightInterface[] => {
    const formedAnswers: AnswersHighlightInterface[] = [];

    keys(answers).forEach((key) => {
      formedAnswers.push({ key: key, percentage: answers[key] });
    });

    return formedAnswers.sort(
      (a: AnswersHighlightInterface, b: AnswersHighlightInterface) => {
        return a.percentage > b.percentage ? -1 : 1;
      }
    );
  };

  render() {
    // @ts-ignore
    const { title, value, answers, question } = this.props;
    const formedAnswers = this.formatAnswers(answers);

    return (
      <StyledContainer className="highlight-box">
        {title && <StyledTitle>{title}</StyledTitle>}
        {question && <StyledQuestion>{question}</StyledQuestion>}
        {value && <StyledValue>{this.getValueString()}</StyledValue>}

        {!isEmpty(answers) && (
          <StyledAnswers>
            <StyledQuestion7>What’s on your mind?</StyledQuestion7>
            Out of all the users who answered this question, % of users clicked
            this answer
            <StyledAnswersGrid
              rows={String(Math.ceil(formedAnswers.length / 3))}
            >
              {formedAnswers.map((answer) => (
                <StyledAnswer key={answer.key}>
                  {answer.key
                    .replace(/_/g, " ")
                    .replace("coworkers", "co-workers")}
                  <StyledAnswerValue>
                    {this.getPercentage(answer.percentage)}
                  </StyledAnswerValue>
                </StyledAnswer>
              ))}
            </StyledAnswersGrid>
          </StyledAnswers>
        )}
      </StyledContainer>
    );
  }
}

const StyledContainer = styled.div`
  height: 100%;
  display: block;
  text-align: center;
  border-radius: 2px;
  padding: 2rem 1.5rem;
  background-color: var(--managerPlaceholder);
`;

const StyledTitle = styled.div`
  margin: 0;
  font-size: 2.2rem;
  font-weight: 600;
  color: var(--brandColorPrimary);
`;

const StyledQuestion = styled.div`
  font-size: 1rem;
  max-width: 260px;
  text-align: center;
  margin: 0.4rem auto;
  line-height: 1.4rem;
`;

const StyledValue = styled.div`
  font-weight: 700;
  margin-top: 0.8rem;
  color: var(--brandColorPrimary);
`;

const StyledAnswers = styled.div`
  text-align: center;
  margin: 0.4rem auto;
`;

const StyledAnswersGrid = styled.div<{ rows: string }>`
  display: grid;
  grid-gap: 1rem 3rem;
  margin: 2rem 0.5rem 0;
  grid-auto-flow: column;
  grid-template-rows: repeat(${(props) => props.rows}, 1fr);
`;

const StyledAnswer = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: capitalize;
`;

const StyledQuestion7 = styled.div`
  padding-bottom: 0.3rem;
`;

const StyledAnswerValue = styled.div`
  margin-left: auto;
  color: var(--brandColorPrimary);
`;
