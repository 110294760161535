import styled from "styled-components";
import React, { Fragment, useEffect } from "react";

interface Props {
  as?: any;
  max?: number;
  min?: number;
  children: React.ReactNode;
}

/**
 * Conditionally render child components
 * based on the specified breakpoint.
 */
export const Breakpoint = ({ as = "span", min, max, children }: Props) => {
  useEffect(() => {
    if (max && min) {
      console.error("Breakpoint: Only min or max may be specified, not both");
    }
    // eslint-disable-next-line
  }, []);

  if (max) {
    return (
      <StyledGreaterThan as={as} breakpoint={max}>
        {children}
      </StyledGreaterThan>
    );
  }

  if (min) {
    return (
      <StyledLessThan as={as} breakpoint={min}>
        {children}
      </StyledLessThan>
    );
  }

  return <Fragment />;
};

const StyledGreaterThan = styled.span`
  @media (max-width: ${(props: { breakpoint: number; as: string }) =>
      props.breakpoint}px) {
    display: none;
  }
`;

const StyledLessThan = styled.span`
  @media (min-width: ${(props: { breakpoint: number; as: string }) =>
      props.breakpoint}px) {
    display: none;
  }
`;
