export const SET_COUNTRIES = "SET_COUNTRIES";

const initialState = [];

const countries = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRIES:
      return [...action.payload];
    default:
      return state;
  }
};

export default countries;
