import React from "react";
import styled from "styled-components";

import { generateUnidaysLink } from "@utils/generateUnidaysLink";
import UnidaysLogo from "../assets/images/unidays-login-transparent.svg";

export const UnidaysLoginButton = () => {
  return (
    <StyledContainer>
      <a href={generateUnidaysLink()}>
        <StyledUnidaysImg src={UnidaysLogo} />
      </a>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-left: auto;
`;

const StyledUnidaysImg = styled.img`
  height: 40px;
  margin: 0 0.95rem;
`;
