import React from "react";
import styled from "styled-components";
import { Image } from "cloudinary-react";
import { useSelector } from "react-redux";

import Channels from "../utils/Channels";
import { getGermanChannelName } from "@utils/german";

interface Props {
  slug?: string;
  name?: string;
  fontSize?: string;
  organisation?: any;
}

const ChannelCard = ({ slug, name, fontSize }: Props) => {
  // eslint-disable-next-line no-shadow
  const { organisation } = useSelector(({ organisation }: Props) => ({
    organisation,
  }));

  return (
    <StyledChannelCardContainer>
      <StyledChannelLine color={Channels.getColorByKey(slug || "")} />
      {slug ? (
        <>
          {slug === "organisation" && (
            <StyledCloudinaryImage
              cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
              publicId={organisation.image_id}
            />
          )}
          {slug !== "organisation" && (
            <StyledChannelImage src={Channels.getImageByKey(slug)} />
          )}
        </>
      ) : (
        <StyledChannelImageBlank />
      )}
      <StyledChannelTitle
        style={{ fontSize: fontSize ? fontSize : "" }}
        as="h3"
      >
        {getGermanChannelName(name)}
      </StyledChannelTitle>
    </StyledChannelCardContainer>
  );
};

const StyledChannelCardContainer = styled.div`
  display: grid;
  grid-template-columns: 2px 50px auto;
`;

const StyledChannelLine = styled.div<{ color: string }>`
  width: 4px;
  height: 32px;
  display: block;
  background-color: ${({ color }) =>
    color && color ? color : "var(--placeholderImageBackground)"};
`;

const StyledChannelImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 1px;
  margin: 0 0.8rem 0 0.3rem;
`;

const StyledCloudinaryImage = styled(Image)`
  width: 32px;
  height: 32px;
  border-radius: 1px;
  margin: 0 0.8rem 0 0.3rem;
`;

const StyledChannelImageBlank = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 1px;
  margin: 0 0.8rem 0 0.3rem;
  background-color: var(--placeholderImageBackground);
`;

const StyledChannelTitle = styled.h3`
  margin: 0.4rem 0 0;
  text-transform: capitalize;
`;

export default ChannelCard;
