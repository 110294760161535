import moment from "moment";
import { startCase } from "lodash";
import styled from "styled-components";
import React, { useState } from "react";
import { useAsyncEffect } from "use-async-effect";

import { Grid } from "@components/Grid";
import { numberFormat } from "@utils/number";
import { DataTable } from "@components/DataTable";
import Wrapper from "@components/managers/Wrapper";
import { getManagersStudentData } from "@store/actions/managers/students";
import { IManagersStudentsData } from "@interfaces/managers/IManagersStudentsData";

export default function ManagersStudentsContainer(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [studentsData, setStudentData] = useState<IManagersStudentsData>();

  useAsyncEffect(async () => {
    const data = await getManagersStudentData();

    if (data) {
      setStudentData(data);
      setIsLoading(false);
    }
  }, []);

  return (
    <Wrapper>
      <Grid columns={"1fr 1fr 1fr"} gap="1rem" className="pt-4">
        {[
          {
            value: studentsData?.overview.total_students_email_confirmed,
            title: `Students (${numberFormat(
              studentsData?.overview.total_students -
                studentsData?.overview.total_students_email_confirmed || 0
            )} unconfirmed)`,
          },
          {
            title: "Total Assessments Completed",
            value: studentsData?.overview.assessments_completed,
          },
          {
            title: "Average Assessments Per Student",
            value: studentsData?.overview.average_assessments_per_student,
          },
        ].map(({ title, value }, key) => (
          <StyledStat key={key}>
            {title}
            <StyledStatValue>{numberFormat(value || 0)}</StyledStatValue>
          </StyledStat>
        ))}
      </Grid>
      <Grid columns={"1fr 1fr"} gap="1rem" className="pt-4">
        <DataTable
          isLoading={isLoading}
          data={studentsData?.universities?.map((channels) => {
            return {
              ...channels,
              total_students: numberFormat(channels.total_students),
            };
          })}
          columns={[
            { key: "university" },
            { key: "total_students", width: "100px", heading: "Students" },
          ]}
        />
        <DataTable
          isLoading={isLoading}
          data={studentsData?.channels
            ?.map((channels) => {
              return {
                ...channels,
                total_students: numberFormat(channels.total_students),
              };
            })
            .sort((a, b) => {
              return a.total_students > b.total_students ? -1 : 1;
            })}
          columns={[
            { key: "channel" },
            {
              width: "100px",
              heading: "Students",
              key: "total_students",
            },
          ]}
        />
      </Grid>
      <DataTable
        className="pt-4"
        isLoading={isLoading}
        data={studentsData?.students?.map((student) => {
          return {
            ...student,
            channels: student.channels
              ?.map((channel) => startCase(channel))
              ?.join(", "),
            created_at: moment(student.created_at).format("h:mma Do MMM"),
          };
        })}
        columns={[
          { key: "first_name" },
          { key: "email" },
          { key: "city" },
          { key: "university" },
          { key: "channels", width: "170px" },
          { key: "created_at", width: "170px", heading: "Registered On" },
        ]}
      />
    </Wrapper>
  );
}

const StyledStat = styled.div`
  width: 100%;
  border-radius: 2px;
  text-align: center;
  padding: 2rem 1.5rem;
  background-color: var(--managerPlaceholder);
`;

const StyledStatValue = styled.div`
  font-size: 1.8rem;
  margin-top: 0.5rem;
  color: var(--brandColorPrimary);
`;
