import axios, { AxiosError, AxiosResponse } from "axios";

import ManagersCloudinaryPayloadInterface from "../../../interfaces/managers/ManagersCloudinaryPayloadInterface";

/**
* Upload cloudinary image

* Returns a public_id from cloudinary if successful
*
* @param {File|string} file
* @return {Promise<string>}
*/
export const upload = async (
  file: File | string
): Promise<ManagersCloudinaryPayloadInterface> => {
  /** Custom axios instance needed for cloudinary */
  delete axios.defaults.headers;

  axios.interceptors.response.use(
    async (response: AxiosResponse): Promise<AxiosResponse<any>> => response,
    async (error: AxiosError): Promise<AxiosResponse<any>> => {
      /** Using reject enables then/catch on ~400 */
      if (error.response && error.response.status === 400) {
        return Promise.reject(error);
      }

      return axios(error.config);
    }
  );

  /** Create request object */
  const data = new FormData();

  data.append("file", file);
  data.append("upload_preset", "shbmikn7");

  const response = await axios.post<ManagersCloudinaryPayloadInterface>(
    `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response.data;
};
