import React from "react";
import styled from "styled-components";

const NoDataMessage = () => (
  <StyledContainer>
    The system hasn’t collated enough data yet to populate this section, please
    come back later.
  </StyledContainer>
);

const StyledContainer = styled.div`
  font-weight: 600;
  margin: 4rem 1rem;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    margin: 2rem 1rem;
  }
`;

export default NoDataMessage;
