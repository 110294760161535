import moment from "moment";
import { useSelector } from "react-redux";
import useReactRouter from "use-react-router";
import React, { useState, useEffect } from "react";
import { startCase, truncate, isEmpty } from "lodash";

import {
  Table,
  TableDate,
  TableImage,
  TableNoRows,
  TableAbstract,
  TableCellStretch,
} from "@components/Table";
import Loader from "@components/Loader";
import ContentType from "@enums/managers/ContentType";
import ContentTablePagination from "./ContentTablePagination";
import ManagersContentInterface from "@interfaces/managers/ManagersContentInterface";

import { getContent } from "@store/actions/managers/content";

interface Props {
  type: ContentType;
  organisation?: any;
}

const ContentTable = ({ type }: Props) => {
  const { history } = useReactRouter();

  // eslint-disable-next-line no-shadow
  const { organisation } = useSelector(({ organisation }: Props) => ({
    organisation,
  }));

  const limit = 8;
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState<ManagersContentInterface[]>([]);

  let columns: string[] = ["Title", "Status", "Date Added"];

  /** Videos do not have image column */
  if (type !== ContentType.video) {
    columns = [...[""], ...columns];
  }

  useEffect(() => {
    getContent({
      type,
      page,
      limit,
      organisationId: organisation.organisation_id,
    }).then((item) => {
      setIsLoading(false);

      if (item) {
        setContent(item);
      }
    });

    return () => {
      /** Clear data on unmount */
      setContent([]);
      setIsLoading(true);
    };
  }, [type, organisation, page]);

  if (isLoading) return <Loader />;

  return (
    <>
      <Table striped bordered interactive={Boolean(content.length)}>
        <thead>
          <tr>
            {columns.map((column, key: number) => (
              <th
                // eslint-disable-next-line react/no-array-index-key
                key={key}
                style={
                  ["Status", "Date Added"].includes(column)
                    ? { textAlign: "center" }
                    : {}
                }
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isEmpty(content) ? (
            <tr>
              <TableNoRows colSpan={5}>Nothing to display</TableNoRows>
            </tr>
          ) : (
            content.map((item, key: number) => (
              <tr
                // eslint-disable-next-line react/no-array-index-key
                key={key}
                onClick={() =>
                  history.push(`/managers/content/edit/${item.slug}`)
                }
              >
                {type !== ContentType.video && (
                  <td>
                    <TableImage
                      cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
                      publicId={item.image_id}
                    />
                  </td>
                )}
                <TableCellStretch>
                  {item.headline}
                  <TableAbstract>
                    {truncate(item.abstract, {
                      length: 70,
                      separator: /,? +/,
                    })}
                  </TableAbstract>
                </TableCellStretch>
                <TableDate>
                  {item.publish_at
                    ? moment(item.publish_at).format("Do MMM")
                    : startCase(item.status)}
                </TableDate>
                <TableDate>
                  {moment(item.posted_at).format("Do MMM YYYY")}
                </TableDate>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <ContentTablePagination
        currentPage={page}
        isFinalPage={content.length < limit}
        onChange={(newPage: number) => setPage(newPage < 1 ? 0 : newPage)}
      />
    </>
  );
};

export default ContentTable;
