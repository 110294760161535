import firebase from "firebase/app";
import "firebase/analytics";
import { FIREBASE_CONFIG } from "../constants/analytics";

firebase.initializeApp(FIREBASE_CONFIG);
const analytics = firebase.analytics();
analytics.setAnalyticsCollectionEnabled(
  !process.env.NODE_ENV || process.env.NODE_ENV !== "development"
);

export default analytics;
