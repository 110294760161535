import React, { useState } from "react";
import Select from "react-multi-select-component";
import styled from "styled-components";

interface Props {
  name: string;
  className?: string;
  options: { label: string; value: string }[];
  defaultValue: { label: string; value: string }[];
}

export const MultiSelect = ({
  className,
  name,
  options = [],
  defaultValue = [],
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState(defaultValue);

  return (
    <StyledMultiSelect>
      <Select
        options={options}
        hasSelectAll={false}
        labelledBy={"Select"}
        className={className}
        value={selectedOptions}
        onChange={(selectedOptions) => setSelectedOptions(selectedOptions)}
      />
      {selectedOptions.map((selectedOption) => (
        <input
          type="hidden"
          name={`${name}[]`}
          key={selectedOption.value}
          value={selectedOption.value}
        />
      ))}
    </StyledMultiSelect>
  );
};

const StyledMultiSelect = styled.div`
  &&& {
    .dropdown-heading {
      height: auto;
      align-items: start;
      padding: 0.5rem 1rem;
    }

    .dropdown-heading-value {
      overflow: visible;
      padding-top: 0.5rem;
      white-space: initial;
    }

    input[type="checkbox"] {
      width: initial;
      -webkit-appearance: checkbox;
    }
  }
`;
