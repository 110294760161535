/* eslint no-nested-ternary: 0, react/prop-types: 0, react/prefer-stateless-function: 0 */
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import styled from "styled-components";
import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";

import Loader from "@components/managers/Loader";
import GenerateReport from "@components/managers/GenerateReport";
import OrganisationSwitcher from "@components/managers/OrganisationSwitcher";

import { UserRole } from "@enums/UserRole";
import { UserInterface } from "@interfaces/UserInterface";

const mapStateToProps = ({ user, organisation, organisations }: $TSFixMe) => ({
  user,
  organisation,
  organisations,
});

interface Props {
  user: UserInterface;
}

class Wrapper extends Component<Props> {
  render() {
    // @ts-ignore
    const { organisation, organisations, user, children, match } = this.props;

    /**
     * Determines manager vs staff account since a
     * manager will only have one organisation
     */
    const isManager =
      user.role === UserRole.manager && isEmpty(organisations.length === 1);

    const isStaff = user.role === UserRole.staff;

    return (
      <StyledContainer>
        <StyledHeading>
          <StyledTitle>
            <StyledLink to="/managers/overview">Overview</StyledLink>
            <StyledLink to="/managers/invitations">Invitations</StyledLink>
            {isManager && (
              <StyledLink to="/managers/content/article">Content</StyledLink>
            )}
            {isStaff && (
              <>
                <StyledLink to="/managers/content/review">Review</StyledLink>
                <StyledLink to="/managers/students">Students</StyledLink>
              </>
            )}
          </StyledTitle>
          <StyledUser>
            <StyledName>
              {user.first_name && `Hi, ${user.first_name}.`}
            </StyledName>
            <OrganisationSwitcher />
          </StyledUser>
        </StyledHeading>

        {match.path === "/managers/overview" && <GenerateReport />}

        {isEmpty(organisation) ? (
          <Loader delay={500} />
        ) : organisation.organisation_id ? (
          children
        ) : (
          <StyledNoOrganisation>
            It seems you are not currently part of an active organisation.
          </StyledNoOrganisation>
        )}
      </StyledContainer>
    );
  }
}

const StyledContainer = styled.div`
  margin: 0 0 5rem;
  min-height: 850px;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    min-height: auto;
  }
`;

const StyledHeading = styled.div`
  padding: 0;
  display: flex;
  margin: 0 0 1rem;
  flex-direction: row;
  border-bottom: 1px solid var(--borderColor);

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    margin-top: 1.5rem;
  }
`;

const StyledLink = styled(NavLink)`
  margin-right: 1.4rem;
  display: inline-block;
  padding-bottom: 0.5rem;
  color: var(--textColor2);

  &.active {
    border-bottom: 2px solid var(--brandColorPrimary);
  }

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    margin-right: 0.8rem;
  }
`;

const StyledTitle = styled.h1`
  margin: 0;
  font-size: 1.2rem;
`;

const StyledUser = styled.div`
  display: flex;
  font-weight: 300;
  font-size: 1.2rem;
  flex-direction: row;
  align-items: center;
  margin: -8px 0 0 auto;
  color: var(--textColor2);
`;

const StyledName = styled.span`
  font-size: 1.2rem;

  @media (max-width: ${({ theme }) => theme.breakpointSmall}) {
    display: none;
  }
`;

const StyledNoOrganisation = styled.div`
  display: block;
  padding: 0 2rem;
  font-weight: 300;
  max-width: 680px;
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.6rem;
  margin: 4rem auto 2rem;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    margin-top: 2rem;
  }
`;

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, null)(Wrapper)
);
