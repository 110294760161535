import { useEffect } from "react";
import { EVENT_PAGE_VIEW } from "@constants/analytics";
import analytics from "@utils/analytics";
import { useLocation } from "react-router-dom";

export function Analytics() {
  const location = useLocation();
  useEffect(() => {
    /**
     * When {@link location} is changed document.title is not updated until
     * <Helmet> renders.
     * An arbitrary delay has been added to make sure document.title is not one
     * page behind the current page.
     *
     * {@link https://github.com/nfl/react-helmet/issues/189}
     */
    setTimeout(() => {
      const path = location.pathname + location.search;
      if (document.title === process.env.REACT_APP_COMPANY_NAME) return;

      analytics.setCurrentScreen(path);
      analytics.logEvent(EVENT_PAGE_VIEW, {
        page_path: path,
        page_title: document.title,
      });
    }, 1000);
  }, [location]);

  return null;
}
