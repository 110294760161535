import moment from "moment";
import numeral from "numeral";
import { connect } from "react-redux";
import styled from "styled-components";
import React, { Component, Fragment } from "react";
import { flatMap, mapValues, toLower } from "lodash";

import {
  Pie,
  Line,
  Cell,
  Label,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  PieChart,
  LineChart,
  ResponsiveContainer,
} from "recharts";

import { DEMO_EMAIL } from "@constants/demo";
import Loader from "@components/managers/Loader";
import { DUMMY_INDICATORS } from "@constants/dummy";
import { getIndicators } from "@store/actions/managers";
import PoseAnimated from "@components/managers/PoseAnimated";
import NoDataMessage from "@components/managers/NoDataMessage";
import PrintableWrapper from "@components/managers/PrintableWrapper";
import { TopEngagementsTable } from "@components/managers/TopEngagementsTable";
import ResponsivePieContainer from "@components/managers/ResponsivePieContainer";

const mapStateToProps = ({ organisation, channels, user }: any) => ({
  organisation,
  channels,
  user,
});

const mapDispatchToProps = (dispatch: any) => ({
  getIndicators: (organisation_id: any) =>
    dispatch(getIndicators(organisation_id)),
});

class ManagersOverviewIndicatorsContainer extends Component<any> {
  state = {
    pose: "closed",
    isLoading: true,
    indicators: [] as $TSFixMe,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(nextProps: any) {
    // @ts-ignore
    const { organisation } = this.props;

    if (organisation.selected !== nextProps.organisation.selected) {
      this.getData();
    }
  }

  getData() {
    // @ts-ignore
    const { organisation, getIndicators } = this.props;

    this.setState({ isLoading: true, pose: "closed" });
    getIndicators(organisation.organisation_id).then((data: any) => {
      // When logged in as a demo user, show hard coded stats
      const indicators =
        this.props.user.work_email === DEMO_EMAIL ? DUMMY_INDICATORS : data;

      this.setState(
        {
          indicators,
          isLoading: false,
        },
        () => setTimeout(() => this.setState({ pose: "open" }), 1)
      );
    });
  }

  shouldDisplay = () => {
    const { indicators } = this.state;

    if (indicators.graph) {
      /** Totals values from each date key */
      const values = indicators.graph.map((row) =>
        Number(row.stress + row.anxiety + row.depression)
      );

      const summed = values.reduce((previous, current) => previous + current);

      if (summed > 0) return true;
    }

    return false;
  };

  shouldDisplayPieChart = () => {
    const { indicators } = this.state;

    if (indicators.pie) {
      /** Totals values from each pie key */
      const values = flatMap(mapValues(indicators.pie, "value"));
      const formatted = values.map((value) => numeral(value).value());
      const summed = formatted.reduce(
        (previous, current) => previous + current
      );

      if (summed > 0) return true;
    }

    return false;
  };

  render() {
    // @ts-ignore
    const { organisation } = this.props;
    const { pose, indicators, isLoading } = this.state;

    const colors = {
      stress: "#9eb931",
      anxiety: "#bc2a35",
      depression: "#375bac",
    };

    const display = this.shouldDisplay();
    const displayPieChart = this.shouldDisplayPieChart();

    const formatDate = (date) => {
      return moment(date, "DD-MM-YYYY").format("MMM");
    };

    return isLoading ? (
      <Loader />
    ) : (
      <PrintableWrapper report="Indicators" name={organisation.name}>
        <PoseAnimated pose={pose}>
          {!isLoading &&
            (!display ? (
              <NoDataMessage />
            ) : (
              <Fragment>
                <StyledHeading>
                  Critical Indicators
                  <StyledHeadingDescription>
                    Changes within the organisation for stress, anxiety &
                    depression
                  </StyledHeadingDescription>
                </StyledHeading>

                <StyledResponsiveContainer height={240} width="95%">
                  <LineChart height={240} data={indicators.graph}>
                    <XAxis
                      tickMargin={14}
                      dataKey="date"
                      tickFormatter={formatDate}
                    />
                    <YAxis>
                      <Label
                        angle={-90}
                        position="insideLeft"
                        className="y-axis-label"
                        value="Avg. wellbeing score"
                        style={{ textAnchor: "middle" }}
                      />
                    </YAxis>
                    <Tooltip />
                    {["stress", "anxiety", "depression"].map((key) => (
                      <Line
                        key={key}
                        dot={false}
                        dataKey={key}
                        strokeWidth={3}
                        type="monotone"
                        stroke={colors[key]}
                        isAnimationActive={false}
                      />
                    ))}
                  </LineChart>
                </StyledResponsiveContainer>

                <Fragment>
                  {displayPieChart && (
                    <StyledHeading>
                      Total Organisation Percentage
                      <StyledHeadingDescription>
                        Proportional breakdown of the stress, anxiety and
                        depression levels within your organisation in the last
                        30 days
                      </StyledHeadingDescription>
                    </StyledHeading>
                  )}
                  <ResponsivePieContainer
                    className={!displayPieChart ? "legend" : "small"}
                  >
                    <PieChart>
                      <Pie
                        label={false}
                        nameKey="name"
                        dataKey="value"
                        paddingAngle={0}
                        innerRadius="60%"
                        outerRadius="70%"
                        isAnimationActive={false}
                        // @ts-ignore
                        data={indicators.pie.map((item: any) => {
                          item.value = Math.floor(numeral(item.value).value());

                          return item;
                        })}
                      >
                        {
                          // @todo fix this
                          // @ts-ignore
                          indicators.pie.map((item: any) => (
                            <Cell
                              key={item.name}
                              // @ts-ignore
                              fill={colors[toLower(item.name)]}
                            />
                          ))
                        }
                      </Pie>
                      <Legend
                        height={36}
                        verticalAlign="bottom"
                        // @ts-ignore
                        payload={indicators.pie.map((item: any) => ({
                          id: item.name,
                          type: "square",
                          // @ts-ignore
                          color: colors[toLower(item.name)],
                          /** If pie chart is not displayed then don't show percnetages */
                          value: displayPieChart
                            ? `${item.name} (${item.value}%)`
                            : `${item.name} (%)`,
                        }))}
                      />
                    </PieChart>
                  </ResponsivePieContainer>
                </Fragment>
              </Fragment>
            ))}
          <Fragment>
            {indicators.top_engagements?.articles && (
              <StyledTopEngagementsTable
                type="article"
                title="Top Articles by Read Count"
                total={indicators.top_engagements.articles_sum}
                engagements={indicators.top_engagements.articles}
              />
            )}
            {indicators.top_engagements?.videos && (
              <StyledTopEngagementsTable
                type="video"
                title="Top Videos by View Count"
                total={indicators.top_engagements.videos_sum}
                engagements={indicators.top_engagements.videos}
              />
            )}
            {indicators.top_engagements?.podcasts && (
              <StyledTopEngagementsTable
                type="podcast"
                title="Top Podcasts by Listen Count"
                total={indicators.top_engagements.podcasts_sum}
                engagements={indicators.top_engagements.podcasts}
              />
            )}
          </Fragment>
        </PoseAnimated>
      </PrintableWrapper>
    );
  }
}

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  max-width: 900px;
  margin: 2rem auto 3rem;

  .y-axis-label {
    opacity: 0.6;
  }
`;

const StyledHeading = styled.div`
  margin: 0 0 1rem;
  font-size: 1.4rem;
  text-align: center;
`;

const StyledHeadingDescription = styled.div`
  font-size: 1rem;
  margin: 0.8rem 0 0;
`;

const StyledTopEngagementsTable = styled(TopEngagementsTable)`
  margin-bottom: 3rem;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagersOverviewIndicatorsContainer);
