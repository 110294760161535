import React from "react";
import styled from "styled-components";

interface Props {
  as?: any;
  /**
   * The max number of lines that should be displayed
   */
  lines?: number;
  className?: string;
  children?: React.ReactNode;
}

export const TruncatedText = ({ as, lines, children, className }: Props) => {
  return (
    <StyledTruncatedText as={as} lines={lines} className={className}>
      {children}
    </StyledTruncatedText>
  );
};

const StyledTruncatedText = styled.p<{ lines: number }>`
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => (props.lines ? props.lines : "initial")};
`;
