import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Link } from "react-router-dom";
import React, { PureComponent } from "react";
import logo from "@assets/images/logo-pink.png";

import { Button } from "@blueprintjs/core";

export default class WelcomeContainer extends PureComponent {
  render() {
    return (
      <StyledWelcomeContainer>
        <Helmet>
          <title>Welcome - {process.env.REACT_APP_COMPANY_NAME}</title>
        </Helmet>
        <StyledHeading className="text-center">
          <div>
            Welcome to
            <img
              alt=""
              src={logo}
              style={{
                display: "inline-block",
                height: "33px",
                marginLeft: "8px",
                marginBottom: "-6px",
              }}
            />
          </div>
          <div>Supporting your mental wellbeing</div>
        </StyledHeading>
        <div className="ml-a mr-a" style={{ width: "650px", maxWidth: "100%" }}>
          <StyledParagraph>
            Read, listen and watch engaging content specifically curated for
            your current mindset. Improve your wellbeing and strengthen your
            mental health resilience.
          </StyledParagraph>
          <StyledParagraph>
            With built in tools to help support every aspect of your life from
            fitness, healthy eating to sleep, work and mindfulness.
          </StyledParagraph>
          <StyledParagraph>
            Take the wellness assessment and start your Miindset journey.
          </StyledParagraph>
          <StyledButtonContainer>
            <Link to="/login">
              <Button
                large
                type="submit"
                intent="primary"
                rightIcon="chevron-right"
              >
                Sign in
              </Button>
            </Link>
          </StyledButtonContainer>
        </div>
      </StyledWelcomeContainer>
    );
  }
}

const StyledWelcomeContainer = styled.div`
  padding: 6% 0 15%;
  text-align: center;
`;

const StyledHeading = styled.h1`
  @media (max-width: ${({ theme }) => theme.breakpointSmall}) {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
`;

const StyledParagraph = styled.p`
  font-size: 1.1rem;
  margin: 0.5rem auto 1rem;
`;

const StyledButtonContainer = styled.div`
  margin: 2.5rem 0 0;
`;
