import { clone } from "lodash";

/**
 * Remove empty properties from ab object
 *
 * @param {T} object
 * @returns {T}
 */
export const removeEmptyProperties = <T extends object>(object: T): T => {
  const clonedObject = clone(object);

  Object.entries(object).forEach(([key, value]) => {
    if (!value) {
      delete clonedObject[key];
    }
  });

  return clonedObject;
};
