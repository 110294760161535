import styled from "styled-components";
import React, { ReactNode } from "react";
import { FormGroup as BpFormGroup, Intent } from "@blueprintjs/core";

interface IFormGroup {
  label: string;
  intent?: Intent;
  labelFor?: string;
  className?: string;
  children: ReactNode;
  helperText?: string;
}

const FormGroup = ({
  label,
  labelFor,
  className,
  helperText,
  children,
  intent = Intent.NONE,
}: IFormGroup) => {
  return (
    <StyledContainer>
      <BpFormGroup
        label={label}
        intent={intent}
        labelFor={labelFor}
        className={className}
        helperText={helperText}
      >
        {children}
      </BpFormGroup>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  .bp3-form-group .bp3-form-helper-text {
    font-weight: 300;
    font-size: 0.9rem;
    margin: 0 0 0.8rem;
    color: var(--managersMutedText);
  }

  label.bp3-label {
    font-size: 1rem;
    font-weight: 400;
    color: var(--brandColorPrimary);
  }

  .bp3-form-group label.bp3-label {
    font-weight: 600;
    margin-bottom: 0.2rem;
  }
`;

export default FormGroup;
