import { Component } from "react";
import { withRouter } from "react-router-dom";

interface Props {
  children: any;
  location: string;
}

class ScrollToTop extends Component<Props> {
  componentDidUpdate(prevProps: any) {
    const { location } = this.props;

    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

// @ts-ignore
export default withRouter(ScrollToTop);
