import api from "../../api";

import { AdvertDailyChallengeInterface } from "@interfaces/AdvertDailyChallengeInterface";
import { AdvertDailyChallengeExpandedInterface } from "@interfaces/AdvertDailyChallengeExpandedInterface";

/**
 * Get channel ad
 *
 * @param {string} channel
 * @return {Promise<ChannelAdInterface | void>}
 */
export const getChannelAd = (
  channel: string
): Promise<AdvertDailyChallengeInterface | void> =>
  api
    .get(`ads/${channel}`)
    .then(
      (response) => response.data.payload.ad as AdvertDailyChallengeInterface
    )
    /** @todo Endpoint returns 404 which should be changed, this is a workaround for making sure the toast errors are not shown */
    .catch(() => {
      //
    });

/**
 * Get channel ad based off id
 *
 * @param {string} adId
 * @return {Promise<ChannelAdInterface | void>}
 */
export const getChannelAdSingle = (
  adId: string
): Promise<AdvertDailyChallengeExpandedInterface | void> =>
  api
    .get(`ads/page/${adId}`)
    .then(
      (response) =>
        response.data.payload.ad as AdvertDailyChallengeExpandedInterface
    )
    /** @todo Endpoint returns 404 which should be changed, this is a workaround for making sure the toast errors are not shown */
    .catch(() => {
      //
    });
