import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";

import LoginContainer from "../containers/LoginContainer";
import WelcomeContainer from "../containers/WelcomeContainer";

const mapStateToProps = ({ user }: any) => ({
  user,
});

class PrivateRoute extends Component<any> {
  render() {
    // @ts-ignore
    const { user } = this.props;

    // Replace the homepage with the welcome page
    // if the user is not logged in and tries to visit the homepage
    if (!user.isAuthenticated && window.location.pathname === "/") {
      return <WelcomeContainer {...this.props} />;
    }

    // Display the login form when a user is not logged in
    // and tries to access a restricted page
    if (!user.isAuthenticated) {
      return <LoginContainer fromRedirection />;
    }

    return <Route {...this.props} />;
  }
}

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, {})(PrivateRoute)
);
