import React, { PureComponent } from "react";
import styled from "styled-components";

import Loader from "./Loader";

export default class NetworkErrorMessage extends PureComponent {
  state = {
    display: false,
    timeout: null,
  };

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    const { timeout } = this.state;

    // @ts-ignore
    clearTimeout(timeout);
  }

  startTimer = () => {
    const timeout = setTimeout(() => {
      this.setState({ display: true });

      /** Clear out local storage */
      localStorage.clear();
    }, 5000);

    this.setState({ timeout });
  };

  render() {
    const { display } = this.state;

    return (
      <StyledErrorContainer>
        {!display ? (
          <Loader delay={800} />
        ) : (
          "We appear to be experiencing a temporary network outage, please come back in a couple of minutes. Apologies for any inconvenience caused."
        )}
      </StyledErrorContainer>
    );
  }
}

const StyledErrorContainer = styled.div`
  display: block;
  padding: 0 2rem;
  font-weight: 600;
  max-width: 720px;
  font-size: 1.2rem;
  margin: 4% auto 10%;
  text-align: center;
  line-height: 1.8rem;
`;
