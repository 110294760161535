import React from "react";

export interface IFormObject {
  [key: string]: FormDataEntryValue | FormDataEntryValue[] | File | object;
}

export interface IFormatArgs {
  key: string;
  object: IFormObject;
  value: FormDataEntryValue;
}

export interface IFormSubmit {
  onSubmit: (form: IFormObject, event?: React.FormEvent<Element>) => void;
}

/**
 * Transforms FormData into a object
 *
 * @param  {EventTarget} form
 * @return {object}
 */
export const formDataToObject = (form: EventTarget): IFormObject => {
  const object: IFormObject = {};
  const fields = formDataToArray(form);

  fields.forEach(([key, value]) => {
    if (formatArrayField({ key, value, object })) return;
    if (formatObjectValue({ key, value, object })) return;

    // It's a normal input just set the value normally
    object[key] = value;
  });

  return object;
};

/**
 * Transforms FormData into a object
 *
 * @param  {EventTarget} form
 * @return {[string, FormDataEntryValue][]}
 */
const formDataToArray = (form: EventTarget): [string, FormDataEntryValue][] => {
  const formData: FormData = new FormData(form as HTMLFormElement);
  // @ts-ignore
  return [...formData.entries()];
};

/**
 * Convert field value to an array
 * and add it to the main form object.
 *
 * This will work for inferred arrays eg. `keywords[]`
 *
 * @param   {IFormatArgs}
 * @returns {boolean}
 */
const formatArrayField = ({ key, value, object }: IFormatArgs): boolean => {
  if (!key.endsWith("[]")) {
    return false;
  }

  const arrayKey = key.replace("[]", "");

  const arrayValue = (object[arrayKey] || []) as any;

  // @ts-ignore
  object[arrayKey] = [...arrayValue, value];

  return true;
};

/**
 * Convert field value to an object value
 * and add it to the main form object.
 *
 * This will work for named arrays eg. `keywords[my_keyword]`
 *
 * @param   {IFormatArgs}
 * @returns {boolean}
 */
const formatObjectValue = ({ key, value, object }: IFormatArgs): boolean => {
  const matches = key.match(/\[(.*?)\]/);

  if (!matches) {
    return false;
  }

  const [matchWithBrackets, match] = matches;
  const arrayKey = key.replace(matchWithBrackets, "");
  const existingValue = object[arrayKey];
  const arrayValue = {
    [match]: value,
  };

  object[arrayKey] =
    typeof existingValue === "object"
      ? { ...existingValue, ...arrayValue }
      : arrayValue;

  return true;
};

interface Props extends IFormSubmit {
  id?: string;
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const Form = ({
  style,
  children,
  onSubmit,
  className,
}: Props): JSX.Element => {
  /**
   * Handle form submission
   *
   * @param {React.FormEvent} event
   * @return {void}
   */
  function handleSubmit(event: React.FormEvent): void {
    event?.preventDefault();
    event?.stopPropagation();

    const data = formDataToObject(event?.target);
    onSubmit(data, event);
  }

  return (
    <form style={style} className={className} onSubmit={handleSubmit}>
      {children}
    </form>
  );
};
