import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { CHANNEL_ACRONYMS } from "@constants/channels";
import { isEmpty, snakeCase, upperCase, startCase } from "lodash";

const ContentTopics = (props: any) =>
  isEmpty(props.tags) ? null : (
    <StyledTags>
      <StyledHeading>Topics:</StyledHeading>
      {props.tags.map((tag: string) => (
        <StyledLink key={tag} to={`/topic/${snakeCase(tag)}`}>
          {CHANNEL_ACRONYMS.includes(tag.toLowerCase())
            ? upperCase(tag)
            : startCase(tag)}
        </StyledLink>
      ))}
    </StyledTags>
  );

const StyledHeading = styled.div`
  color: #b1b4b5;
  display: block;
  padding: 0 0 0.7rem;
`;

const StyledLink = styled(Link)`
  position: relative;
  padding: 0 0 0.5rem;
  display: inline-block;
  color: var(--brandColorPrimary);

  &:after {
    content: "/";
    color: #b1b4b5;
    padding: 0 0.5rem;
  }

  &:hover {
    &::after {
      color: #b1b4b5;
    }
  }
`;

const StyledTags = styled.div`
  margin-top: 1rem;
  display: inline-block;
  text-decoration: none;

  ${StyledLink}:last-child {
    &::after {
      content: ""; /** Remove final slash */
    }
  }
`;

export default ContentTopics;
