import user from "./user";
import search from "./search";
import channels from "./channels";
import countries from "./countries";
import processing from "./processing";
import universities from "./universities";
import organisation from "./organisation";
import organisations from "./organisations";

export default {
  user,
  search,
  channels,
  countries,
  processing,
  universities,
  organisation,
  organisations,
};
