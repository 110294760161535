import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

interface Props {
  content: string;
}

const ContentArticle = ({ content }: Props) => (
  <StyledContentArticle>
    <ReactMarkdown source={content.replace(/<br>/g, "")} />
  </StyledContentArticle>
);

const StyledContentArticle = styled.div`
  display: block;
  margin: 2rem 0 1rem;
  white-space: "pre-wrap";

  a {
    color: var(--brandColorPrimary);
  }
`;

export default ContentArticle;
