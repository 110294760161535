/**
 * Convert numbers into a formated string
 * e.g 10000, "10,000"
 *
 * @todo check if this method and Price.format can be merged eventually
 * @param  {string | number} value
 * @param  {object} options
 * @returns {string}
 */
export function numberFormat(
  value: string | number,
  options: object = {
    style: "decimal",
    minimumFractionDigits: 0,
  }
): string {
  const formatter = new Intl.NumberFormat("en", options);

  // Convert strings to numbers (even if they have special characters)
  const number = Number(typeof value === "string" ? toNumber(value) : value);

  // Fix the decimals to two places (if they are needed)
  const numberWithFixedDecimals = Number(
    number % 1 !== 0 ? number.toFixed(2) : number
  );

  return formatter.format(numberWithFixedDecimals);
}

/**
 * Convert strings into numbers
 * e.g "10,000" to 10000
 *
 * @param  {string} text
 * @returns {number}
 */
export function toNumber(text: string): number {
  return text ? Number(text.replace(/\D/g, "")) : 0;
}

/**
 * Format decimal numbers
 *
 * @param {string} value
 * @return {string}
 */
export function decimalFormat(value: string | number): string {
  if (typeof value === "number") {
    value.toFixed(2);
  }

  return Number(value).toFixed(2);
}

/**
 * Converts long numbers into shorthand
 *
 * @param {number} number number to shorten.
 * @param {number} digits The number of digits to appear after the decimal point.
 * @returns {string|number}
 */
export function getShorthandNumber(
  number: number,
  digits: number = 1
): number | string {
  const units = ["k", "M", "G", "T", "P", "E", "Z", "Y"];
  let decimal;

  for (let i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1);

    if (number <= -decimal || number >= decimal) {
      return +(number / decimal).toFixed(digits) + units[i];
    }
  }

  return number;
}
