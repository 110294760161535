/* eslint-disable new-cap */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import jsPDF from "jspdf";
import moment from "moment";
import { isEmpty } from "lodash";
import html2canvas from "html2canvas";
import styled from "styled-components";
import React, { Component } from "react";
import { IconNames } from "@blueprintjs/icons";
import { Alert, Icon, Intent } from "@blueprintjs/core";

import pinkLogo from "../../assets/images/logo-pink.png";

export default class GenerateReport extends Component {
  state = {
    alertText: "",
    alertShow: false,
  };

  /**
   * Was unable to location printable wrapper
   * on screen so displays the user a warning
   *
   * @return {void}
   */
  printableElementNotFound = () =>
    this.setState({
      alertShow: true,
      alertText:
        "Unfortunately we are unable to print this page right now, please try again later.",
    });

  /**
   * Begin generating the report
   *
   * @param {HTMLElement} printable
   * @return {void}
   */
  generateReport = (printable: any) => {
    const pdf = new jsPDF("l", "mm", "a4");

    /** Delay to allow the alert to appear because building canvas */
    setTimeout(() => {
      html2canvas(printable).then((canvas) => {
        /** Page settings */
        pdf.setFontSize(10);
        pdf.setTextColor(161, 168, 173);

        /** Append canvas "screenshot" */
        const bleed = 60;
        const width = pdf.internal.pageSize.width - bleed;

        pdf.addImage(
          canvas.toDataURL("image/png", 100),
          "PNG",
          bleed / 2,
          32,
          width,
          width * (canvas.height / canvas.width)
        );

        /** App left hand logo */
        pdf.addImage(pinkLogo, "PNG", 8, 8, 45, 8.4);

        pdf.text(8, 22, "Supporting your mental wellbeing");

        /** Bottom text */
        pdf.text(266, 202, moment().format("Do MMM YYYY"));
        pdf.text(
          8,
          202,
          `${printable.getAttribute("data-name")} - ${printable.getAttribute(
            "data-report"
          )} Report`
        );

        /** Force downloads the PDF */
        pdf.save("Report.pdf");
      });
    }, 1000);
  };

  /**
   * On click of the download button
   *
   * @return {void}
   */
  handleClick = () => {
    /** Specifically looks for the printable wrapper */
    const printable = document.getElementById("printable");

    if (isEmpty(printable)) {
      this.printableElementNotFound();
    } else {
      this.setState(
        {
          alertShow: true,
          alertText:
            "We are currently processing your download, please be patient this may take a minute.",
        },
        () => this.generateReport(printable)
      );
    }
  };

  render() {
    const { alertShow, alertText } = this.state;

    return (
      <>
        <StyledPrintButton onClick={this.handleClick}>
          Download
          <StyledIcon icon="print" iconSize={18} />
        </StyledPrintButton>

        <Alert
          isOpen={alertShow}
          icon={IconNames.PRINT}
          intent={Intent.PRIMARY}
          confirmButtonText="Close"
          onConfirm={() => this.setState({ alertShow: false })}
        >
          {alertText}
        </Alert>
      </>
    );
  }
}

const StyledPrintButton = styled.div`
  float: right;
  cursor: pointer;
  font-size: 1rem;
  padding: 6px 0 0;
  color: var(--textColor2);

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    display: none;
  }
`;

const StyledIcon = styled(Icon)`
  padding-left: 0.8rem;
`;
