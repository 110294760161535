import moment from "moment";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { Redirect } from "react-router";
import React, { Component } from "react";

import api from "@api";
import Toast from "@utils/Toast";
import Content from "@utils/Content";
import Image from "@components/Image";
import Loader from "@components/Loader";
import { Title } from "@components/Title";
import Sidebar from "@components/Sidebar";
import ChannelLinks from "@components/ChannelLinks";
import ContentBlock from "@components/ContentBlock";
import ContentTopics from "@components/ContentTopics";
import ContentArticle from "@components/ContentArticle";
import SponsoredLegal from "@components/SponsoredLegal";

export default class ArticleContainer extends Component<any> {
  state = {
    slug: "",
    display: false,
    redirect: false,
    article: {
      date: "",
      type: "",
      content: "",
      video: null,
      abstract: "",
      headline: "",
      channels: [],
      isPartnerContent: false,
    },
    videos: [],
    podcasts: [],
    articles: [],
  };

  componentDidMount = () => {
    this.setCurrentArticle().then(() => {
      /** Get related content */
      Content.get({
        limit: 4,
        // eslint-disable-next-line react/destructuring-assignment
      }).then((data: any) =>
        this.setState({
          videos: data.videos,
          podcasts: data.podcasts,
          articles: data.articles,
        })
      );
    });
  };

  setCurrentArticle = async () => {
    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    const { match } = this.props;

    Content.getSingle({
      type: match.params.type,
      channel: match.params.slug,
    })
      .then((article: any) => {
        this.setState({
          article,
          display: true,
          slug: match.params.slug,
        });

        if (article.video) {
          // register engagement
          api.post(
            `/employee/content/engagement/${this.state.article.type}/${this.state.slug}`
          );
        }
      })
      .catch(() => {
        this.setState({ redirect: true }, () => {
          Toast.danger({
            message: "The content you were looking for could not be found",
          });
        });
      });
  };

  render() {
    const {
      videos,
      display,
      article,
      redirect,
      articles,
      podcasts,
    } = this.state;

    if (redirect) return <Redirect to="/" />;
    if (!display) return <Loader delay={500} />;

    return (
      <StyledArticleContainer>
        <Helmet>
          <title>{`${article.headline} - ${process.env.REACT_APP_COMPANY_NAME}`}</title>
        </Helmet>

        <StyledArticleContent className="mr-8">
          <Title>{article.headline}</Title>

          <p>{article.abstract}</p>

          <StyledMeta>
            <div>
              <ChannelLinks channels={article.channels} />
            </div>
          </StyledMeta>

          {
            // @ts-ignore
            article.video ? (
              <ReactPlayer
                className="mt-4"
                width="100%"
                playing={true}
                controls={true}
                // @ts-ignore
                url={article.video}
              />
            ) : (
              <Image
                className="mt-4 w-100"
                // @ts-ignore
                src={article.image}
                alt={article.headline}
              />
            )
          }

          <ContentArticle content={article.content} />

          {article.isPartnerContent && <SponsoredLegal />}

          <ContentTopics
            tags={
              // @ts-ignore
              article.topics
            }
          />
        </StyledArticleContent>

        <Sidebar>
          <ContentBlock
            columns={2}
            className="mb-4"
            // @ts-ignore
            articles={articles}
            title="More Articles"
          />
          <ContentBlock
            columns={2}
            hideChannels={true}
            hideAbstract={true}
            className="mb-4"
            // @ts-ignore
            articles={videos}
            title="More Videos"
          />
          <ContentBlock
            columns={2}
            hideChannels={true}
            hideAbstract={true}
            className="mb-4"
            // @ts-ignore
            articles={podcasts}
            title="More Podcasts"
          />
        </Sidebar>
      </StyledArticleContainer>
    );
  }
}

const StyledArticleContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    display: flex;
  }

  padding-bottom: 2rem;
`;

const StyledArticleContent = styled.div`
  flex-grow: 1;
`;

const StyledMeta = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto 1fr;
`;
