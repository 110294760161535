import React from "react";
import styled from "styled-components";
import { Button, Intent } from "@blueprintjs/core";

interface Props {
  currentPage: number;
  isFinalPage: boolean;
  onChange: (page: number) => void;
}

enum Directions {
  next = "Next",
  prev = "Prev",
}

const ContentTablePagination = ({
  currentPage,
  isFinalPage,
  onChange,
}: Props) => (
  <StyledContainer>
    {[Directions.prev, Directions.next].map((direction, key: number) => {
      const disabled =
        (currentPage === 1 && direction === Directions.prev) ||
        (isFinalPage && direction === Directions.next);

      return (
        <Button
          key={key}
          large={false}
          disabled={disabled}
          onClick={() =>
            onChange(
              direction === Directions.next ? currentPage + 1 : currentPage - 1
            )
          }
          intent={disabled ? Intent.NONE : Intent.PRIMARY}
        >
          {direction}
        </Button>
      );
    })}
  </StyledContainer>
);

const StyledContainer = styled.div`
  width: 122px;
  float: right;
  display: flex;
  margin: 1rem 0;
  justify-content: space-between;
`;

export default ContentTablePagination;
