import moment from "moment";
import Swal from "sweetalert2";
import ContentStatus from "@enums/managers/ContentStatus";

/**
 * Prompt the user for a rejection reason
 *
 * @returns {string}
 */
export async function promptForRejectionReason(): Promise<string> {
  const { value } = await Swal.fire<string>({
    title: "",
    text: "Please specify a rejection reason",
    input: "text",
    showCancelButton: true,
  });

  return value;
}

/**
 * Prompt the user if they are sure they wish to continue
 * if the post is approved and  the status is still a draft
 *
 * @param {ContentStatus} status
 * @returns {boolean}
 */
export async function promptIsAcceptedPostStatusCorrect(
  status: ContentStatus
): Promise<boolean> {
  if (status === ContentStatus.draft) {
    const { value } = await Swal.fire<boolean>({
      title: "",
      icon: "info",
      confirmButtonText: "Yes",
      html:
        'You are approving an article but the status is still "draft".<br/> Is this correct?',
      showCancelButton: true,
    });

    return value;
  }

  return true;
}

/**
 * Certain values must be the correct type or the api will fail validation
 * The get and put endpoints have no consistency so there are quite a few
 * tricks required to get them to match up.
 *
 * @param {$TSFixMe} data
 */
export function castReviewFormDataToCorrectTypes(data: $TSFixMe) {
  const clonedData = { ...data };

  clonedData.sentiment = Number(clonedData.sentiment);

  clonedData.review_viewed_at = moment().format("YYYY-MM-DD HH:mm:ss");

  if (clonedData.title) {
    clonedData.headline = clonedData.title;
  }

  if (clonedData.headline) {
    clonedData.title = clonedData.headline;
  }

  if (clonedData.channelList) {
    clonedData.channels = clonedData.channelList;
  }

  if (clonedData.topicList) {
    clonedData.topics = clonedData.topicList;
  }

  if (clonedData.sections && typeof clonedData.sections === "string") {
    clonedData.sections = JSON.parse(clonedData.sections);
  }

  if (!clonedData.stress_target) {
    clonedData.stress_target = {};
  }

  if (!clonedData.anxiety_target) {
    clonedData.anxiety_target = {};
  }

  if (!clonedData.depression_target) {
    clonedData.depression_target = {};
  }

  clonedData.stress_target.lower = Number(
    clonedData.stress_target_lower ?? clonedData.stress_target.lower
  );
  clonedData.stress_target.upper = Number(
    clonedData.stress_target_upper ?? clonedData.stress_target.upper
  );
  clonedData.anxiety_target.lower = Number(
    clonedData.anxiety_target_lower ?? clonedData.anxiety_target.lower
  );
  clonedData.anxiety_target.upper = Number(
    clonedData.anxiety_target_upper ?? clonedData.anxiety_target.upper
  );
  clonedData.depression_target.lower = Number(
    clonedData.depression_target_lower ?? clonedData.depression_target.lower
  );
  clonedData.depression_target.upper = Number(
    clonedData.depression_target_upper ?? clonedData.depression_target.upper
  );

  clonedData.status =
    clonedData.status === ContentStatus.scheduled
      ? ContentStatus.draft
      : clonedData.status;

  return clonedData;
}
