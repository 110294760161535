import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";

import LoginContainer from "../containers/LoginContainer";

const mapStateToProps = ({ user }: any) => ({
  user,
});

class PrivateManagersRoute extends Component<any> {
  render() {
    // @ts-ignore
    const { user } = this.props;

    if (!user.isAuthenticated || !user.isManager)
      return <LoginContainer fromRedirection />;

    return <Route {...this.props} />;
  }
}

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, {})(PrivateManagersRoute)
);
