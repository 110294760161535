import { EapPartner } from "@constants/eap";
import React, { useState } from "react";
import styled from "styled-components";

const EXPAND_LIMIT = 180;
export const EapCard = ({
  name,
  type,
  link,
  image,
  category,
  description,
}: EapPartner) => {
  const canExpand = description.length > EXPAND_LIMIT;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <StyledContainer>
      <StyledLogo src={`/assets/images/eap/${image}`}></StyledLogo>
      <a href={link} target="_blank" rel="noreferrer noopener">
        <StyledHeading>
          <StyledName>{name} - </StyledName> {type}
        </StyledHeading>
      </a>
      <StyledHr />
      <StyledCategory>{category}</StyledCategory>

      <StyledDescription>
        {isExpanded || !canExpand
          ? description
          : `${description.slice(0, EXPAND_LIMIT)}...`}
      </StyledDescription>
      {canExpand && (
        <StyledReadMore onClick={() => setIsExpanded(!isExpanded)}>
          <StyledReadMore>Read {isExpanded ? "less" : "more"}</StyledReadMore>
        </StyledReadMore>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  flex: 1;
`;
const StyledLogo = styled.img`
  height: 100px;
`;
const StyledHeading = styled.h1`
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--brandColorPrimary);
`;

const StyledHr = styled.div`
  width: 100%;
  height: 2px;
  margin-top: 0.8rem;
  margin-bottom: 1rem;
  background-color: rgba(0, 0, 0, 0.2);
`;
const StyledCategory = styled.div`
  color: var(--brandColorPrimary);
  margin-bottom: 0.6rem;
  font-size: 16px;
`;

const StyledDescription = styled.div`
  line-height: 1.4;
`;

const StyledName = styled.span`
  font-weight: 600;
`;

const StyledReadMore = styled.div`
  font-weight: 800;
  cursor: pointer;
  margin-top: 1rem;
  color: var(--brandColorPrimary);
`;
