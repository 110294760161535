import { isEmpty } from "lodash";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import React, { Component } from "react";

import Text from "../utils/Text";
import Content from "../utils/Content";
import Loader from "../components/Loader";
import ContentBlock from "../components/ContentBlock";
import TitleSecondary from "../components/TitleSecondary";
import TrackVisibility from "../components/TrackVisibility";
import { NoArticlesResultsMessage } from "../components/NoArticlesResultsMessage";

interface Props {
  match: any;
}

export default class TopicContainer extends Component<Props> {
  state = {
    articles: [],
    currentPage: 1,
    isLoading: true,
    loadMoreContent: true,
  };

  componentDidMount() {
    const { match } = this.props;
    const { currentPage, articles: currentArticles } = this.state;

    Content.get({
      limit: 20,
      paginate: true,
      type: "article",
      page: currentPage,
      topics: match.params.topic,
    }).then(({ articles }: any) => {
      this.setState({
        isLoading: false,
        currentPage: currentPage + 1,
        articles: [...currentArticles, ...articles],
      });
    });
  }

  getContent = () => {
    const { match } = this.props;
    const { currentPage, articles: currentArticles } = this.state;

    Content.get({
      limit: 20,
      paginate: true,
      type: "article",
      page: currentPage,
      topics: match.params.topic,
    }).then(({ articles }: any) => {
      this.setState({
        currentPage: currentPage + 1,
        articles: [...currentArticles, ...articles],
      });
    });
  };

  render() {
    const { match } = this.props;

    const { articles, isLoading } = this.state;

    const title = Text.toWords(match.params.topic);

    if (isLoading) return <Loader />;

    return (
      <StyledContainer>
        <Helmet>
          <title>{`${title} - ${process.env.REACT_APP_COMPANY_NAME}`}</title>
        </Helmet>
        {isEmpty(articles) ? (
          <>
            <TitleSecondary>{title}</TitleSecondary>
            <NoArticlesResultsMessage />
          </>
        ) : (
          <div>
            <StyledTitleSecondary>{title}</StyledTitleSecondary>
            <ContentBlock
              columns={4}
              // @ts-ignore
              articles={articles}
            />
            <TrackVisibility isActive={true} onVisible={this.getContent}>
              <Loader />
            </TrackVisibility>
          </div>
        )}
      </StyledContainer>
    );
  }
}

const StyledContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpointSmall}) {
    margin-top: 1.5rem;
  }
`;

const StyledTitleSecondary = styled(TitleSecondary)`
  display: block;
  margin: 0 0 1.5rem;
`;
