export const SET_USER = "SET_USER";
export const UNSET_USER = "UNSET_USER";

const initialState = {
  isManager: false,
  isAuthenticated: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...action.payload };
    case UNSET_USER:
      return initialState;
    default:
      return state;
  }
};

export default user;
