import styled from "styled-components";
import React, { PureComponent } from "react";

interface Props {
  alt: string;
  style?: any;
  onClick?: any;
  image: string;
  selected: boolean;
  className?: string;
  children: JSX.Element;
}

interface LinkProps {
  color: string;
  active: boolean;
  backgroundColor: string;
}

export default class WellbeingSelect extends PureComponent<Props> {
  static defaultProps = {
    style: {},
    className: "",
  };

  render() {
    const {
      alt,
      style,
      image,
      onClick,
      children,
      selected,
      className,
    } = this.props;

    return (
      <StyledSelectItem
        style={style}
        active={selected}
        onClick={onClick}
        className={className}
        color={selected ? "white" : ""}
        backgroundColor={selected ? "var(--brandColorPrimary)" : ""}
      >
        <StyledInternalImage
          alt={alt}
          src={image}
          height="30"
          className="mr-2"
        />
        <StyledInternalContainer>{children}</StyledInternalContainer>
      </StyledSelectItem>
    );
  }
}

const StyledSelectItem = styled.a`
  display: flex;
  padding: 0.5rem;
  border-radius: 3px;
  align-items: center;
  transition: background-color 100ms linear;
  color: ${(props: LinkProps) => props.color};
  background-color: ${(props: LinkProps) => props.backgroundColor};

  &:hover {
    color: ${(props: LinkProps) => props.color};
    background-color: ${(props: LinkProps) =>
      props.active ? props.backgroundColor : "#f0f0f0"};
  }
`;

const StyledInternalImage = styled.img`
  padding-right: 0;
  border-radius: 3px;
`;

const StyledInternalContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  & * {
    margin: auto;
  }
`;
