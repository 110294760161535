import getUrls from "get-urls";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import useReactRouter from "use-react-router";
import { IconNames } from "@blueprintjs/icons";
import { lowerCase, flatten, isEmpty } from "lodash";
import { FormGroup, Intent, Button } from "@blueprintjs/core";

import api from "@api";
import { Grid } from "@components/Grid";
import { Form } from "@components/Form";
import Loader from "@components/Loader";
import { Select } from "@components/Select";
import Wrapper from "@components/managers/Wrapper";
import { MultiSelect } from "@components/MultiSelect";
import ContentType from "@enums/managers/ContentType";
import InputUnderline from "@components/InputUnderline";
import DatePicker from "@components/managers/DatePicker";
import { ChannelList } from "@enums/managers/ChannelList";
import ContentStatus from "@enums/managers/ContentStatus";
import VideoPreview from "@components/managers/VideoPreview";
import ImageUploader from "@components/managers/ImageUploader";
import MarkdownEditor from "@components/managers/MarkdownEditor";
import { getContentSingle, putContent } from "@store/actions/managers/content";
import ManagersContentInterface from "@interfaces/managers/ManagersContentInterface";

import {
  promptForRejectionReason,
  castReviewFormDataToCorrectTypes,
  promptIsAcceptedPostStatusCorrect,
} from "@utils/managers/Review";

export default function ManagersReviewContentContainer(): JSX.Element {
  const [topics, setTopics] = useState<string[]>([]);
  const [content, setContent] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [podcastUrl, setPodcastUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [article, setArticle] = useState<ManagersContentInterface>();
  const [contentStatus, setContentStatus] = useState<ContentStatus>();
  const [reviewApproved, setReviewApproved] = useState<boolean>(false);

  const [contentType, setContentType] = useState<ContentType>(
    ContentType.article
  );

  const {
    history,
    match: {
      params: { slug },
    },
  } = useReactRouter<{ slug: string }>();

  useAsyncEffect(async () => {
    const data = await getContentSingle(slug);
    const { data: topicsResponse } = await api.get("admin/filters/topics");

    // Set content type
    setContentType(data.type);

    // Mark as viewed
    putContent({
      type: data.type,
      showToast: false,
      contentId: data.article_id,
      data: castReviewFormDataToCorrectTypes(data),
    });

    setArticle(data);

    const parsedTopics: string[] = Object.values(
      topicsResponse.payload.topics as string[]
    ).sort();

    setTopics([...new Set(parsedTopics)]);

    setIsLoading(false);
  }, []);

  /**
   * Check if any of the ContentType's
   * match the current set ContentType
   *
   * @param {ContentType[]} contentTypes
   * @returns {boolean}
   */
  function isContentType(...contentTypes: ContentType[]): boolean {
    return contentTypes.includes(contentType);
  }

  /**
   * Check if any of the ContentStatus's
   * match the current set ContentStatus
   *
   * @param {ContentStatus[]} contentTypes
   * @returns {boolean}
   */
  function isContentStatus(...contentStatuses: ContentStatus[]): boolean {
    return contentStatuses.map(lowerCase).includes(contentStatus);
  }

  /**
   * Takes a string of text and returns an array of image urls
   *
   * @param {string} text
   * @returns {string[]}
   */
  function getImageUrlsFromString(text: string): string[] {
    const urls = Array.from(getUrls(text)).map((string) =>
      // BBC specific image paths
      string.replace("%3E", "").replace(")", "")
    );

    const images = urls.filter(
      (url) => url.endsWith(".png") || url.endsWith(".jpg")
    );

    return images;
  }

  /**
   * Submit the form
   *
   * @param {$TSFixMe} data
   * @returns {Promise<void>}
   */
  async function handleOnSubmit(data: $TSFixMe): Promise<void> {
    let review_rejection_reason = "";

    if (reviewApproved) {
      const isPostAcceptedStatusCorrect = await promptIsAcceptedPostStatusCorrect(
        data.status
      );

      if (!isPostAcceptedStatusCorrect) return;
    } else {
      review_rejection_reason = await promptForRejectionReason();

      if (!review_rejection_reason) return;
    }

    const formData = {
      ...castReviewFormDataToCorrectTypes(data),
      review_approved: reviewApproved,
      review_accepted: reviewApproved,
      // Only add it when needed, the api throws an error if it's an empty string
      review_rejection_reason: !isEmpty(review_rejection_reason)
        ? review_rejection_reason
        : undefined,
    };

    await putContent({
      type: contentType,
      data: formData as $TSFixMe,
      contentId: article.article_id,
    });

    history.push("/managers/content/review");
  }

  if (isLoading) return <Loader />;

  return (
    <Wrapper>
      <Form className="pt-4" onSubmit={handleOnSubmit}>
        <InputUnderline
          name="title"
          label="Title"
          maxLength={191}
          required={true}
          className="mb-4"
          lightBorder={true}
          defaultValue={article.headline}
        />
        <InputUnderline
          name="abstract"
          maxLength={191}
          required={true}
          label="Overview"
          className="mb-4"
          lightBorder={true}
          defaultValue={article.abstract}
        />
        <Grid columns="2fr 1fr">
          <Grid columns="1fr 1fr">
            <InputUnderline
              min={0}
              max={100}
              type="number"
              name="stress_target[lower]"
              required={true}
              className="mb-0 stress_lower"
              lightBorder={true}
              label="Stress Lower (0-100)"
              defaultValue={article.stress_target_lower as $TSFixMe}
            />
            <InputUnderline
              min={0}
              max={100}
              type="number"
              name="stress_target[upper]"
              required={true}
              className="mb-0 stress_upper"
              lightBorder={true}
              label="Stress Upper (0-100)"
              defaultValue={article.stress_target_upper as $TSFixMe}
            />
            <InputUnderline
              min={0}
              max={100}
              type="number"
              name="anxiety_target[lower]"
              required={true}
              className="mb-0 anxiety_lower"
              lightBorder={true}
              label="Anxiety Lower (0-100)"
              defaultValue={article.anxiety_target_lower as $TSFixMe}
            />
            <InputUnderline
              min={0}
              max={100}
              type="number"
              name="anxiety_target[upper]"
              required={true}
              className="mb-0 anxiety_upper"
              lightBorder={true}
              label="Anxiety Upper (0-100)"
              defaultValue={article.anxiety_target_upper as $TSFixMe}
            />
            <InputUnderline
              min={0}
              max={100}
              name="depression_target[lower]"
              required={true}
              className="mb-0 depression_lower"
              type="number"
              lightBorder={true}
              label="Depression Lower (0-100)"
              defaultValue={article.depression_target_lower as $TSFixMe}
            />
            <InputUnderline
              min={0}
              max={100}
              name="depression_target[upper]"
              required={true}
              className="mb-0 depression_upper"
              type="number"
              lightBorder={true}
              label="Depression Upper (0-100)"
              defaultValue={article.depression_target_upper as $TSFixMe}
            />
            <InputUnderline
              max={100}
              min={-100}
              type="number"
              required={true}
              className="mb-0 sentiment"
              name="sentiment"
              lightBorder={true}
              label="Sentiment (-100 - +100)"
              defaultValue={article.sentiment as $TSFixMe}
            />
            <Select
              className="status"
              name="status"
              label="Status"
              hasSearch={false}
              items={Object.values(ContentStatus).map((status) =>
                status.toLowerCase()
              )}
              defaultValue={contentStatus || article.status}
              onChange={(value) => setContentStatus(value as ContentStatus)}
            />
            <FormGroup label="Topics" className="pb-2" intent={Intent.NONE}>
              <MultiSelect
                name="topics"
                className="topics"
                options={topics.map((topic) => ({
                  label: topic,
                  value: topic,
                }))}
                defaultValue={topics
                  .filter((topic) => {
                    return flatten<string>(article.topicList).includes(topic);
                  })
                  .map((topic) => ({
                    label: topic,
                    value: topic,
                  }))}
              />
            </FormGroup>
            <FormGroup className="pb-2" label="Channels" intent={Intent.NONE}>
              <MultiSelect
                name="channels"
                className="channels"
                options={Object.entries<string>(ChannelList).map(
                  ([value, label]) => ({
                    label,
                    value,
                  })
                )}
                defaultValue={Object.entries<string>(ChannelList)
                  .filter(([key, _value]) => {
                    return Object.values<string>(article.channelList).includes(
                      key
                    );
                  })
                  .map(([value, label]) => ({
                    label,
                    value,
                  }))}
              />
            </FormGroup>
            {isContentType(ContentType.video) && (
              <InputUnderline
                required={true}
                className="mb-0"
                name="youtube_url"
                lightBorder={true}
                label="Youtube / Vimeo Url"
                defaultValue={article.video_link}
                onChange={(_, value) => setVideoUrl(value)}
              />
            )}
            {isContentType(ContentType.podcast) && (
              <>
                <InputUnderline
                  required={true}
                  className="mb-0"
                  name="homepage_link"
                  lightBorder={true}
                  label="Homepage URL"
                  defaultValue={article.podcast_homepage}
                />
                <InputUnderline
                  required={true}
                  className="mb-0"
                  name="podcast_link"
                  lightBorder={true}
                  label="YouTube / SoundCloud URL"
                  defaultValue={article.podcast_file_url}
                  onChange={(_, value) => setPodcastUrl(value)}
                />
              </>
            )}
            {isContentStatus(ContentStatus.scheduled) && (
              <Grid
                className="mb-8"
                columns="1.8fr 1fr"
                style={{ gridColumn: "span 2" }}
              >
                <div>
                  <div className="pb-2">
                    <strong>Please note:</strong>
                  </div>
                  By setting a scheduled publish date we will automatically push
                  this content live to your entire organisation on the date and
                  time you specify.
                </div>
                <DatePicker
                  name="publish_at"
                  defaultValue={article.posted_at}
                />
              </Grid>
            )}
          </Grid>
          <div>
            <Select
              name="content"
              hasSearch={false}
              label="Content Type"
              items={Object.values(ContentType)}
              defaultValue={contentType || article.type}
              onChange={(value) => setContentType(value as ContentType)}
            />
            {isContentType(ContentType.article, ContentType.podcast) && (
              <FormGroup
                intent={Intent.NONE}
                className="pb-2"
                label="Featured Image"
              >
                <ImageUploader
                  name="image_id"
                  required={true}
                  className="mt-2"
                  key={`${article.image_id}`}
                  defaultValue={article.image_id}
                  cloudinaryImageID={article.image_id}
                  selectableImages={getImageUrlsFromString(
                    content || article.content
                  )}
                />
              </FormGroup>
            )}
            {isContentType(ContentType.video) && (
              <FormGroup intent={Intent.NONE} label="Preview">
                <VideoPreview link={videoUrl || article.video_link} />
              </FormGroup>
            )}
            {isContentType(ContentType.podcast) && (
              <FormGroup intent={Intent.NONE} label="Preview">
                <VideoPreview link={podcastUrl || article.podcast_file_url} />
              </FormGroup>
            )}
          </div>
        </Grid>
        {isContentType(ContentType.article) && (
          <MarkdownEditor
            name="content"
            defaultValue={article.content}
            onChange={(text) => setContent(text)}
          />
        )}
        <Button
          large={true}
          type="submit"
          id="test_submit"
          icon={IconNames.TICK}
          className="mt-4 mr-4"
          intent={Intent.PRIMARY}
          onClick={() => setReviewApproved(true)}
        >
          Approve
        </Button>
        <Button
          large={true}
          type="submit"
          className="mt-4"
          intent={Intent.NONE}
          icon={IconNames.CROSS}
          id="test_deny-content"
          onClick={() => setReviewApproved(false)}
        >
          Reject
        </Button>
      </Form>
    </Wrapper>
  );
}
