import styled from "styled-components";

/**
 * Muted text used for de-emphasis
 */
export const MutedText = styled.div`
  opacity: 0.5;
  &:hover {
    text-decoration: none;
  }
`;
