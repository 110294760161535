export const SET_SEARCH = "SET_SEARCH";
export const UNSET_SEARCH = "UNSET_SEARCH";

const search = (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return { ...action.payload };
    case UNSET_SEARCH:
      return {};
    default:
      return state;
  }
};

export default search;
