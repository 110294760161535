import api from "../../api";

import { SET_CHANNELS } from "../reducers/channels";

/**
 * Get channels data
 * @return {object|null} channels payload
 */
export const getChannels = () => () => {
  return api
    .get("channels")
    .then(({ data }) => data.payload)
    .catch(() => "Could not load API");
};

/**
 * Set channels data
 * @param {object} channels
 */
export const setChannels = (channels) => (dispatch) => {
  dispatch({
    type: SET_CHANNELS,
    payload: channels,
  });
};

export default getChannels;
