import styled from "styled-components";
import { Image } from "cloudinary-react";
import React, { PureComponent } from "react";

import YouTubeThumbnail from "../YouTubeThumbnail";

interface Props {
  result: any;
  isVideo: boolean;
}

export default class SearchResultImage extends PureComponent<Props> {
  render() {
    const { result, isVideo } = this.props;

    return (
      <StyledResultImageContainer>
        {!isVideo ? (
          <Image
            publicId={result.image_id}
            cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
          />
        ) : (
          <YouTubeThumbnail id={result?.youtube_link || result?.video_link} />
        )}
      </StyledResultImageContainer>
    );
  }
}

const StyledResultImageContainer = styled.div`
  width: 58px;
  height: 58px;
  display: flex;
  min-width: 58px;
  overflow: hidden;
  justify-content: center;
  background-color: var(--placeholderImageBackground);

  img {
    width: auto;
    height: auto;
    min-width: 120px;
  }
`;
