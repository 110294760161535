/* eslint react/prop-types: 0 */
import { connect } from "react-redux";
import { isEmpty, keys } from "lodash";
import styled from "styled-components";
import { Image } from "cloudinary-react";
import React, { Component } from "react";

import {
  setOrganisation,
  getOrganisations,
} from "../../store/actions/managers";

const mapStateToProps = ({ organisation, organisations }: any) => ({
  organisation,
  organisations,
});

const mapDispatchToProps = (dispatch: any) => ({
  getOrganisations: () => dispatch(getOrganisations()),
  setOrganisation: (selected: any, organisation: any) =>
    dispatch(setOrganisation(selected, organisation)),
});

class OrganisationSwitcher extends Component {
  state = {
    open: false,
    isLoading: true,
  };

  componentDidMount() {
    // @ts-ignore
    // eslint-disable-next-line no-shadow
    const { getOrganisations } = this.props;

    getOrganisations().then(() => this.setState({ isLoading: false }));
  }

  getImage(selected: number | any = 0) {
    // @ts-ignore
    const { organisations } = this.props;

    return isEmpty(organisations)
      ? null
      : organisations[selected].image_id && (
          <StyledImage
            publicId={organisations[selected].image_id}
            className={organisations.length === 1 && "single"}
            cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
          />
        );
  }

  handleChangeOrganisation = (selected = 0) => {
    // @ts-ignore
    // eslint-disable-next-line no-shadow
    const { organisations, setOrganisation } = this.props;

    setOrganisation(selected, organisations[selected]);
  };

  render() {
    const { open, isLoading } = this.state;
    // @ts-ignore
    const { organisation, organisations } = this.props;

    /** Cot currently part of any organisations */
    if (isEmpty(organisations)) return null;

    /** Only one organisation */
    if (!isLoading && organisations.length === 1) {
      return this.getImage(organisation.selected);
    }

    return isLoading || isEmpty(organisation) ? null : (
      <StyledContainer
        // @ts-ignore
        className={open && "open"}
        onClick={() => this.setState({ open: !open })}
      >
        <StyledOrganisation>
          <StyledOrganisationName>{organisation.name}</StyledOrganisationName>
          {this.getImage(organisation.selected)}
        </StyledOrganisation>
        {open && (
          <StyledPopUp>
            {keys(organisations).map((key) => (
              <StyledOrganisation
                key={key}
                onClick={() =>
                  // @ts-ignore
                  this.handleChangeOrganisation(key, organisations[key])
                }
              >
                <StyledName>{organisations[key].name}</StyledName>
                <StyledLogo>{this.getImage(key)}</StyledLogo>
              </StyledOrganisation>
            ))}
          </StyledPopUp>
        )}
      </StyledContainer>
    );
  }
}

const StyledContainer = styled.div`
  display: block;
  cursor: pointer;
  user-select: none;
  position: relative;
  border-radius: 2px;
  margin: 0 0 0 1rem;
  padding-right: 1.5rem;

  &::after {
    top: 12px;
    right: 4px;
    content: "";
    width: 11px;
    height: 11px;
    display: block;
    position: absolute;
    border-style: solid;
    border-color: #cecece;
    transform: rotate(135deg);
    border-width: 2px 2px 0 0;
  }

  &:hover,
  &.open {
    &::after {
      border-color: #aeaeae;
    }
  }

  &.open {
    &::after {
      top: 16px;
      transform: rotate(-45deg);
    }
  }
`;

const StyledOrganisation = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding: 0.4rem 0.5rem;
  justify-content: flex-end;

  &:hover {
    opacity: 0.7;
  }
`;

const StyledOrganisationName = styled.span`
  overflow: hidden;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    max-width: 100px;
  }
`;

const StyledImage = styled(Image)`
  max-height: 22px;
  border-radius: 3px;
  margin: -4px 0 0 1.2rem;

  &.single {
    max-height: 30px;
    margin: -3px 0 0 1rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    display: none;
  }
`;

const StyledPopUp = styled.div`
  right: 0;
  top: 46px;
  z-index: 10;
  padding: 1rem;
  text-align: right;
  background: white;
  position: absolute;
  border: 1px solid #e2e2e2;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
`;

const StyledName = styled.div`
  font-weight: 300;
  overflow: hidden;
  text-align: left;
  padding: 0.2rem 0;
  font-size: 1.1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledLogo = styled.div`
  margin-left: auto;
  padding-left: 2rem;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationSwitcher);
