import React from "react";
import TrackVisibility from "react-on-screen";

export default function TRackVisibility(props: any) {
  return (
    <>
      {props.isActive && (
        // eslint-disable-next-line react/jsx-filename-extension
        <TrackVisibility
          partialVisibility
          throttleInterval={50}
          offset={props.offset || 200}
        >
          <ContentLoader
            isActive={props.isActive}
            onVisible={props.onVisible}
          />
          {props.children}
        </TrackVisibility>
      )}
    </>
  );
}

let shouldLoad = true;

// @ts-ignore
const ContentLoader = ({ isVisible, onVisible }: any) => {
  if (shouldLoad === false && shouldLoad !== isVisible) {
    onVisible();
  }
  shouldLoad = isVisible;
  return null;
};
