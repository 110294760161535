import { Helmet } from "react-helmet";
import styled from "styled-components";
import { isEmpty, merge } from "lodash";
import React, { useState } from "react";
import useReactRouter from "use-react-router";

import Text from "@utils/Text";
import Content from "@utils/Content";
import useMount from "@hooks/useMount";
import Loader from "@components/Loader";
import Sidebar from "@components/Sidebar";
import { Advert } from "@components/Advert";
import { Breakpoint } from "@components/Breakpoint";
import ContentBlock from "@components/ContentBlock";
import useOrganisation from "@hooks/useOrganisation";
import { BREAKPOINTS } from "@constants/breakpoints";
import TitleSecondary from "@components/TitleSecondary";
import TrackVisibility from "@components/TrackVisibility";
import ContentBlockFeatured from "@components/ContentBlockFeatured";
import ContentBlockPaginated from "@components/ContentBlockPaginated";
import { NoArticlesResultsMessage } from "@components/NoArticlesResultsMessage";
import { getGermanChannelName } from "@utils/german";

const ChannelContainer = () => {
  const {
    match: {
      params: { slug },
    },
  } = useReactRouter<{ slug: string }>();

  const { organisation } = useOrganisation();

  const [loadMoreContent] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [articles, setArticles] = useState<any[]>([]);
  const [featuredArticle, setFeaturedArticle] = useState(null);

  const getPagedContent = () =>
    Content.get(
      merge(
        {
          limit: 20,
          channel: slug,
          paginate: true,
          type: "article",
          page: currentPage,
        },
        slug === "organisation" && { organisational: true }
      )
    ).then((response: any) => {
      const newArticles = response.articles;

      setIsLoading(false);

      if (!isEmpty(newArticles)) {
        if (currentPage === 1) {
          const firstPost = newArticles.shift();
          setFeaturedArticle(firstPost);
          setArticles([...articles, ...newArticles]);
        } else {
          setArticles([...articles, ...newArticles]);
        }
      }

      setCurrentPage(currentPage + 1);
    });

  useMount(() => {
    getPagedContent();
  });

  const title =
    slug === "organisation"
      ? organisation.name
      : getGermanChannelName(Text.toWords(slug));

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <StyledChannelContainer className="pb-4">
        <Helmet>
          <title>{`${title} - ${process.env.REACT_APP_COMPANY_NAME}`}</title>
        </Helmet>

        <div className="mr-4 w-100">
          <ContentBlockFeatured
            title={title}
            className="mb-8"
            article={featuredArticle}
          />

          <Breakpoint min={BREAKPOINTS.breakpointMedium}>
            <Advert slug={slug} />
          </Breakpoint>

          {isEmpty(articles) && isEmpty(featuredArticle) ? (
            <>
              <TitleSecondary>{title}</TitleSecondary>
              <NoArticlesResultsMessage />
            </>
          ) : (
            <>
              <ContentBlock
                columns={3}
                // @ts-ignore
                articles={articles}
              />
              <TrackVisibility
                isActive={loadMoreContent}
                onVisible={() => getPagedContent()}
              >
                {loadMoreContent && <Loader />}
              </TrackVisibility>
            </>
          )}
        </div>
        <Sidebar>
          <Breakpoint max={BREAKPOINTS.breakpointMedium}>
            <Advert slug={slug} />
          </Breakpoint>
          <ContentBlockPaginated
            type="videos"
            channel={slug}
            titleClassName="mt-0"
            featuredPostColumns={2}
          />
          <ContentBlockPaginated type="podcasts" channel={slug} />
        </Sidebar>
      </StyledChannelContainer>
    </>
  );
};

const StyledChannelContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    display: flex;
  }
`;

export default ChannelContainer;
