/* eslint no-unexpected-multiline: 0 */
import { isEmpty } from "lodash";
import styled from "styled-components";
import { Link } from "react-router-dom";
import React, { Component } from "react";

import api from "@api";
import Icon from "@components/Icon";
import Image from "@components/Image";
import { Title } from "@components/Title";
import VideoModal from "@components/VideoModal";
import ChannelLinks from "@components/ChannelLinks";
import { TruncatedText } from "@components/TruncatedText";
import { getGermanChannelName } from "@utils/german";

interface IContentBlock {
  style: any;
  slug?: string;
  columns?: any;
  title?: string;
  color?: string;
  className?: string;
  direction?: string;
  hideAbstract?: boolean;
  hideChannels?: boolean;
  titleClassName?: string;
  featuredPostColumns?: number;
  articles: [
    {
      slug: string;
      image: string;
      title: string;
      image_id: string;
      headline: string;
      abstract: string;
      channels: any;
      article_id: string;
    }
  ];
}

export default class ContentBlock extends Component<IContentBlock> {
  static defaultProps = {
    title: "",
    columns: 1,
    slug: null,
    className: "",
    direction: "",
    color: "intitial",
    titleClassName: "",
    hideAbstract: false,
    hideChannels: false,
    featuredPostColumns: 0,
  };

  state = {
    video: {
      url: "",
      title: "",
      channels: [],
      slug: "",
      type: "",
    },
  };

  /**
   * The max number of channels to show
   * on a content block.
   *
   * @type {Number}
   */
  channelLimit = 3;

  playVideo = (event: any, data: any) => {
    if (data.video) {
      event.preventDefault();
      event.stopPropagation();

      // register engagement interaction
      api.post(`/employee/content/engagement/${data.type}/${data.slug}`);

      this.setState({
        video: {
          url: data.video,
          title: data.headline,
          channels: data.channels,
          slug: data.slug,
          type: data.type,
        },
      });
    }
  };

  stopVideo = () => {
    this.setState({
      video: {
        url: "",
        slug: "",
        title: "",
        channels: [],
        type: "",
      },
    });
  };

  /**
   * Render the component
   *
   * @return {jsx}
   */
  render() {
    const {
      slug,
      title,
      color,
      style,
      columns,
      articles,
      direction,
      className,
      hideAbstract,
      hideChannels,
      titleClassName,
      featuredPostColumns,
    } = this.props;

    const { video } = this.state;

    /**
     * Only load articles if any exist
     */
    if (isEmpty(articles)) return null;

    const blocks = articles?.map((article: any, key: number) => {
      return (
        // eslint-disable-next-line react/jsx-filename-extension
        <div key={key}>
          <StyledContentBlockLink
            id={`test__article-${article.slug}`}
            to={`/${article.type}/${article.slug}`}
            onClick={(event) => this.playVideo(event, article)}
          >
            <StyledContentBlockImageContainer>
              <StyledContentBlockImage
                src={article.image}
                alt={article.abstract}
              />
              <StyledContentBlockPlayIcon
                icon="play"
                color="white"
                iconSize={28}
                playbutton={article.video ? "flex" : "none"}
              />
            </StyledContentBlockImageContainer>
            <StyledContentBlockSubtitle>
              {article.headline}
            </StyledContentBlockSubtitle>
            {!hideAbstract && article.abstract && (
              <div>
                <StyledContentBlockAbstract lines={4}>
                  {article.abstract}
                </StyledContentBlockAbstract>
              </div>
            )}
            {!hideChannels && article?.channels?.length > 0 && (
              <div className="mt-a">
                <ChannelLinks channels={article.channels} />
              </div>
            )}
          </StyledContentBlockLink>
        </div>
      );
    });

    return (
      <StyledContentBlock className={className} style={style}>
        {title && (
          <Title id={`test__${slug}`} color={color} className={titleClassName}>
            {getGermanChannelName(title)}
          </Title>
        )}
        <StyledContentBlockArticles
          columns={columns}
          direction={direction}
          featuredPostColumns={featuredPostColumns}
        >
          {blocks}
        </StyledContentBlockArticles>
        <VideoModal video={video} onClose={this.stopVideo} />
      </StyledContentBlock>
    );
  }
}

const StyledContentBlock = styled.div``;

const StyledContentBlockSubtitle = styled.h3`
  display: block;
  margin: 0.5rem 0;
`;

const StyledContentBlockLink = styled(Link)`
  display: flex;
  min-height: 100%;
  padding-bottom: 0.8rem;
  flex-direction: column;

  &:hover {
    h2,
    h3 {
      text-decoration: underline;
    }
  }
`;

const StyledContentBlockAbstract = styled(TruncatedText)`
  text-decoration: none;
`;

const StyledContentBlockImageContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const StyledContentBlockImage = styled(Image)`
  width: 100%;
  height: 11rem;
  display: block;
  object-fit: cover;
  position: relatie;
  border-radius: 2px;
  margin: 0 0 0.8rem;

  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    height: 7rem;
  }
`;

const StyledContentBlockPlayIcon = styled(Icon)`
  &&& {
    left: 0;
    right: 0;
    width: 58px;
    height: 38px;
    padding-top: 1px;
    position: absolute;
    border-radius: 3px;
    align-items: center;
    margin: -3px auto 0;
    justify-content: center;
    display: ${(props) => props.playbutton};
    background-color: var(--playButtonBackground);
  }
`;

const StyledContentBlockArticles = styled.div<{
  columns: any;
  direction: any;
  featuredPostColumns: any;
}>`
  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    display: grid;
    grid-gap: 1.5rem;
    direction: ${(props) => props.direction};
    grid-template-columns: repeat(${(props) => props.columns || 1}, 1fr);

    // Ensure the text is in the correct direction when reversing the articles
    // it must be the inverse of the current direction.
    * {
      direction: ${(props) => (props.direction === "rtl" ? "ltr" : null)};
    }

    & > *:nth-child(${(props) => (props.featuredPostColumns ? 1 : 0)}) {
      grid-column: 1 / span ${(props) => props.featuredPostColumns};
      grid-row: 1/3;
      img {
        max-height: 23.8rem;
        height: calc(100% - 2.15rem);
      }
    }
  }
`;
