import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import api from "@api";
// import { connect } from "react-redux";
import Loader from "@components/Loader";
// import LoginCounter from "@utils/LoginCounter";
// import { SET_USER } from "@store/reducers/user";
// import { getOrganisations } from "@store/actions/managers";
import ContainerContent from "@components/ContainerContent";

interface IStudentVerifyEmailContainer {
  match: Record<string, string>;
  dispatchGetOrganisations: any;
  dispatchSetUser: any;
}

// const mapDispatchToProps = (dispatch: any) => ({
// dispatchGetOrganisations: () => dispatch(getOrganisations()),
// dispatchSetUser: (data: any) => dispatch({ type: SET_USER, payload: data }),
// });

const StudentVerifyEmailContainer = ({
  match,
}: // dispatchSetUser,
// dispatchGetOrganisations,
IStudentVerifyEmailContainer) => {
  const history = useHistory();
  const code = match.params["confirmation_code"];

  // TODO: This copied logic from the login action & should be in redux
  useEffect(() => {
    const verifyCode = async () => {
      try {
        await api.post("students/verify", { code: code });
        // const response = await api.post("students/verify", { code: code });

        // const payload = response.data.payload.user;
        // payload.isAuthenticated = Boolean(payload.user_id);

        // if (["staff", "manager"].includes(payload.role))
        //   payload.isManager = true;

        // const counter = new LoginCounter();
        // counter.add();

        // dispatchSetUser(payload);
        // dispatchGetOrganisations();

        Swal.fire({
          title: "Email verified",
          text:
            "Your email has been successfully verified and you may now login",
          icon: "success",
        });
      } catch (error) {
        Swal.fire({
          title: "An error occurred",
          text: error,
          icon: "error",
        });
      }

      history.push(`/login`);
    };

    verifyCode();
  }, [code, history]);

  return (
    <ContainerContent>
      <Helmet>
        <title>Confirm email - {process.env.REACT_APP_COMPANY_NAME}</title>
      </Helmet>
      <Loader />
    </ContainerContent>
  );
};

// export default connect(null, mapDispatchToProps)(StudentVerifyEmailContainer);

export default StudentVerifyEmailContainer;
