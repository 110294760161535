import styled from "styled-components";
import { Dialog } from "@blueprintjs/core";
import React, { useEffect, useMemo, useState } from "react";

import { Grid } from "./Grid";
import { SUPPORT_AGENCIES } from "../constants/support-agencies";

interface IHelplineModal {
  scores: Record<string, number>;
}

export default function HelplineModal({ scores = {} }: IHelplineModal) {
  const [hasCompletedWellbeing, setHasCompletedWellbeing] = useState<boolean>(
    false
  );

  useEffect(() => {
    setHasCompletedWellbeing(
      Boolean(localStorage.getItem("hasCompletedWellbeing"))
    );
  }, []);

  function handleOnClose(): void {
    setHasCompletedWellbeing(false);
    localStorage.removeItem("hasCompletedWellbeing");
  }

  const isModalOpen = useMemo(() => {
    return (
      hasCompletedWellbeing &&
      scores &&
      Object.values(scores).filter((a) => a > 70).length > 0
    );
  }, [scores, hasCompletedWellbeing]);

  return (
    <StyledDialog
      isOpen={isModalOpen}
      onClose={handleOnClose}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      title="Free helplines are available"
    >
      <StyledHelplineContainer>
        <StyledHelplineTextContainer>
          <p>
            <strong>
              Based on your answers, help and support is available right now if
              you need it.
            </strong>{" "}
          </p>

          <p>You do not have to struggle with difficult feelings alone.</p>
          <p>
            These free helplines are there to help when you're feeling down or
            desperate. Unless it says otherwise, they're open 24 hours a day,
            every day.
          </p>
        </StyledHelplineTextContainer>
        <StyledAgencyContainer>
          <StyledGrid columns="1fr 1fr 1fr">
            {SUPPORT_AGENCIES.map(({ src, title, data }) => (
              <StyledHelpline key={src}>
                <StyledHelplineImage
                  src={`assets/images/helplines/${src}`}
                  alt={title}
                />
                <StyledHelplineTitle href="https://www.samaritans.org/">
                  {title}
                </StyledHelplineTitle>
                <StyledHelplineDescription>
                  {data.map((data, id) => (
                    <StyledHelplineDescriptionText key={id}>
                      {data}
                    </StyledHelplineDescriptionText>
                  ))}
                </StyledHelplineDescription>
              </StyledHelpline>
            ))}
          </StyledGrid>
        </StyledAgencyContainer>
      </StyledHelplineContainer>
    </StyledDialog>
  );
}

const StyledHelpline = styled.div`
  padding: 1rem;
  text-align: center;
`;

const StyledGrid = styled(Grid)<{ theme }>`
  > div {
    background: #ffffff;
  }

  grid-gap: 1px;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    grid-template-columns: 2fr 2fr;
  }

  @media (max-width: ${({ theme }) => theme.breakpointSmall}) {
    grid-template-columns: 1fr;
  }
`;

const StyledAgencyContainer = styled.div`
  background: #d6d6d6;
`;

const StyledHelplineTitle = styled.a`
  font-weight: bold;
  position: relative;
  line-height: 1.5;
  color: var(--brandColorPrimary);
  :hover {
    color: var(--brandColorPrimary);
  }
`;

const StyledHelplineContainer = styled.div`
  padding: 1.2rem;
`;

const StyledHelplineImage = styled.img`
  margin: 1rem auto;
  height: 4.8rem;
`;

const StyledHelplineDescription = styled.div`
  margin-top: 0.5rem;
`;

const StyledHelplineDescriptionText = styled.p`
  line-height: 1.4;
  font-size: 14px;

  :not(:last-child) {
    margin-bottom: 0.4rem;
  }

  a {
    color: var(--brandColorPrimary);
  }
`;

const StyledDialog = styled(Dialog)`
  width: 700px;
  max-width: 100%;
`;

const StyledHelplineTextContainer = styled.div`
  p {
    margin-bottom: 0.5rem;
    line-height: 1.4;
  }

  margin-bottom: 2rem;
`;
