import axios from "axios";

const hasToken = () => {
  const token = localStorage.getItem("token");

  return token !== "undefined" && token !== null;
};

const api = axios.create({
  baseURL: window.env.REACT_APP_API_URL,
  headers: hasToken() ? { Authorization: localStorage.getItem("token") } : null,
});

/*
 * On promise success
 * @param  {object} response
 * @return {object}
 */
const handleSuccess = (response) => {
  let token = response.headers.authorization;

  localStorage.setItem("token", token);

  response.headers = token ? { Authorization: token } : null;
  api.defaults.headers = token ? { Authorization: token } : null;
  axios.defaults.headers = token ? { Authorization: token } : null;

  return response;
};

/*
 * On promise rejection.
 * @param  {object} error
 * @return {object|boolean} error.message (for string)
 */
const handleError = (error) => {
  const response = error.response;
  if (response === undefined) return error;

  /**
   * Typically needed for when a session expires
   * whilst a user screen is still active
   */
  if (
    response.status === 401 &&
    response.config.url.indexOf("auth/login") === -1
  ) {
    localStorage.removeItem("token");
    window.location.href = "/logout";
    return false;
  }

  /**
   * The current endpoint errors out with the correct assesment ID
   * the following allows for an await.then to return the ID
   */
  if (
    response.status === 400 &&
    response.config.url.indexOf("assessments/start") > -1
  ) {
    return response.data.payload
      ? response.data.payload.current_assessment_id
      : null;
  }

  const errors = response.data.payload;
  let single = "";

  // comes back with different error objects
  if (!errors.error) {
    if (
      errors.error === "" &&
      errors.error_type === "user_old_password_incorrect"
    ) {
      single = "The current password you provided was incorrect";
    } else {
      const object = Object.values(errors.errors);
      single = object.length === 1 ? object[0] : object[0][0];
    }
  }

  const message = !errors.error || single.length > 0 ? single : errors.error;

  return Promise.reject(new Error(message));
};

api.interceptors.response.use(handleSuccess, handleError);

export default api;
