export const SET_ORGANISATIONS = "SET_ORGANISATIONS";
export const UNSET_ORGANISATIONS = "UNSET_ORGANISATIONS";

const initialState = [];

const organisations = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANISATIONS:
      return [...action.payload];
    case UNSET_ORGANISATIONS:
      return initialState;
    default:
      return state;
  }
};

export default organisations;
