import moment from "moment";
import slugify from "slugify";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { find, merge, first } from "lodash";
import { withRouter } from "react-router-dom";
import React, { Fragment, Component } from "react";

import Content from "@utils/Content";
import Loader from "@components/Loader";
import ContentBlock from "@components/ContentBlock";
import HelplineModal from "@components/HelplineModal";
import TrackVisibility from "@components/TrackVisibility";
import { ShoutAppBubble } from "@components/ShoutAppBubble";
import { refreshUser } from "@store/actions/authentication";
import { shoutAppMessages } from "@constants/shoutAppMessages";
import LoginCounterDialog from "@components/LoginCounterDialog";
import ContentBlockPrimary from "@components/ContentBlockPrimary";

const mapDispatchToProps = (dispatch: any) => ({
  dispatchRefreshUser: () => dispatch(refreshUser()),
});

const mapStateToProps = ({ user, channels }: any) => ({
  user,
  channels,
});

interface Props {
  user: any;
  channels: any;
}

class HomeContainer extends Component<Props> {
  public timeoutID;

  state = {
    articles: [],
    channels: [],
    isLoading: true,
    userChannels: [],
    loadMoreContent: true,
    currentChannelIndex: 0,
  };

  async componentDidMount() {
    // @ts-ignore
    const { dispatchRefreshUser } = this.props;

    await dispatchRefreshUser();

    this.sortChannels();

    const { all } = await Content.get({
      limit: 13,
      type: "article",
      top_items: true,
      date_after: moment().subtract(24, "month").format("YYYY-MM-DD"),
    });

    this.setState({
      articles: all,
      isLoading: false,
    });

    this.timeoutID = setInterval(() => this.getContent(), 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  sortChannels = () => {
    const { channels, user } = this.props;

    let prependChannels = [];

    user.channels.forEach((channel: any) => {
      const found = find(channels, { slug: channel });
      // @ts-ignore
      if (found) prependChannels = [found, ...prependChannels];
    });

    const organisation = {
      slug: "organisation",
      // @ts-ignore
      name: first(user.organisations)?.name,
    };

    this.setState({
      // @ts-ignore
      userChannels: [organisation, ...prependChannels.reverse()],
    });
  };

  public getContent = async () => {
    const { channels, userChannels, currentChannelIndex } = this.state;

    const channel = userChannels[currentChannelIndex];

    // Run out of channels, dont request any more data
    if (!channel) {
      return this.setState({ loadMoreContent: false });
    }

    const { all } = await Content.get(
      merge(
        {
          limit: 7,
          // @ts-ignore
          channel: channel.slug,
          date_after: moment().subtract(24, "month").format("YYYY-MM-DD"),
        },
        channel.slug === "organisation" && { organisational: true }
      )
    );

    // If the request was empty then
    // call for data again
    if (!all.length) {
      return this.setState(
        {
          currentChannelIndex: currentChannelIndex + 1,
        },
        async () => {
          this.getContent();
        }
      );
    }

    return this.setState({
      channels: [
        ...channels,
        {
          // @ts-ignore
          name: channel.name,
          // @ts-ignore
          color: channel.color,
          content: all,
        },
      ],
      currentChannelIndex: currentChannelIndex + 1,
    });
  };

  render() {
    const { user } = this.props;
    const { articles, channels, isLoading, loadMoreContent } = this.state;

    if (isLoading) return <Loader />;

    return (
      <Fragment>
        <Helmet>
          <title>Dashboard - {process.env.REACT_APP_COMPANY_NAME}</title>
        </Helmet>
        <ContentBlockPrimary className="mb-4" articles={articles} />
        {channels.map((channel, key: number) => (
          // @ts-ignore
          <ContentBlock
            key={key}
            columns={5}
            direction="rtl"
            className="mb-4"
            hideChannels={true}
            hideAbstract={true}
            title={channel?.name}
            color={channel?.color}
            featuredPostColumns={2}
            articles={channel?.content}
            slug={slugify(channel?.name ?? "")}
          />
        ))}
        <TrackVisibility isActive={loadMoreContent} onVisible={this.getContent}>
          <Loader />
        </TrackVisibility>
        <LoginCounterDialog />
        <HelplineModal scores={user?.scores} />
        <ShoutAppBubble message={shoutAppMessages} />
      </Fragment>
    );
  }
}

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
);
