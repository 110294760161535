import { useEffect } from "react";

/**
 * Runs the specified callback once the component mounts.
 *
 * @param {() => void} callback
 */
export const useMount = (callback: () => void) =>
  // eslint-disable-next-line
  useEffect(() => callback(), []);

export default useMount;
