import React from "react";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";

import AppStyles from "../styles/AppStyles";
import ThemeStyles from "../styles/ThemeStyles";

// eslint-disable-next-line
const withProviders = (WrappedComponent: React.ComponentType<any>) => (
  // eslint-disable-next-line no-undef
  props: unknown
) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <ThemeProvider
    theme={{
      breakpointSmall: "700px",
      breakpointMedium: "900px",
      breakpointLarge: "1100px",
      breakpointExtraSmall: "500px",
    }}
  >
    <IntlProvider locale="en">
      <>
        <ThemeStyles />
        <AppStyles />
        <WrappedComponent {...props} />
      </>
    </IntlProvider>
  </ThemeProvider>
);

export default withProviders;
