import { WellnessResultsInterface } from "@interfaces/WellnessResultsInterface";

export const DUMMY_ENGAGEMENT = [
  {
    name: "Education",
    value: 46,
  },
  {
    name: "Entertainment",
    value: 56,
  },
  {
    name: "Family & Relationships",
    value: 142,
  },
  {
    name: "Food",
    value: 114,
  },
  {
    name: "LGBTQ+",
    value: 10,
  },
  {
    name: "Mental health",
    value: 302,
  },
  {
    name: "Money",
    value: 66,
  },
  {
    name: "Neurodiversity",
    value: 32,
  },
  {
    name: "Opinion",
    value: 81,
  },
  {
    name: "Physical health",
    value: 146,
  },
  {
    name: "Sleep",
    value: 100,
  },
  {
    name: "Sport",
    value: 80,
  },
  {
    name: "Travel",
    value: 43,
  },
  {
    name: "Work",
    value: 132,
  },
];

export const DUMMY_WELLNESS: WellnessResultsInterface = {
  results: [
    {
      answer: "15.0",
      question_number: "1",
      text: "I have had trouble sleeping",
    },
    {
      answer: "55.0",
      question_number: "2",
      text: "I have felt stressed, tense or anxious",
    },
    {
      answer: "30.0",
      question_number: "3",
      text: "I have felt distracted from the task at hand",
    },
    {
      answer: "20.0",
      question_number: "4",
      text: " I tended to over-react to situations",
    },
    {
      answer: "21.0",
      question_number: "5",
      text: "I have felt something bad is about to happen",
    },
    {
      answer: "35.0",
      question_number: "6",
      text: " I have felt down or depressed",
    },
    {
      answer: "26.0",
      question_number: "7",
      text: "I have had little interest or pleasure in doing things",
    },
    {
      answer: "18.0",
      question_number: "8",
      text: " I have felt able to cope when things don’t go my way",
    },
    {
      answer: "43.0",
      question_number: "9",
      text: " I tended to criticise myself and not feel OK about myself",
    },
    {
      answer: "58.0",
      question_number: "10",
      text: "I am caring and supportive of myself",
    },
    {
      question_number: 11,
      total: 50,
      answers: {
        mental_health: 3,
        my_family: 6,
        my_body_image: 7,
        ageing: 2,
        physical_health: 5,
        my_relationships: 2,
        issues_with_management: 3,
        issues_with_coworkers: 2,
        job_security: 7,
        my_finances: 7,
        deadlines: 2,
        my_sleep: 2,
        lgbt_issues: 1,
        term_illness: 1,
      },
    },
  ],
  userCount: 50,
  count: 150,
};

export const DUMMY_INDICATORS = {
  graph: [
    {
      date: "2019-06-01",
      stress: 0,
      anxiety: 0,
      depression: 0,
    },
    {
      date: "2019-07-01",
      stress: 65.2,
      anxiety: 60,
      depression: 58,
    },
    {
      date: "2019-08-01",
      stress: 90,
      anxiety: 50,
      depression: 50,
    },
    {
      date: "2019-09-01",
      stress: 100,
      anxiety: 72,
      depression: 79,
    },
    {
      date: "2019-10-01",
      stress: 90,
      anxiety: 72,
      depression: 75,
    },
    {
      date: "2019-11-01",
      stress: 80,
      anxiety: 72,
      depression: 70,
    },
    {
      date: "2019-12-01",
      stress: 70,
      anxiety: 60,
      depression: 72,
    },
    {
      date: "2020-01-01",
      stress: 75,
      anxiety: 65,
      depression: 60,
    },
    {
      date: "2020-02-01",
      stress: 69,
      anxiety: 62,
      depression: 58,
    },
    {
      date: "2020-03-01",
      stress: 65,
      anxiety: 60,
      depression: 50,
    },
    {
      date: "2020-04-01",
      stress: 50,
      anxiety: 55,
      depression: 50,
    },
    {
      date: "2020-05-01",
      stress: 40,
      anxiety: 50,
      depression: 55,
    },
    {
      date: "2020-06-01",
      stress: 30,
      anxiety: 40,
      depression: 45,
    },
  ],
  pie: [
    {
      name: "Stress",
      value: 0,
    },
    {
      name: "Anxiety",
      value: 0,
    },
    {
      name: "Depression",
      value: 0,
    },
  ],
};
