import React from "react";

import Wrapper from "../../components/managers/Wrapper";
import TabsInvitationsContainer from "./overview/ManagersOverviewInvitationsContainer";

const ManagersInvitationsContainer = () => (
  <Wrapper>
    <TabsInvitationsContainer />
  </Wrapper>
);

export default ManagersInvitationsContainer;
