import styled from "styled-components";

export default styled.div`
  padding: 0 0 2.5rem;

  ul {
    margin: 0;
    padding: 0 0 0 1rem;

    li {
      margin: 0 0 0.8rem;
    }
  }
`;
