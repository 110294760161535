import React, { ReactNode } from "react";

interface IAgency {
  src: string;
  href: string;
  title: string;
  data: ReactNode[];
}

export const SUPPORT_AGENCIES: IAgency[] = [
  {
    title: "Samaritans",
    href: "https://www.samaritans.org/",
    src: "samaritans.png",
    data: [
      "For everyone",
      <>
        Call <a href="tel:116123">116 123</a>
      </>,
      "24/7",
    ],
  },
  {
    title: "Campaign Against Living Miserably (CALM)",
    href: "https://www.thecalmzone.net/",
    src: "calm.png",
    data: [
      "For men",
      <>
        Call <a href="tel:0800585858">0800 58 58 58</a>
      </>,
      "5pm to midnight every day",
    ],
  },

  {
    title: "Papyrus",
    href: "https://www.papyrus-uk.org/",
    src: "papyrus.png",
    data: [
      "For people under 35",
      <>
        Call <a href="tel:08000684141">0800 068 41 41</a>
      </>,
      <>
        Text <a href="tel:07860039967">07860 039 967</a>
      </>,
      "9am to midnight every day",
    ],
  },

  {
    title: "Childline",
    href: "https://www.childline.org.uk/",
    src: "childline.png",
    data: [
      "For children and young people under 19",
      <>
        Call <a href="tel:08001111">0800 1111</a>
      </>,
      "24/7",
    ],
  },

  {
    title: "Mind",
    src: "mind.png",
    href: "https://www.mind.org.uk/",
    data: [
      "For everyone",
      <>
        Call <a href="tel:03001233393">0300 123 3393</a>
      </>,
      "9am - 6pm Mon-Fri",
    ],
  },

  {
    title: "Shout",
    src: "shout.png",
    href: "https://giveusashout.org/",
    data: [
      "For everyone",
      <>
        Text 'SHOUT' to <a href="sms:85258">85258</a>
      </>,
      "24/7",
    ],
  },
];
