import styled from "styled-components";
import React, { Component } from "react";

interface Props {
  as?: any;
  children: any;
  color?: string;
  className?: string;
}

export default class TitleSecondary extends Component<Props> {
  static defaultProps = {
    as: null,
    color: "",
    className: "",
  };

  render() {
    const { className, color, children, as } = this.props;

    return (
      <StyledTitleSecondary className={className} color={color} as={as}>
        {children}
      </StyledTitleSecondary>
    );
  }
}

const StyledTitleSecondary = styled.h2`
  margin-top: 0;
  margin-bottom: 0.5rem;
`;
