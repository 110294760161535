import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import ChannelCard from "./ChannelCard";

interface Props {
  channels: any;
  organisation?: any;
  onClick?: () => any;
}

const NavChannels = ({ channels, onClick }: Props) => {
  // eslint-disable-next-line no-shadow
  const { organisation } = useSelector(({ organisation }: Props) => ({
    organisation,
  }));

  return (
    <StyledNavChannels hasBrandContent={Boolean(organisation.brand_content)}>
      {organisation.brand_content && (
        <StyledNavChannelsItem>
          <StyledNavChannelsLink onClick={onClick} to="/channel/organisation">
            <ChannelCard name={organisation.name} slug="organisation" />
          </StyledNavChannelsLink>
        </StyledNavChannelsItem>
      )}

      {channels.map((channel: any) => (
        <StyledNavChannelsItem key={channel.slug}>
          <StyledNavChannelsLink
            onClick={onClick}
            to={`/channel/${channel.slug}`}
            id={`test__nav-channel-${channel.slug}`}
          >
            <ChannelCard name={channel.name} slug={channel.slug} />
          </StyledNavChannelsLink>
        </StyledNavChannelsItem>
      ))}
    </StyledNavChannels>
  );
};

const StyledNavChannels = styled.ul<{ hasBrandContent: boolean }>`
  left: 0;
  margin: 0;
  z-index: 2;
  width: 770px;
  display: grid;
  top: calc(3rem);
  background: #fff;
  grid-gap: 0 1rem;
  list-style: none;
  padding: 0.5rem 0;
  position: absolute;
  color: var(--brandColorPrimary);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  grid-template-columns: repeat(auto-fill, minmax(auto, 235px));

  @media (max-width: ${({ theme }) => theme.breakpointLarge}) {
    width: 100%;
    display: block;
  }
`;

const StyledNavChannelsItem = styled.li`
  display: flex;
  white-space: nowrap;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: ${({ theme }) => theme.breakpointLarge}) {
    width: 100%;
  }
`;

const StyledNavChannelsLink = styled(Link)`
  width: 100%;
  padding: 0.5rem 1rem;
`;

export default NavChannels;
