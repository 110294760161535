/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";

/**
 * This serves as a wrapper for the
 * report egenrator to focus around
 */
const PrintableWrapper = ({ children, report, name }: any) => (
  <StyledPrintableWrapper id="printable" data-report={report} data-name={name}>
    {children}
  </StyledPrintableWrapper>
);

const StyledPrintableWrapper = styled.div`
  /** Fix for recharts adding floating legend */
  min-height: 800px;
`;

export default PrintableWrapper;
