import { SET_PROCESSING } from "../reducers/processing";

/**
 * Update processing
 * @param {boolean} processing value of processing variable
 */
export const setProcessing = (processing) => (dispatch) =>
  dispatch({
    type: SET_PROCESSING,
    payload: processing,
  });

export default setProcessing;
