import { IconNames } from "@blueprintjs/icons";
import styled, { css } from "styled-components";
import { Button, Icon, Spinner } from "@blueprintjs/core";
import { get, isEmpty, sortBy, startCase } from "lodash-es";
import React, { useState, useEffect, Fragment } from "react";

import { Pagination } from "@components/Pagination";

interface Column<T> {
  /** Object key that should be used, by default this becomes the heading title */
  key: keyof T & string;
  /** Set a custom heading name */
  heading?: string | JSX.Element;
  /** The max width of a column */
  width?: string;
  /**
   * Should hide the column
   *
   * This is often used for if a breakpoint is met
   */
  hide?: boolean;
  isSortable?: boolean;
  /**
   * Format data before it's sorted,
   * useful in situation where you may need to convert
   * strings into numbers to get proper formatting.
   *
   * @todo Changing this to anything besides `any` seems to
   * break the generic for the `key` element (a bug with TS?)
   */
  sortableFormatter?: (value: any) => string | number;
}

interface Responsive {
  isResponsive?: boolean;
}

interface Props<T> extends Responsive {
  data: T[];
  className?: string;
  isLoading?: boolean;
  hasSearch?: boolean;
  columns: Column<T>[];
  currentPage?: number;
  isResponsive?: boolean;
  style?: React.CSSProperties;
  onRowClick?: (data: T) => void;
  onPageChange?: (page: number) => void;
  /**
   * Show an extra expand button on the end of a row
   * Which allows additional hidden content to be viewed
   */
  renderExpandableElement?: (data: T) => React.ReactNode;
}

export const DataTable = <T extends {}>({
  style,
  columns,
  className,
  hasSearch,
  currentPage,
  onPageChange,
  isLoading = false,
  data: originalData,
  isResponsive = true,
  renderExpandableElement,
  onRowClick = () => null,
}: Props<T>): JSX.Element => {
  const [data, setData] = useState<T[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [expandedRowKey, setExpandedRowKey] = useState<number>();
  const [sortableColumns, setSortableColumns] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    setData(originalData);
  }, [originalData]);

  /**
   * Display a loading state for the data table.
   *
   * @param  {Column<T>[]} data
   * @return {React.ReactNode}
   */
  function renderLoadingState(columns: Column<T>[]): React.ReactNode {
    return (
      <>
        <tr>
          <>
            <StyledDataTableCell
              // eslint-disable-next-line
              colSpan={columns.length}
            >
              <StyledLoadingContainer>
                <p className="pt-4 pb-4">
                  <Spinner size={32} className="mb-2" />
                  Loading...
                </p>
              </StyledLoadingContainer>
            </StyledDataTableCell>
          </>
        </tr>
      </>
    );
  }

  /**
   * Set the filter search term
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e
   * @returns {void}
   */
  function handleSearch(e: React.ChangeEvent<HTMLInputElement>): void {
    setSearchFilter(e.target.value);
  }

  /**
   * Sort the data by the specified column.
   *
   * @param {T[]} data
   * @param {string} column
   * @returns {void}
   */
  function sort(data: T[], column: Column<T>): void {
    const { key, sortableFormatter } = column;

    // Run the data through the sortable formatter(if needed)
    const convertedData = data.map((row) => {
      return {
        ...row,
        [key]: sortableFormatter ? sortableFormatter(row[key]) : row[key],
      };
    });

    const sortedData = sortBy(convertedData, [key]);

    // Reverse the columns if it's the second time sorting
    if (!sortableColumns[key]) {
      sortedData.reverse();
    }

    setData(sortedData);

    setSortableColumns((state) => {
      return {
        ...state,
        ...{
          [key]: !state[key],
        },
      };
    });
  }

  /**
   * Filter rows based on the specified search term
   *
   * @param  {T[]} data
   * @return {T[]}
   */
  function filterData(data: T[]): T[] {
    // The filter is blank, return everything
    if (!searchFilter) {
      return data;
    }

    return data.filter((property: T) =>
      Object.values(property).some((value): boolean => {
        // If it's a jsx element get the inner text
        const jsxText = get(value, "props.children");

        const valueToFilter =
          !isEmpty(jsxText) && typeof jsxText === "string"
            ? jsxText.toLowerCase()
            : value;

        return String(valueToFilter).includes(searchFilter);
      })
    );
  }

  /**
   * Convert text into the PascalCase format
   *
   * @param  {string} text
   * @return {string}
   */
  function pascalCase(text: string): string {
    return startCase(text.replace(/[^\w\s]/gi, " ").replace(/_/g, " "));
  }

  /**
   * Determines coloumn name
   *
   * @param {Column<T>} column
   * @returns {string | JSX.Element}
   */
  function getColumnName(column?: Column<T>): string | JSX.Element {
    if (column.heading) {
      return column.heading;
    }

    if (isEmpty(column.heading)) {
      if (column.heading === "") {
        return "";
      }
    }

    return pascalCase(column.key);
  }

  const filteredData = filterData(data);

  return (
    <>
      <StyledDataTableContainer className={className} style={style}>
        {hasSearch && (
          <StyledDataTableSearch
            type="search"
            placeholder="Search..."
            onChange={handleSearch}
          />
        )}
        <StyledDataTable>
          <thead>
            <StyledDataTableRow isResponsive={isResponsive}>
              {columns.map((column: Column<T>, key: number) =>
                column.hide ? null : (
                  <StyledDataTableHeaderCell
                    key={key}
                    onClick={() =>
                      column.isSortable ? sort(data, column) : null
                    }
                    isResponsive={isResponsive}
                    isSortable={column.isSortable}
                    columnWidth={column.width ? column.width : undefined}
                  >
                    {getColumnName(column)}
                    {column.isSortable && (
                      <StyledDataTableSortIcon
                        direction={sortableColumns[column.key]}
                      />
                    )}
                  </StyledDataTableHeaderCell>
                )
              )}
              {renderExpandableElement && (
                <StyledDataTableHeaderCell isResponsive={isResponsive} />
              )}
            </StyledDataTableRow>
          </thead>
          <StyledDataTableBody>
            {isLoading ? (
              renderLoadingState(columns)
            ) : (
              <>
                {filteredData.map((row, rowKey: number) => (
                  <Fragment key={rowKey}>
                    <StyledDataTableRow
                      isResponsive={isResponsive}
                      onClick={() => onRowClick(row)}
                    >
                      <>
                        {columns.map((column, columnKey: number) =>
                          column.hide ? null : (
                            <StyledDataTableCell
                              key={columnKey}
                              isResponsive={isResponsive}
                              columnWidth={
                                column.width ? column.width : undefined
                              }
                            >
                              {row[column.key]}
                            </StyledDataTableCell>
                          )
                        )}
                        {renderExpandableElement && (
                          <StyledDataTableCell isResponsive={isResponsive}>
                            <StyledButton
                              className="ml-a d-flex"
                              icon={IconNames.CHEVRON_DOWN}
                              onClick={() =>
                                setExpandedRowKey(
                                  rowKey === expandedRowKey ? null : rowKey
                                )
                              }
                            />
                          </StyledDataTableCell>
                        )}
                      </>
                    </StyledDataTableRow>
                    {rowKey === expandedRowKey && (
                      <>
                        {/** Use a blank row so the expanded section is the same color as the previous row */}
                        <StyledDataTableRow />
                        <StyledDataTableRow>
                          <StyledDataTableCell colSpan={columns.length + 1}>
                            {renderExpandableElement(row)}
                          </StyledDataTableCell>
                        </StyledDataTableRow>
                      </>
                    )}
                  </Fragment>
                ))}
              </>
            )}
            {isEmpty(filteredData) && !isLoading && (
              <StyledDataTableRow isResponsive={isResponsive}>
                <StyledDataTableNoResults>
                  No matching records found
                </StyledDataTableNoResults>
              </StyledDataTableRow>
            )}
          </StyledDataTableBody>
        </StyledDataTable>
      </StyledDataTableContainer>
      {onPageChange && (
        <Pagination
          className="mt-4"
          key={currentPage}
          isLoading={isLoading}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
};

const StyledDataTableSortIcon = ({
  direction,
}: {
  direction: boolean | undefined;
}) => (
  <Icon
    style={{ margin: "0 0 0.15rem 0.4rem" }}
    icon={(() => {
      if (direction === undefined) {
        return IconNames.DOUBLE_CARET_VERTICAL;
      }

      return direction ? IconNames.CARET_DOWN : IconNames.CARET_UP;
    })()}
  />
);

const StyledDataTableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  transition: 0.2s ease;
  box-shadow: var(--box-shadow);
  -webkit-overflow-scrolling: touch;
`;

const StyledDataTable = styled.table`
  width: 100%;
  background: #fff;
  border-spacing: 0;
  border-radius: 2px;
  border-collapse: initial;
  border: 1px solid var(--borderColor);
`;

const StyledDataTableHeaderCell = styled.th<
  Responsive & { columnWidth?: string; isSortable?: boolean }
>`
  border: 0;
  color: #fff;
  text-align: left;
  font-weight: bold;
  user-select: none;
  white-space: nowrap;
  padding: 12px 12px 10px;
  width: ${({ columnWidth }) => columnWidth};
  background: var(--brandColorPrimary);
  border-bottom: 1px solid var(--borderColor);
  :not(:first-child) {
    box-shadow: inset 1px 0 0 0 rgba(16, 22, 26, 0.15);
  }

  ${({ isSortable }) =>
    isSortable &&
    css`
      cursor: pointer;
    `}

  ${({ isResponsive }) =>
    isResponsive &&
    css`
      @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
        display: none;
      }
    `}
`;

const StyledDataTableBody = styled.tbody`
  tr {
    :nth-child(odd) {
      background: rgba(191, 204, 214, 0.15);
    }
  }
`;

const StyledDataTableRow = styled.tr<Responsive>`
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      &:hover {
        ${StyledDataTableCell} {
          background: #ecf0f3;
        }
      }
    `}

  ${({ isResponsive }) =>
    isResponsive &&
    css`
      @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        &:not(:last-child) {
          border-bottom: 1px solid var(--borderColor);
        }
      }
    `}
`;

const StyledDataTableCell = styled.td<Responsive & { columnWidth?: string }>`
  padding: 1rem;
  overflow: hidden;
  vertical-align: top;
  text-overflow: ellipsis;
  width: ${({ columnWidth }) => columnWidth};

  :not(:first-child) {
    box-shadow: inset 1px 0 0 0 rgba(16, 22, 26, 0.15);
  }

  ${({ isResponsive }) =>
    isResponsive &&
    css`
      @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
        width: 100%;
        height: auto;
      }
    `}
`;

const StyledDataTableNoResults = styled(StyledDataTableCell).attrs({
  colSpan: 100,
})`
  padding: 2rem;
  text-align: center;
`;

const StyledDataTableSearch = styled.input`
  max-width: 15rem;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  &&& {
    min-height: 32px;
    padding: 5px;
    min-width: 35px;
  }
`;

const StyledLoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  & > p {
    margin: auto 1rem;
  }
`;
