import styled from "styled-components";
import { Redirect } from "react-router";
import React, { Fragment } from "react";
import { startCase, upperCase } from "lodash";

import { CHANNEL_ACRONYMS } from "@constants/channels";

interface Props {
  channels: any;
}

class ChannelLinks extends React.Component<Props> {
  state = {
    activeChannelSlug: "",
    redirectToChannel: false,
  };

  createChannelRedirection = (slug: string) => {
    this.setState({ activeChannelSlug: slug, redirectToChannel: true });
  };

  render() {
    const { channels } = this.props;
    const { redirectToChannel, activeChannelSlug } = this.state;

    if (redirectToChannel) {
      return <Redirect to={`/channel/${activeChannelSlug}`} />;
    }

    return (
      <Fragment>
        {channels?.slice(0, 3).map((channel: any) => (
          <StyledChannelLinks
            key={channel.id}
            onClick={() => this.createChannelRedirection(channel.slug)}
          >
            {CHANNEL_ACRONYMS.includes(channel.slug)
              ? upperCase(channel.slug)
              : startCase(channel.slug)}
          </StyledChannelLinks>
        ))}
      </Fragment>
    );
  }
}

const StyledChannelLinks = styled.div`
  margin-right: 0.5rem;
  display: inline-block;
  text-decoration: none;
  color: var(--brandColorPrimary);

  &:hover {
    color: black;
    cursor: pointer;
  }
`;

export default ChannelLinks;
