import moment, { Moment } from "moment";
import { Select } from "@blueprintjs/select";
import useReactRouter from "use-react-router";
import { IconNames } from "@blueprintjs/icons";
import styled, { css } from "styled-components";
import React, { useState, useEffect } from "react";
import { flatten, merge, startCase, isEmpty } from "lodash";
import { Alert, Button, Intent, FormGroup, MenuItem } from "@blueprintjs/core";

import Toast from "@utils/Toast";
import Loader from "@components/Loader";
import Enums from "@utils/managers/Enums";
import Wrapper from "@components/managers/Wrapper";
import ContentType from "@enums/managers/ContentType";
import InputUnderline from "@components/InputUnderline";
import DatePicker from "@components/managers/DatePicker";
import ContentStatus from "@enums/managers/ContentStatus";
import VideoPreview from "@components/managers/VideoPreview";
import ImageUploader from "@components/managers/ImageUploader";
import MarkdownEditor from "@components/managers/MarkdownEditor";
import ManagersContentInterface from "@interfaces/managers/ManagersContentInterface";
import ManagersContentPutCreateInterface from "@interfaces/managers/ManagersContentPutCreateInterface";

import {
  putContent,
  createContent,
  deleteContent,
  getContentSingle,
} from "@store/actions/managers/content";

const ManagersContentCreateEditContainer = () => {
  const {
    history,
    match: {
      params: { slug, prefill_type },
    },
  } = useReactRouter<{ slug: string; prefill_type: ContentType }>();

  const [isEdit, setIsEdit] = useState(true);

  /** Determines through the route param */
  const [contentType, setContentType] = useState<ContentType>(
    prefill_type || ContentType.article
  );

  const [content, setContent] = useState("");
  const [headline, setHeadline] = useState("");
  const [abstract, setAbstract] = useState("");
  const [contentId, setContentId] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [cloudinaryId, setCloudinaryId] = useState("");
  const [podcastHomepage, setPodcastHomepage] = useState("");
  const [status, setStatus] = useState<ContentStatus>(ContentStatus.draft);

  /** Can be `null` live/drafts will not require this */
  const [publishAt, setPublishAt] = useState<Moment | null>();

  /** Not editable but needed for backend */
  const [topics, setTopics] = useState<string[]>([]);
  const [channels, setChannels] = useState<string[]>([]);

  useEffect(() => {
    if (!slug) {
      /**
       * Slug has not passed to this screen meaning
       * is a new content creation
       */
      setIsEdit(false);
    } else {
      getContentSingle(slug).then((response: ManagersContentInterface) => {
        if (response) {
          const tempType = response.type as ContentType;

          setIsEdit(true);
          setContentType(tempType);
          setHeadline(response.headline);
          setAbstract(response.abstract);
          setContentId(response.article_id);
          setCloudinaryId(response.image_id);
          setTopics(flatten(response.topicList));
          setChannels(flatten(response.channelList));
          response.publish_at && setPublishAt(moment(response.publish_at));

          if (tempType === ContentType.article) {
            setContent(response.content);
          }

          if (tempType === ContentType.podcast) {
            setVideoLink(response.podcast_file_url);
            setPodcastHomepage(response.podcast_homepage);
          }

          if (tempType === ContentType.video) {
            setVideoLink(response.video_link);
          }

          /**
           * When a publish_at date is set then this is technically
           * a "Scheduled" piece of content but the backend
           * doesn't support this type
           */
          setStatus(
            response.status === ContentStatus.draft.toLowerCase() &&
              response.publish_at
              ? ContentStatus.scheduled
              : (response.status as ContentStatus)
          );
        } else {
          /** Content not found from slug */
          history.push("/managers/content/article");
        }
      });
    }
    // eslint-disable-next-line
  }, [slug, history]);

  /**
   * Update content
   *
   * @param {React.FormEvent<HTMLFormElement>} event
   * @return {Promise<void>}
   */
  async function handleSubmit(
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> {
    event.preventDefault();

    /**
     * This acts as both the save and creation data object
     * it is merged together dependant on the content type
     */
    const data: ManagersContentPutCreateInterface = merge(
      {
        topics,
        channels,
        abstract,
        /** `null` will reset the published state if changed */
        publish_at:
          publishAt && status === ContentStatus.scheduled
            ? publishAt.format("YYYY-MM-DD HH:mm:ss")
            : null,
        /**
         * Converts from an enum and "Scheduled" is front end term
         * will revert back to live with a  publish_at date
         */
        status:
          status !== ContentStatus.scheduled ? status : ContentStatus.draft,
      },

      /** Article specific */
      contentType === ContentType.article && {
        content,
        headline,
        title: headline,
        image_id: cloudinaryId,
      },

      /** Podcast specific */
      contentType === ContentType.podcast && {
        headline,
        title: headline,
        image_id: cloudinaryId,
        podcast_link: videoLink,
        homepage_link: podcastHomepage,
      },

      /** Video specific */
      contentType === ContentType.video && {
        headline,
        title: headline,
        youtube_url: videoLink,
      }
    );

    isEdit
      ? await putContent({
          type: contentType,
          contentId: contentId,
          data: data,
        }).then(() => history.replace(`/managers/content/${contentType}`))
      : /** After creating content then redirect to edit screen */
        await createContent(contentType, data).then(async (slug) => {
          if (slug) {
            /**
             * Since this part of the system is event sourced we need to
             * only proceed when content has actually been created
             *
             * @todo needs a promise loop or socket here
             */
            setTimeout(
              () =>
                getContentSingle(slug).then(
                  (createdContent: ManagersContentInterface) =>
                    !isEmpty(createdContent) &&
                    history.replace(`/managers/content/${contentType}`)
                ),
              500
            );
          }
        });
  }

  return (
    <Wrapper>
      {!content && contentType === ContentType.article && isEdit ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit} noValidate={true}>
          <StyledGrid>
            <div>
              <InputUnderline
                type="text"
                large={false}
                maxLength={75}
                name="headline"
                label="Title"
                lightBorder={true}
                defaultValue={headline}
                // @ts-ignore
                onChange={(name, value) => setHeadline(value)}
              />

              <InputUnderline
                type="text"
                large={false}
                name="abstract"
                maxLength={150}
                lightBorder={true}
                label="Overview"
                defaultValue={abstract}
                // @ts-ignore
                onChange={(name, value) => setAbstract(value)}
              />

              {contentType === ContentType.podcast && (
                <InputUnderline
                  type="url"
                  large={false}
                  lightBorder={true}
                  name="podcastHomepage"
                  label="Homepage URL"
                  defaultValue={podcastHomepage}
                  // @ts-ignore
                  onChange={(name, value) => setPodcastHomepage(value)}
                />
              )}

              {[ContentType.podcast, ContentType.video].includes(
                contentType
              ) && (
                <InputUnderline
                  type="url"
                  large={false}
                  name="videoLink"
                  lightBorder={true}
                  defaultValue={videoLink}
                  label={
                    contentType === ContentType.video
                      ? "YouTube / Vimeo URL"
                      : "YouTube / SoundCloud URL"
                  }
                  // @ts-ignore
                  onChange={(name, value) => setVideoLink(value)}
                />
              )}

              <StyledStatusGroup
                isSplit={Boolean(status === ContentStatus.scheduled)}
              >
                <FormGroup intent={Intent.NONE} label="Status">
                  <Select
                    itemRenderer={(status: string) => (
                      <MenuItem
                        key={status}
                        text={status}
                        onClick={() => setStatus(status as ContentStatus)}
                      />
                    )}
                    filterable={false}
                    items={Enums.getValues(ContentStatus) as string[]}
                    onItemSelect={(item: any) =>
                      setStatus(item as ContentStatus)
                    }
                  >
                    <Button
                      fill={true}
                      text={startCase(status)}
                      rightIcon="double-caret-vertical"
                    />
                  </Select>

                  {status === ContentStatus.scheduled && (
                    <StyledExplination>
                      <div className="pb-2">
                        <strong>Please note:</strong>
                      </div>
                      By setting a scheduled publish date we will automatically
                      push this content live to your entire organisation on the
                      date and time you specify.
                    </StyledExplination>
                  )}
                </FormGroup>

                {status === ContentStatus.scheduled && (
                  <DatePicker
                    onChange={(date: Date) => setPublishAt(moment(date))}
                    value={publishAt ? publishAt.toDate() : moment().toDate()}
                  />
                )}
              </StyledStatusGroup>
            </div>

            <div>
              <FormGroup
                className="pb-2"
                intent={Intent.NONE}
                label="Content Type"
              >
                <Select
                  itemRenderer={(type: string) => (
                    <MenuItem
                      key={type}
                      text={startCase(type)}
                      onClick={() => setContentType(type as ContentType)}
                    />
                  )}
                  filterable={false}
                  items={Enums.getValues(ContentType) as string[]}
                  onItemSelect={(item: any) =>
                    setContentType(item as ContentType)
                  }
                >
                  <Button
                    fill={true}
                    text={startCase(contentType)}
                    rightIcon="double-caret-vertical"
                  />
                </Select>
              </FormGroup>

              {contentType !== ContentType.video && (
                <FormGroup
                  intent={Intent.NONE}
                  className="pb-2"
                  label="Featured Image"
                >
                  <ImageUploader
                    className="mt-2"
                    onChange={setCloudinaryId}
                    cloudinaryImageID={cloudinaryId}
                  />
                </FormGroup>
              )}

              {videoLink && (
                <FormGroup intent={Intent.NONE} label="Preview">
                  <VideoPreview link={videoLink} />
                </FormGroup>
              )}
            </div>
          </StyledGrid>

          {contentType === ContentType.article && (
            <MarkdownEditor
              value={content}
              isNewContent={!isEdit}
              onChange={(value) => setContent(value)}
            />
          )}

          <StyledButtonGroup>
            <Button
              large
              type="submit"
              intent={Intent.PRIMARY}
              rightIcon="chevron-right"
            >
              {
                /** Creates a singular term (e.g Create) */
                `${isEdit ? "Save" : "Create"} ${startCase(
                  contentType as string
                )}`
              }
            </Button>

            {isEdit && (
              <Button
                large
                type="button"
                intent={Intent.NONE}
                onClick={() => setDeleteOpen(true)}
              >
                Delete
              </Button>
            )}

            <Alert
              isOpen={deleteOpen}
              icon={IconNames.TRASH}
              intent={Intent.PRIMARY}
              canEscapeKeyCancel={true}
              cancelButtonText="Cancel"
              confirmButtonText="Delete"
              canOutsideClickCancel={true}
              onCancel={() => setDeleteOpen(false)}
              onConfirm={() =>
                deleteContent(contentId).then(() => {
                  /** Notify user and redirect */
                  history.push(`/managers/content/${contentType}`);
                  Toast.success({
                    message: "Content was successfully deleted",
                  });
                })
              }
            >
              Are you sure you wish to delete this content? This action can not
              be undone.
            </Alert>
          </StyledButtonGroup>
        </form>
      )}
    </Wrapper>
  );
};

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 3.5rem;
  margin: 1.9rem 0 1rem;
  grid-template-columns: 1fr 400px;

  &&& {
    .bp3-form-helper-text {
      margin-bottom: 0;
    }

    label.bp3-label {
      font-weight: 600;
      color: var(--brandColorPrimary);
    }

    /** Makes dropdown full width */
    .bp3-popover-target {
      width: 100%;
      display: block;

      .bp3-button.bp3-fill {
        padding: 0;
        width: 100%;
        display: flex;
        border-radius: 0;
        box-shadow: none;
        background-image: none;
        background-color: white;
        justify-content: space-between;
        border-bottom: 1px solid var(--borderColor);

        .bp3-icon > svg {
          margin-top: -3px;
          color: var(--borderColor);
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    display: block;
  }
`;

const StyledButtonGroup = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-top: 1.5rem;
  justify-content: start;
  grid-template-columns: auto auto;
`;

const StyledStatusGroup = styled.div<{ isSplit: boolean }>`
  /** When scheduled display datetime */
  ${({ isSplit }) =>
    isSplit &&
    css`
      display: grid;
      grid-gap: 3rem;
      grid-template-columns: 1fr 252px;
    `}
`;

const StyledExplination = styled.div`
  padding-top: 1.5rem;
  color: var(--managersMutedText);
`;

export default ManagersContentCreateEditContainer;
