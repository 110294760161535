import Youtube from "./Youtube";

export default class Image {
  /**
   * Attempt to dynamically get an image if it exists
   *
   * @type {string}
   * @return {string}
   */
  static get = (filename) => {
    let image;

    try {
      image = require(`../assets/images/${filename}`);
    } catch (error) {
      image = null;
    }

    return image;
  };

  /**
   * Get the image for the selected content, this may
   * differ based on the content type. Eg videos
   * need a thumbnail from youtube.
   *
   * @param  {string} url
   * @return {string}
   */
  static generateUrl(url) {
    if (url.includes("youtube")) {
      return `https://img.youtube.com/vi/${Youtube.getIDFromLink(
        url
      )}/hqdefault.jpg`;
    }
    return `${process.env.REACT_APP_CLOUDINARY_URL}c_scale,h_500/${url}`;
  }
}
