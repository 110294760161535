/* eslint-disable camelcase */
import { startCase } from "lodash";
import React, { useState } from "react";
import useReactRouter from "use-react-router";
import styled, { css } from "styled-components";
import { Button, Intent } from "@blueprintjs/core";

import Icon from "../../../components/Icon";
import Wrapper from "../../../components/managers/Wrapper";
import ContentType from "../../../enums/managers/ContentType";
import ContentTable from "../../../components/managers/ContentTable";

const ManagersContentContainer = () => {
  const {
    history,
    match: {
      params: { preselect_type },
    },
  } = useReactRouter<{ preselect_type: ContentType }>();

  const [selectedType, setSelectedType] = useState<ContentType>(
    preselect_type || ContentType.article
  );

  /**
   * Icon name for the content type
   *
   * @param {ContentType} type
   * @return {string} blueprint icon name
   */
  const getTypeIcon = (type: ContentType) => {
    switch (type) {
      case ContentType.article:
        return "label";
      case ContentType.video:
        return "play";
      case ContentType.podcast:
        return "volume-down";
      default:
        return "";
    }
  };

  return (
    <Wrapper>
      <StyledFlex>
        <StyledTypes>
          {[ContentType.article, ContentType.video, ContentType.podcast].map(
            (type: ContentType) => (
              <StyledLink
                key={type}
                isActive={type === selectedType}
                onClick={() => setSelectedType(type)}
              >
                <StyledIcon icon={getTypeIcon(type)} />
                {startCase(type)}
              </StyledLink>
            )
          )}
        </StyledTypes>

        <Button
          large={false}
          intent={Intent.PRIMARY}
          onClick={() =>
            history.push(`/managers/content/create/${selectedType}`)
          }
        >
          {`Create ${startCase(selectedType)}`}
        </Button>
      </StyledFlex>
      <ContentTable type={selectedType} />
    </Wrapper>
  );
};

const StyledFlex = styled.div`
  display: flex;
  margin-top: 1.3rem;
  align-items: center;
  justify-content: space-between;

  &&& {
    .bp3-button {
      min-height: 32px;
    }
  }
`;

const StyledTypes = styled.div`
  > a {
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
`;

const StyledLink = styled.a<{ isActive: boolean }>`
  cursor: pointer;
  font-weight: 400;
  padding: 0.5rem 0;
  text-align: center;
  position: relative;
  margin-right: 2rem;
  white-space: nowrap;
  display: inline-block;

  ${({ isActive }) =>
    isActive &&
    css`
      color: var(--brandColorPrimary);
    `}

  &::after {
    content: "|";
    margin-left: 1rem;
    font-size: 0.9rem;
    position: absolute;
    color: var(--borderColor);
  }
`;

const StyledIcon = styled(Icon)`
  float: left;
  margin: -0.5px 5px 0 0;
`;

export default ManagersContentContainer;
