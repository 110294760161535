import moment from "moment";
import styled from "styled-components";
import { Link } from "react-router-dom";
import React, { Component } from "react";

import ChannelLinks from "./ChannelLinks";
import TitleSecondary from "./TitleSecondary";
import { TruncatedText } from "./TruncatedText";

interface Props {
  article: any;
  title?: string;
  className?: string;
}

export default class ContentBlockFeatured extends Component<Props> {
  state = {};

  static defaultProps = {
    className: "",
    title: "Mein Gebiet",
  };

  render() {
    const { article, className, title } = this.props;

    if (!article) return null;

    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <StyledContentBlockFeatured className={className}>
        <StyledLink to={`/${article.type}/${article.slug}`}>
          <StyledTitleDate>{moment().format("MMMM Do")}</StyledTitleDate>
          <StyledTitlePrimary>{title || ""}</StyledTitlePrimary>
          <TitleSecondary className="mr-4 mt-4">
            {article.headline}
          </TitleSecondary>
          <TruncatedText lines={5} className="mr-4">
            {article.abstract}
          </TruncatedText>
          <ChannelLinks channels={article.channels} />
        </StyledLink>
        <StyledContentBlockImage src={article.image} alt="" />
      </StyledContentBlockFeatured>
    );
  }
}

const StyledContentBlockFeatured = styled.div`
  display: flex;
  grid-area: primary;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    display: block;
    margin-top: 1rem;
  }
`;

const StyledContentBlockImage = styled.img`
  object-fit: cover;
  max-height: 288px;
  max-width: 390px;
  margin-bottom: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    max-width: 100%;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const StyledLink = styled(Link)`
  max-width: 305px;

  &:hover {
    text-decoration: none;
  }

  &:hover h2 {
    text-decoration: underline;
  }
`;

const StyledTitlePrimary = styled.h1`
  width: 305px;
  display: inline;
  line-height: 3rem;
  margin-right: 1rem;
  white-space: normal;
  padding-bottom: 2rem;
  background-size: 6px 3px;
  background-repeat: repeat-x;
  background-position: 0 1.1em;
  color: var(--brandColorPrimary);
  background-image: linear-gradient(
    to right,
    var(--brandColorPrimary) 50%,
    var(--brandColorPrimary) 50%
  );
`;

const StyledTitleDate = styled.h3`
  margin-top: 0;
  font-size: 1.63rem;
  white-space: nowrap;
  margin-bottom: 0.5rem;
  color: var(--brandColorPrimary);

  @media (max-width: ${({ theme }) => theme.breakpointSmall}) {
    top: 2px;
    font-size: 1.3rem;
  }
`;
