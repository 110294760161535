/* eslint no-underscore-dangle: 0 */
import { startCase } from "lodash";

import api from "../api";
import Image from "./Image";

export default class Content {
  /**
   * Normalise the data into a
   * more useable format.
   *
   * @param  {Object} contents
   * @return {Array}
   */
  static normalize = (contents) =>
    contents.map((content) => Content.normalizeSingle(content));

  /**
   * Normalise a single piece of data into a
   * more useable format.
   *
   * @param  {Object} content
   * @return {Array}
   */
  static normalizeSingle = (content) => ({
    slug: content.slug,
    type: content.type,
    date: content.posted_at,
    content: content.content,
    channels: content.channels,
    abstract: content.abstract,
    headline: content.headline,
    article_id: content.article_id,
    isPartnerContent: content.is_partner_content,
    video: content.video_link || content.podcast_file_url,
    topics: content.topicList.map((topic) => startCase(topic)),
    image: Image.generateUrl(
      content.image_id || content.video_link || content.podcast_link || ""
    ),
  });

  /**
   * Parse options into url params.
   *
   * { name: joe, age: 25 } becomes "name=joe&age=25"
   *
   * @param {Object} obj
   * @return {String}
   */
  static _parseOptions = (obj) =>
    Object.entries(obj)
      .map(([key, val]) => {
        if (key === "channel" && val === "organisation") {
          return "";
        }

        return `${key}=${val}`;
      })
      .join("&");

  /**
   * Filter specific content into thier own properties,
   * this is usefull for endpoints that return multiple
   * content types.
   *
   * @param  {Object} response
   * @return {Object}
   */
  static filterContent = (response) => ({
    all: response,
    videos: response.filter((content) => content.type === "video"),
    podcasts: response.filter((content) => content.type === "podcast"),
    articles: response.filter((content) => content.type === "article"),
  });

  /**
   * Get content data form the api and automatically normalize
   * the response.
   *
   * @param  {Object} options
   * @return {Object}
   */
  static get = (options) =>
    api
      .get(`employee/content/search?${Content._parseOptions(options)}`)
      .then(({ data }) => {
        const response = Content.normalize(data?.payload?.content || []);
        return Content.filterContent(response);
      });

  /**
   * Get content data form the api and automatically normalize
   * the response.
   *
   * @param  {Object} options
   * @return {Object}
   */
  static getSingle = (options) =>
    api
      .get(
        `employee/content/${options.type}/${
          options.channel
        }?${Content._parseOptions(options)}`
      )
      .then(({ data }) => {
        const response = data.payload.content;
        return Content.normalizeSingle(response);
      });
}
