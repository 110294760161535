import styled from "styled-components";
import ReactPlayer from "react-player";
import { Dialog } from "@blueprintjs/core";
import React, { PureComponent } from "react";

import Icon from "./Icon";
import ChannelLinks from "./ChannelLinks";

interface Props {
  video: {
    url: string;
    title: string;
    channels: any;
    slug: string;
    type: string;
  };
  onClose: () => void;
}

export default class VideoModal extends PureComponent<Props> {
  static defaultProps = {
    channels: {},
  };

  componentDidUpdate(prevProps: Object) {
    const { video } = this.props;

    // @ts-ignore
    if (video !== prevProps.video) {
      if (video.url !== "") {
        this.changeURL(video.slug, video.title, video.type);
      } else {
        window.history.back();
      }
    }
  }

  /**
   * Change URL to video screen
   * @param  {string} slug video slug for URL
   * @param  {string} title needed for the history data
   * @param  {string} type switch between video/podcast
   * @return {void}
   */
  changeURL = (slug: string, title: string, type: string) => {
    if (slug !== "") window.history.pushState({}, title, `/${type}/${slug}`);
  };

  render() {
    const { video, onClose } = this.props;

    return (
      <Dialog {...this.props} isOpen={Boolean(video.url)}>
        <ReactPlayer width="100%" url={video.url} playing controls />
        <StyledVideoOverview>
          <StyledVideoTitle id="test__video-title">
            {video.title}
          </StyledVideoTitle>
          <StyledVideoButton onClick={onClose}>
            {video.channels.length > 0 && (
              <ChannelLinks channels={video.channels} />
            )}
          </StyledVideoButton>
          <StyledVideoModalButton
            icon="cross"
            iconSize={30}
            onClick={onClose}
          />
        </StyledVideoOverview>
      </Dialog>
    );
  }
}

const StyledVideoOverview = styled.div`
  position: relative;
  padding: 1.3rem 5rem 1.2rem 1.5rem;
`;

const StyledVideoTitle = styled.div`
  font-size: 1.2rem;
  margin: 0 0 0.5rem;
  line-height: 1.8rem;
`;

const StyledVideoModalButton = styled(Icon)`
  top: 11px;
  right: 10px;
  cursor: pointer;
  position: absolute;
`;

const StyledVideoButton = styled.div``;
