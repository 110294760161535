import styled from "styled-components";
import React, { PureComponent } from "react";

import tickOn from "../assets/images/tick-on.png";
import tickOff from "../assets/images/tick-off.png";

interface Props {
  id?: string;
  style?: any;
  ticked: any;
  onClick?: any;
  className?: string;
}

export default class Tick extends PureComponent<Props> {
  static defaultProps = {
    style: {},
    className: "",
  };

  render() {
    const { onClick, ticked, style, id, className } = this.props;

    return (
      <StyledButton
        id={id}
        as="button"
        type="button"
        onClick={onClick}
        style={style}
        className={className}
      >
        <StyledTickImage as="img" src={ticked ? tickOn : tickOff} alt="Tick" />
      </StyledButton>
    );
  }
}

const StyledButton = styled.button`
  padding: 0;
  border: none;
  cursor: pointer;
  margin: -3px 0 0;

  &:hover {
    opacity: 0.7;
  }
`;

const StyledTickImage = styled.img`
  width: 34px;
  height: 34px;
`;
