export const SET_PROCESSING = "SET_PROCESSING";

const initialState = false;

const processing = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROCESSING:
      return action.payload;
    default:
      return state;
  }
};

export default processing;
