import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Button } from "@blueprintjs/core";

import InputUnderline from "../components/InputUnderline";
import ContainerCentered from "../components/ContainerCentered";
import { forgotPassword } from "../store/actions/authentication";

const mapDispatchToProps = (dispatch: any) => ({
  dispatchForgotPassword: (email: any) => dispatch(forgotPassword(email)),
});

class ForgotPasswordContainer extends PureComponent<any> {
  state = {
    email: "",
    loading: false,
    emailIntent: "",
    emailHelperText: "",
  };

  attemptForgotPassword = async () => {
    // @ts-ignore
    const { dispatchForgotPassword } = this.props;
    const { email } = this.state;

    await dispatchForgotPassword(email);

    this.setState({ loading: false });
  };

  handleInputChange = (name: string, value: string) => {
    this.setState({ [name]: value });
  };

  handleFormSubmit(e: Object) {
    // @ts-ignore
    e.preventDefault();

    const { email } = this.state;

    if (!email) {
      this.setState({
        emailIntent: "danger",
        emailHelperText: "You must enter an email address",
      });
    } else {
      this.setState({ loading: true }, this.attemptForgotPassword);
    }
  }

  render() {
    const { loading, email, emailIntent, emailHelperText } = this.state;

    return (
      <ContainerCentered>
        <Helmet>
          <title>Forgot Password - {process.env.REACT_APP_COMPANY_NAME}</title>
        </Helmet>
        <form onSubmit={(e) => this.handleFormSubmit(e)}>
          <h2>So, you forgot your password, never mind...</h2>

          <InputUnderline
            name="email"
            defaultValue={email}
            intent={emailIntent}
            helperText={emailHelperText}
            onChange={this.handleInputChange}
            label="Your login email address"
            onFocus={() =>
              this.setState({ emailIntent: "", emailHelperText: "" })
            }
          />

          <Button
            loading={loading}
            intent="primary"
            type="submit"
            large
            rightIcon="chevron-right"
          >
            Re-send my password
          </Button>
        </form>
      </ContainerCentered>
    );
  }
}

export default connect(null, mapDispatchToProps)(ForgotPasswordContainer);
