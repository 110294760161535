import moment from "moment";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Button, Checkbox, FormGroup } from "@blueprintjs/core";

import api from "@api";
import Toast from "@utils/Toast";
import Image from "@components/Image";
import { Select } from "@components/Select";
import { GENDERS } from "@constants/genders";
import DateInput from "@components/DateInput";
import { getCountries } from "@store/actions/student";
import PasswordStrength from "@utils/PasswordStrength";
import { signIn } from "@store/actions/authentication";
import InputUnderline from "@components/InputUnderline";
import MadWorldLogo from "@assets/images/mad-world-logo.jpg";
import ContainerCentered from "@components/ContainerCentered";

interface IMadWorldSignUpContainer {
  countries: string[];
  getCountryData: () => Promise<any>;
  dispatchSignIn: (data: any) => Promise<any>;
}

const mapDispatchToProps = (dispatch: any) => ({
  getCountryData: () => dispatch(getCountries()),
  dispatchSignIn: (data: any) => dispatch(signIn(data)),
});

const mapStateToProps = ({ countries }: IMadWorldSignUpContainer) => {
  return {
    countries,
  };
};

export const MadWorldSignUpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ countries, getCountryData, dispatchSignIn }: IMadWorldSignUpContainer) => {
  const [dob, setDob] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [terms, setTerms] = useState<boolean>(false);
  const [address, setAddress] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [postcode, setPostcode] = useState<string>("");
  const [jobTitle, setJobTitle] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [privacy, setPrivacy] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [receiveEmails, setReceiveEmails] = useState<boolean>(false);

  /**
   * On form submit
   *
   * @param {React.FormEvent<HTMLFormElement>} event
   */
  const handleSubmit = () => {
    const request = {
      first_name: firstName,
      last_name: lastName,
      country,
      email,
      address,
      postcode,
      city,
      gender,
      date_of_birth: dob,
      phone,
      password,
      company_name: companyName,
      job_title: jobTitle,
      confirm_password: confirmPassword,
      checked_terms: terms,
      checked_privacy: privacy,
    };

    const requiredFields = {
      first_name: "You must provide your first name",
      last_name: "You must provide your last name",
      country: "You must select a country",
      email: "You must provide your email address",
      address: "You must provide your address",
      postcode: "You must provide your postcode",
      city: "You must provide your city",
      gender: "You must select your gender",
      date_of_birth: "You must provide your date of birth",
      phone: "You must provide your phone number",
      company_name: "You must provide the name of your company",
      checked_terms:
        "You must accept our terms and conditions in order to register",
      checked_privacy:
        "You must accept our privacy policy in order to register",
    };

    if (
      Object.keys(requiredFields).some((field) => {
        if (!request[field]) {
          Toast.danger({ message: requiredFields[field] });
          return true;
        }

        return false;
      })
    ) {
      return;
    }

    if (!PasswordStrength.hasNumber(request["password"])) {
      Toast.danger({ message: "Your password must contain a number" });
      return;
    }
    if (!PasswordStrength.hasSpecial(request["password"])) {
      Toast.danger({
        message:
          "Your password must contain a special characters (e.g #, @ or $)",
      });
      return;
    }
    if (request["password"] !== request["confirm_password"]) {
      Toast.danger({ message: "The passwords you provided did not match" });
      return;
    }

    setIsSubmitting(true);
    api
      .post("users/mad-world-signup", request)
      .then(() => {
        setTimeout(() => {
          dispatchSignIn({ email, password, remember_me: true }).then(() => {
            window.location.href = "/";
          });
        }, 3500);
      })
      .catch((error) => Toast.danger({ message: error.message }));

    setIsSubmitting(false);
    return;
  };

  /**
   * Invoked on date of birth change
   *
   * @param {Date} date
   */
  const handleDateChange = (date: Date) => {
    setDob(moment(date, "DD-MM-YYYY").format("MM/DD/yyyy"));
  };

  useEffect(() => {
    getCountryData();
  }, []);

  return (
    <ContainerCentered>
      <Helmet>
        <title>
          {`Sign Up for A Free Trial - Provided by Mad World - ${process.env.REACT_APP_COMPANY_NAME}`}
        </title>
      </Helmet>
      <form>
        <StyledMadWorldContainer>
          <StyledMadWorldSubcontainer>
            <StyledImage src={MadWorldLogo} />
          </StyledMadWorldSubcontainer>
          <StyledMadWorldSubcontainer>
            <StyledPoweredText>
              Sign up for a free trial, provided by Mad World
            </StyledPoweredText>
          </StyledMadWorldSubcontainer>
        </StyledMadWorldContainer>
        <StyledColumnContainer>
          <InputUnderline
            type="text"
            name="firstName"
            label="First Name"
            onChange={(_, value) => setFirstName(value)}
          />
          <InputUnderline
            type="text"
            name="lastName"
            label="Last Name"
            onChange={(_, value) => setLastName(value)}
          />
        </StyledColumnContainer>
        <InputUnderline
          type="email"
          name="email"
          label="Email"
          onChange={(_, value) => setEmail(value)}
        />
        <InputUnderline
          type="text"
          name="address"
          label="Address"
          onChange={(_, value) => setAddress(value)}
        />
        <StyledColumnContainer>
          <InputUnderline
            type="text"
            name="postcode"
            label="Postcode"
            onChange={(_, value) => setPostcode(value)}
          />
          <InputUnderline
            type="text"
            name="city"
            label="City"
            onChange={(_, value) => setCity(value)}
          />
        </StyledColumnContainer>
        <Select
          name="country"
          label="Country"
          items={countries}
          placeholder=" "
          onChange={(_, value) => setCountry(value)}
        />
        <Select
          name="gender"
          label="Gender"
          items={GENDERS}
          onChange={(_, value) => setGender(value)}
        />
        <DateInput
          label="Date of birth"
          name="date_of_birth"
          minDate={new Date(new Date().setFullYear(1930))}
          onChange={(_, value) => handleDateChange(value)}
        />
        <InputUnderline
          type="phone"
          name="phone"
          label="Phone Number"
          onChange={(_, value) => setPhone(value)}
        />
        <StyledColumnContainer>
          <InputUnderline
            type="text"
            name="company_name"
            label="Company Name"
            onChange={(_, value) => setCompanyName(value)}
          />
          <InputUnderline
            type="text"
            name="job_title"
            label="Job Title"
            onChange={(_, value) => setJobTitle(value)}
          />
        </StyledColumnContainer>
        <InputUnderline
          type="password"
          name="password"
          label="Choose a password"
          helperText={
            "Must contain numbers, special characters and a mix of upper/lowercase letters."
          }
          onChange={(_, value) => setPassword(value)}
        />
        <InputUnderline
          type="password"
          name="passwordConfirm"
          label="Confirm Password"
          onChange={(_, value) => setConfirmPassword(value)}
        />

        <StyledCheckbox>
          <FormGroup
            labelFor="emails"
            label="Receive weekly emails with useful articles, videos and podcasts (you can always change this later)."
          >
            <Checkbox
              large
              id="emails"
              checked={receiveEmails}
              onChange={() => setReceiveEmails(!receiveEmails)}
            />
          </FormGroup>
        </StyledCheckbox>

        <StyledCheckbox>
          <FormGroup
            labelFor="terms"
            label={
              <p>
                You must accept our{" "}
                <StyledLink target="_blank" to="/terms-and-conditions">
                  terms and conditions.
                </StyledLink>
              </p>
            }
          >
            <Checkbox
              large
              id="terms"
              checked={terms}
              onChange={() => setTerms(!terms)}
            />
          </FormGroup>
        </StyledCheckbox>

        <StyledCheckbox>
          <FormGroup
            labelFor="privacy"
            label={
              <p>
                You must accept our{" "}
                <StyledLink target="_blank" to="/privacy-policy">
                  privacy policy.
                </StyledLink>
              </p>
            }
          >
            <Checkbox
              large
              id="privacy"
              checked={privacy}
              onChange={() => setPrivacy(!privacy)}
            />
          </FormGroup>
        </StyledCheckbox>

        <StyledButtonArea>
          <Button
            large
            onClick={() => handleSubmit()}
            intent="primary"
            loading={isSubmitting}
            rightIcon="chevron-right"
          >
            Sign Up
          </Button>
        </StyledButtonArea>
      </form>
    </ContainerCentered>
  );
});

const StyledLink = styled(Link)`
  color: var(--brandColorPrimary);
`;

const StyledCheckbox = styled.div`
  color: ${(props) => props.color};
  position: relative;
  padding: 0.4rem 0 0 2.2rem;

  .bp3-form-group .bp3-control {
    margin-top: 0.33rem;
  }

  .bp3-checkbox {
    top: -1px;
    left: -1px;
    position: absolute;
  }
`;

const StyledButtonArea = styled.div`
  padding-top: 1.5rem;
`;

const StyledColumnContainer = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: row;

  & > * {
    flex-basis: 50%;
  }
`;

const StyledMadWorldContainer = styled.div`
  display: flex;
  margin-bottom: 3rem;
  align-items: flex-end;

  & > * {
    flex-basis: 50%;
  }
`;

const StyledMadWorldSubcontainer = styled.div`
  margin: 0 auto;

  & > p {
    margin-bottom: 0;
  }

  & > img {
    margin: 0 auto;
  }
`;

const StyledImage = styled(Image)`
  width: 170px;
`;

const StyledPoweredText = styled.p`
  margin: 1rem;
  text-align: center;
`;
