import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "@components/Link";
import { Checkbox, Button, FormGroup } from "@blueprintjs/core";

import Toast from "@utils/Toast";
import analytics from "@utils/analytics";
import FormHeader from "@components/FormHeader";
import FormProcessor from "@components/FormProcessor";
import { signIn } from "@store/actions/authentication";
import InputUnderline from "@components/InputUnderline";
import { TextSeparator } from "@components/TextSeperator";
import ContainerCentered from "@components/ContainerCentered";
import UnidaysLoginLogo from "@assets/images/unidays-login.svg";
import { generateUnidaysLink } from "@utils/generateUnidaysLink";
import { EVENT_PARTNER_USER_REGISTER } from "@constants/analytics";

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSignIn: (data: any) => dispatch(signIn(data)),
});

const mapStateToProps = ({ user }: any) => ({
  user,
});

interface Props {
  user: any;
  history?: any;
  dispatchSignIn: any;
  fromRedirection?: boolean;
}

class LoginContainer extends React.Component<Props> {
  state = {
    email: "",
    password: "",
    loading: false,
    showSignUp: false,
    emailIntent: "",
    passwordIntent: "",
    showPassword: false,
    rememberMeChecked: true,
  };

  static defaultProps = {
    fromRedirection: false,
  };

  attemptLogin = () => {
    const { dispatchSignIn, history } = this.props;
    const { email, password, rememberMeChecked } = this.state;

    dispatchSignIn({ email, password, remember_me: rememberMeChecked }).then(
      (success) => {
        if (success) {
          history.push("/");
        }

        this.setState({ loading: false });
      }
    );
  };

  handleInputFocus = (name: string) => {
    this.setState({ [`${name}Intent`]: "", [`${name}HelperText`]: "" });
  };

  handleInputChange = (name: string, value: string) => {
    this.setState({ [name]: value });
  };

  handleFormSubmit(e: Object) {
    // @ts-ignore
    e.preventDefault();

    const requiredFields = {
      email: "You must enter an email address",
      password: "You must provide a password",
    };

    if (
      Object.keys(requiredFields).some((field) => {
        if (!this.state[field]) {
          Toast.danger({ message: requiredFields[field] });
          return true;
        }

        return false;
      })
    ) {
      return;
    }

    this.setState({ loading: true }, this.attemptLogin);
  }

  componentDidMount() {
    window.addEventListener("message", this.handleMessage);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessage);
  }

  handleMessage = async (event: MessageEvent) => {
    if (event.data && typeof event.data === "object" && event.data.login) {
      analytics.logEvent(EVENT_PARTNER_USER_REGISTER, {
        partner: event.data.login.partner,
      });

      await this.setState({
        email: event.data.login.email,
        password: event.data.login.password,
      });

      await this.attemptLogin();
    }
  };

  /**
   * Invoked on student signup button
   *
   * @returns {void}
   */
  handleStudentSignUp(): void {
    this.props.history.push("student-sign-up");
  }

  /**
   * Invoked on free trial button click
   *
   * @returns {void}
   */
  handleTrialClick(): void {
    this.props.history.push("/mad-world-signup");
  }

  render() {
    const { fromRedirection } = this.props;

    const {
      loading,
      emailIntent,
      showPassword,
      passwordIntent,
      rememberMeChecked,
    } = this.state;

    const lockIcon = (
      <Button
        minimal
        icon={showPassword ? "unlock" : "lock"}
        onClick={() => this.setState({ showPassword: !showPassword })}
      />
    );

    return (
      <ContainerCentered>
        <Helmet>
          <title>Login - {process.env.REACT_APP_COMPANY_NAME}</title>
        </Helmet>
        <FormProcessor>
          <form onSubmit={(e) => this.handleFormSubmit(e)}>
            <FormHeader>
              {!fromRedirection
                ? "Please login below to get started"
                : "You must be logged in to do that"}
            </FormHeader>

            <InputUnderline
              name="email"
              intent={emailIntent}
              label="Your email address"
              onFocus={this.handleInputFocus}
              onChange={this.handleInputChange}
            />
            <InputUnderline
              name="password"
              intent={passwordIntent}
              rightElement={lockIcon}
              label="Your password"
              onFocus={this.handleInputFocus}
              onChange={this.handleInputChange}
              type={showPassword ? "text" : "password"}
            />

            <div className="mb-4">
              <Link to="/forgot-password">Forgotten password?</Link>
            </div>

            <Button
              fill={true}
              large={true}
              type="submit"
              intent="primary"
              loading={loading}
            >
              Log In
            </Button>

            <TextSeparator className="mt-4 mb-4">or</TextSeparator>

            <StyledLoginButtonContainer>
              <Link to={generateUnidaysLink()}>
                <StyledImg src={UnidaysLoginLogo} />
              </Link>
              <StyledButton
                large={true}
                onClick={() => this.handleStudentSignUp()}
              >
                Student Signup
              </StyledButton>
              <StyledButton
                large={true}
                onClick={() => this.handleTrialClick()}
              >
                Free Trial
              </StyledButton>
            </StyledLoginButtonContainer>
          </form>
        </FormProcessor>
      </ContainerCentered>
    );
  }
}

const StyledSignupOptionsContainer = styled.div`
  display: grid;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 1fr;
`;

const StyledButton = styled(Button)``;

const StyledAlternativeSignupText = styled.p`
  text-align: center;
`;

const StyledAlternativeLink = styled(Link)`
  color: var(--brandColorPrimary);
`;

const StyledOptionsText = styled.div`
  display: block;
  font-weight: 300;
  margin: 0.4rem 0 0;
  text-align: center;

  a {
    display: inline;
    padding: 0 0.3rem;
    color: var(--brandColorPrimary);
  }
`;

const StyledRememberMe = styled.div`
  .bp3-form-group .bp3-control {
    margin-top: 0.33rem;
  }
`;

const StyledImg = styled.img`
  margin: 0;
  height: 48px;
  margin-left: auto;
  margin-right: 0.5rem;
`;

const StyledLoginButtonContainer = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
