import { connect } from "react-redux";
import styled from "styled-components";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import Nav from "./Nav";
import Icon from "./Icon";
import NavItem from "./NavItem";
import Container from "./Container";
import Search from "./Search/Search";
import NavItemLink from "./NavItemLink";
import NavChannels from "./NavChannels";
import logo from "../assets/images/logo.svg";
import { UserInterface } from "@interfaces/UserInterface";
import { NavLinkInterface } from "@interfaces/NavLinkInterface";
import { UnidaysLoginButton } from "@components/UnidaysLoginButton";
import { MAD_WORLD_ORGANISATION_ID } from "@constants/organisations";

const mapStateToProps = ({ user, channels }: any) => ({
  user,
  channels,
});

interface Props {
  channels: any;
  user: UserInterface;
}

class Header extends Component<Props> {
  state = {
    showMenu: false,
    showChannels: false,
    showChannelsTime: 0,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  renderNavItem({ name, path, id }: NavLinkInterface) {
    const { channels } = this.props;
    const { showChannels } = this.state;

    if (name === "Channels") {
      return (
        <NavItem key={name}>
          <NavItemLink
            to="#"
            as="div"
            id="test__nav-channels"
            onClick={this.toggleChannels}
            onMouseEnter={this.showChannels}
            onMouseLeave={this.hideChannels}
          >
            Kanäle
            <StyledDropDownIcon>
              <Icon icon="caret-down" />
            </StyledDropDownIcon>
            {showChannels && (
              <NavChannels
                channels={channels}
                onClick={this.handleChannelsClick}
              />
            )}
          </NavItemLink>
        </NavItem>
      );
    }

    return (
      <NavItem key={name}>
        <NavItemLink
          id={id}
          to={path}
          exact={true}
          className={
            window.location.pathname.includes("managers") && name === "Manager"
              ? "active"
              : ""
          }
          onClick={() => this.setState({ showMenu: false })}
        >
          {name}
        </NavItemLink>
      </NavItem>
    );
  }

  setReference = (node: Object) => {
    // @ts-ignore
    this.ref = node;
  };

  handleClickOutside = (event: Object) => {
    // @ts-ignore
    if (this.ref && !this.ref.contains(event.target)) {
      this.setState({ showMenu: false });
    }
  };

  handleChannelsClick = () => this.setState({ showMenu: false });

  /**
   * @todo Without checking if the menu's been activated in the last 200 ms,
   * touch devices will fire both toggleMenu and showChannels, meaning that on
   * first touch, the menu will do nothing. However, we should find a more
   * succinct way of doing this, really.
   */

  showChannels = () => {
    if (this.state.showChannelsTime < Date.now() - 200) {
      this.setState({ showChannelsTime: Date.now() });
      this.setState({ showChannels: true });
    }
  };

  hideChannels = () => this.setState({ showChannels: false });

  toggleChannels = () => {
    const { showChannels } = this.state;

    if (this.state.showChannelsTime < Date.now() - 200) {
      this.setState({ showChannelsTime: Date.now() });
      this.setState({ showChannels: !showChannels });
    }
  };

  toggleMenu = () => {
    const { showMenu } = this.state;

    this.setState({ showMenu: !showMenu });
  };

  isUserInMadWorld = (user) => {
    return user.organisations?.some((organisation) => {
      return organisation.organisation_id === MAD_WORLD_ORGANISATION_ID;
    });
  };

  getNavigationItems(): NavLinkInterface[] {
    const { user } = this.props;

    if (!user.isAuthenticated) {
      return [
        { name: "Zuhause", path: "/" },
        { name: "Sign in", path: "/login" },
      ];
    }

    if (this.isUserInMadWorld(user)) {
      return user.isManager
        ? [
            { name: "Zuhause", path: "/", id: "test__home" },
            { name: "Channels", path: "" },
            { name: "Mein Wohlbefinden", path: "/my-wellbeing" },
            { name: "EAP", path: "/eap" },
            { name: "Konto", path: "/account", id: "test__account" },
            { name: "Manager", path: "/managers/overview" },
            { name: "Ausloggen", path: "/logout", id: "test__logout" },
          ]
        : [
            { name: "Zuhause", path: "/", id: "test__home" },
            { name: "Channels", path: "" },
            { name: "Mein Wohlbefinden", path: "/my-wellbeing" },
            { name: "EAP", path: "/eap" },
            { name: "Konto", path: "/account", id: "test__account" },
            { name: "Ausloggen", path: "/logout", id: "test__logout" },
          ];
    }

    if (user.isManager) {
      return [
        { name: "Zuhause", path: "/", id: "test__home" },
        { name: "Channels", path: "" },
        { name: "Mein Wohlbefinden", path: "/my-wellbeing" },
        { name: "Konto", path: "/account", id: "test__account" },
        { name: "Manager", path: "/managers/overview" },
        { name: "Ausloggen", path: "/logout", id: "test__logout" },
      ];
    }

    return this.isUserInMadWorld(user)
      ? [
          { name: "Zuhause", path: "/", id: "test__home" },
          { name: "Channels", path: "" },
          { name: "Mein Wohlbefinden", path: "/my-wellbeing" },
          { name: "EAP", path: "/eap" },
          { name: "Konto", path: "/account", id: "test__account" },
          { name: "Ausloggen", path: "/logout", id: "test__logout" },
        ]
      : [
          { name: "Zuhause", path: "/", id: "test__home" },
          { name: "Channels", path: "" },
          { name: "Mein Wohlbefinden", path: "/my-wellbeing" },
          { name: "Konto", path: "/account", id: "test__account" },
          { name: "Ausloggen", path: "/logout", id: "test__logout" },
        ];
  }

  render() {
    const { user } = this.props;
    const { showMenu } = this.state;

    const menuItems = this.getNavigationItems();

    return (
      <StyledHeader ref={this.setReference}>
        <StyledHeaderContainer>
          <Link to="/">
            <StyledHeaderLogo src={logo} alt="Company Logo" />
          </Link>

          <Nav className={showMenu ? "active" : ""}>
            {menuItems.map((item) => this.renderNavItem(item))}
          </Nav>

          {user.isAuthenticated && <Search />}

          <StyledHeaderMenuIcon
            icon="menu"
            iconSize={30}
            onClick={this.toggleMenu}
            flexgrow={user.isAuthenticated ? 0 : 1}
          />

          {!user.isAuthenticated && <UnidaysLoginButton />}
        </StyledHeaderContainer>
      </StyledHeader>
    );
  }
}

// @ts-ignore
const StyledHeader = styled.header<{ ref?: any }>`
  color: #fff;
  margin-bottom: 3rem;
  background: var(--brandColorPrimary);

  @media (max-width: ${({ theme }) => theme.breakpointSmall}) {
    margin-bottom: 0.2rem;
  }
`;

const StyledHeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
`;

const StyledHeaderLogo = styled.img`
  height: 1.6rem;
  padding: 0 0 0.1rem;

  @media (min-width: ${({ theme }) => theme.breakpointLarge}) {
    margin-right: 3rem;
  }
`;

const StyledHeaderMenuIcon = styled(Icon)`
  height: 48px;
  display: none;
  cursor: pointer;
  text-align: right;
  margin-left: 0.6rem;
  padding: 0.25rem 0 0 0;
  flex-grow: ${(props) => props.flexgrow};

  @media (max-width: ${({ theme }) => theme.breakpointLarge}) {
    display: block;
  }
`;

const StyledDropDownIcon = styled.div`
  float: right;
  display: inline-block;
  margin: -0.1rem 0 0 0.3rem;
`;

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, null)(Header)
);
