import React from "react";
import styled from "styled-components";

interface ITextSeparatorProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextSeparator = ({
  children,
  className,
}: ITextSeparatorProps): JSX.Element => {
  return (
    <StyledTextSeparator className={className}>
      <StyledTextSeparatorText>{children}</StyledTextSeparatorText>
    </StyledTextSeparator>
  );
};

const StyledTextSeparator = styled.div`
  text-align: center;
  position: relative;
  &:after {
    left: 0;
    top: 50%;
    z-index: 0;
    content: "";
    width: 100%;
    height: 1px;
    display: block;
    position: absolute;
    background: #cbd2d6;
  }
`;

const StyledTextSeparatorText = styled.div`
  z-index: 1;
  color: #6c7378;
  background: #fff;
  padding: 0 0.5rem;
  position: relative;
  display: inline-block;
`;
