import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const EmailPreview = () => (
  <StyledEmail>
    <StyledEmailContent>
      <StyledEmailTable>
        <tr>
          <StyledEmailTD>
            <StyledEmailBanner src="https://miindset.com/email/banner.jpg" />
            <StyledEmailTitle>Welcome to Miindset!</StyledEmailTitle>
            <StyledEmailSubTitle>
              YOUR INSTANT WELLNESS PLATFORM
            </StyledEmailSubTitle>
            <p>
              Miindset is the first health platform to deliver information
              tailored to how you are feeling in that instant. By curating
              helpful content tailored to your mood, Miindset helps you raise
              your emotional wellbeing and build resilience in an instant.
            </p>
            <ul style={{ textAlign: "left" }}>
              <li>Read, watch, listen - whatever your preference</li>
              <li>Instant results from anonymised Miindset Questionnaire</li>
              <li>Personal content to boost your mood</li>
              <li>
                Curated content with information and techniques to start
                improving wellbeing, fast
              </li>
              <li>Relevant and topical podcasts</li>
              <li>16 channels of customised content</li>
            </ul>
            <p>
              Miindset is available anytime, anywhere, across multiple devices.
            </p>
            <p>Click below to get started.</p>
            <p>
              <StyledEmailButton to="#">Register Now</StyledEmailButton>
            </p>
            <p>
              Unique activation code -{" "}
              <strong>
                p4ZHNub
                <br />
              </strong>
              Invite Email -{" "}
              <strong>
                joe@company.com
                <br />
              </strong>
              Your code expires - <strong>23 Apr 2019</strong>
            </p>
          </StyledEmailTD>
        </tr>
      </StyledEmailTable>
    </StyledEmailContent>
  </StyledEmail>
);

const StyledEmail = styled.div`
  color: #6d7d8d;
  border-radius: 6px;
  background-color: #eaeaeb;
  font-family: "Poppins", sans-serif;
  border: 1px solid var(--borderColor);
`;

const StyledEmailTable = styled.table`
  border-spacing: 0;
`;

const StyledEmailTD = styled.td`
  text-align: center;
  padding: 0 30px;
`;

const StyledEmailContent = styled.div`
  text-align: center;
  border-radius: 6px;
  background-color: #ffffff;
`;

const StyledEmailBanner = styled.img`
  width: 100%;
`;

const StyledEmailTitle = styled.h1`
  color: #5f5fed;
  font-size: 34px;
`;

const StyledEmailSubTitle = styled.h2`
  font-size: 24px;
`;

const StyledEmailButton = styled(Link)`
  &,
  &:hover,
  &:active {
    color: white;
    color: #ffffff;
    font-size: 13px;
    font-weight: 700;
    padding: 8px 28px;
    letter-spacing: 1px;
    border-radius: 50px;
    text-decoration: none;
    background-color: #5f5fed;
    text-transform: uppercase;
  }
`;

export default EmailPreview;
