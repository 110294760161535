/*
|--------------------------------------------------------------
| Core imports
|--------------------------------------------------------------
|
*/

import React from "react";
import { compose } from "redux";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import cssVars from "css-vars-ponyfill";
import { PersistGate } from "redux-persist/es/integration/react";

import Routes from "./routes";
import createAppStore from "./store";
import withProviders from "./hocs/withProviders";
import * as serviceWorker from "./serviceWorker";

import "./utils/analytics";

import "./assets/scss/blueprintjs.scss";

/*
|--------------------------------------------------------------
| Global Variables
|--------------------------------------------------------------
|
| Declare any global variables/methods here
|
*/

declare global {
  interface Window {
    /*eslint-disable */
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    /*eslint-enable */
    env: {
      REACT_APP_API_URL: string;
      REACT_APP_CLOUDINARY_URL: string;
      REACT_APP_CLOUDINARY_CLOUD_NAME: string;
      REACT_APP_COMPANY_NAME: string;
      REACT_APP_CONTACT_EMAIL: string;
      REACT_APP_UNIDAYS_CLIENT_ID: string;
      REACT_APP_UNIDAYS_AUTHORIZATION_URL: string;
    };
  }

  interface FormData {
    keys(): IterableIterator<string>;
    values(): IterableIterator<FormDataEntryValue>;
    forEach(): IterableIterator<FormDataEntryValue>;
    entries(): IterableIterator<[string, FormDataEntryValue]>;
    fromEntries(): IterableIterator<[string, FormDataEntryValue]>;
    [Symbol.iterator](): IterableIterator<[string, FormDataEntryValue]>;
  }

  /**
   * Alias for the `any` type that signifies that
   * it should be replaced with strict types in the future
   */
  type $TSFixMe = any;
}

/*
|--------------------------------------------------------------
| CSS Vars Polyfill
|--------------------------------------------------------------
|
| For IE11 we are using a polyfill to use CSS polyfills.
| we will use a mutation observer to check the dom
| for updates and reload the latest CSS
|
*/

cssVars({ watch: true });

const { persistor, store } = createAppStore();

const Root = withProviders(Routes);

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Root />
    </PersistGate>
  </Provider>
);

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
