import { isEmpty } from "lodash";

import api from "../../api";
import Toast from "../../utils/Toast";
import analytics from "@utils/analytics";
import { getOrganisations } from "./managers";
import LoginCounter from "../../utils/LoginCounter";
import { SET_USER, UNSET_USER } from "../reducers/user";
import { UNSET_ORGANISATIONS } from "../reducers/organisations";
import { SET_ORGANISATION, UNSET_ORGANISATION } from "../reducers/organisation";
import {
  EVENT_LOGIN,
  EVENT_LOGOUT,
  EVENT_REGISTER,
  EVENT_STUDENT_REGISTER,
} from "../../constants/analytics";

/**
 * Sign In
 * @param {string} data {
 *    email: '',
 *    password: '',
 *    remember_me: boolean
 *  }
 * @return {Promise<boolean>}
 */
export const signIn = (data) => (dispatch) => {
  return api
    .post("auth/login", { ...data })
    .then((res) => {
      const payload = res.data.payload.user;
      payload.isAuthenticated = Boolean(payload.user_id);

      analytics.setUserId(payload.user_id);
      analytics.logEvent(EVENT_LOGIN);

      // Manager specifics
      if (["staff", "manager"].includes(payload.role)) payload.isManager = true;

      // Append successful login count
      const counter = new LoginCounter();
      counter.add();

      dispatch(getOrganisations());
      dispatch({ type: SET_USER, payload });

      return true;
    })
    .catch((error) => Toast.danger({ message: error.message }));
};

/**
 * Sign Up
 * @param {string} data
 * {
 *   gender: '',
 *   password: '',
 *   postcode: '',
 *   last_name: '',
 *   first_name: '',
 *   work_email: '',
 *   invite_code: '',
 *   personalised_email_optin: ''
 * }
 */
export const signUp = (data) => () => {
  return api
    .post("users/accept-invite", { ...data })
    .then((response) => {
      if (response.status === 202) {
        analytics.logEvent(EVENT_REGISTER);
        return true;
      }
      return false;
    })
    .catch((error) => Toast.danger({ message: error.message }));
};

/**
 * Sign Up Student
 * @param {string} data
 * {
 *   gender: '',
 *   password: '',
 *   username: '',
 *   age_range: '',
 *   university: '',
 *   student_email: '',
 *   personalised_email_optin: ''
 * }
 */
export const signUpStudent = (data) => () => {
  return api
    .post("students/register", { ...data })
    .then((response) => {
      if (response.status === 202) {
        analytics.logEvent(EVENT_STUDENT_REGISTER);
        return true;
      }
      return false;
    })
    .catch((error) => Toast.danger({ message: error.message }));
};

/**
 * Forgot Password
 * @param {string} email address
 */
export const forgotPassword = (email) => () => {
  return api
    .post("auth/request-password-reset", { email })
    .then(() =>
      Toast.success({ message: "Please check your emails for a reset link" })
    )
    .catch(() =>
      Toast.danger({
        message: "The email address your provided does not exist",
      })
    );
};

/**
 * Password reset
 * @param {object} data {
 *    email: '',
 *    reset_code: '',
 *    password: ''
 * }
 */
export const passwordReset = (data) => () => {
  return api
    .post("auth/reset-password", { ...data })
    .then(() => {
      Toast.success({
        message: "Your account password was reset, you may now login",
      });

      return true;
    })
    .catch((error) => Toast.danger({ message: error.message }));
};

/**
 * Resend activiation email
 * @param {string} email address
 */
export const resendActivation = (email) => () => {
  return api
    .post("auth/request-invite-resend", { email })
    .then(() =>
      Toast.success({
        message: "Please check your emails for your activation code",
      })
    )
    .catch(() =>
      Toast.danger({
        message: "The email address your provided does not exist",
      })
    );
};

/**
 * Refresh user data.
 *
 * @todo Sometimes this endpoint fails requiring the user to login twice.
 * This seems to happen shortly after logging in. (side effect of event sourcing? Do we need sockets?)
 *
 * @return {object|null} user payload
 */
export const refreshUser = () => (dispatch) =>
  api.post("users/me").then((response) => {
    let user = null;

    if (response.status === 200) {
      dispatch({
        type: SET_USER,
        payload: {
          isAuthenticated: true,
          isManager: ["staff", "manager"].includes(response.data.payload.role),
          ...response.data.payload,
        },
      });

      const firstOrganisation = response.data.payload.organisations[0];
      if (!isEmpty(firstOrganisation)) {
        dispatch({
          type: SET_ORGANISATION,
          payload: {
            selected: 0,
            ...firstOrganisation,
          },
        });
      }

      user = response.data.payload;
    }

    return user;
  });

/**
 * Logout User
 */
export const logout = () => (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("channels");

  analytics.logEvent(EVENT_LOGOUT);
  analytics.setUserId(null);

  // Use promise to allow async/await on logout dispatch
  return new Promise((resolve) => {
    dispatch({ type: UNSET_ORGANISATION });
    dispatch({ type: UNSET_ORGANISATIONS });
    dispatch({ type: UNSET_USER });

    resolve();
  });
};
