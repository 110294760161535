import styled from "styled-components";

export default styled.div`
  width: 90%;
  min-height: 60vh;
  max-width: 550px;
  margin: 3rem auto 5rem;

  @media (max-width: ${({ theme }) => theme.breakpointSmall}) {
    h1 {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
`;
