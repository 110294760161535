import React from "react";
import { Icon as BPIcon } from "@blueprintjs/core";

export default function Icon(props: any) {
  return (
    <span style={props.style} className={props.className}>
      <BPIcon {...props} color={props.color ? props.color : "currentColor"} />
    </span>
  );
}
